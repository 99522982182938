import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./style.css";

const Loader = () => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 80, color: "#3f861e" }} />
  );

  return (
    <>
      <Spin
        size="large"
        indicator={antIcon}
        style={{ position: "absolute", left: "50%", top: "50%" }}
      />
    </>
  );
};
export default Loader;
