import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";

export const GET_MOST_APPRECIATED_SUCCESS = "GET_MOST_APPRECIATED_SUCCESS";
export const GET_MOST_APPRECIATED_ERROR = "GET_MOST_APPRECIATED_ERROR";
export const GET_MOST_APPRECIATED_REQUEST = "GET_MOST_APPRECIATED_REQUEST";

export const getMostAppreciated = () => (dispatch) => {
  const user_id = localStorage.getItem("user_id");

  dispatch({ type: GET_MOST_APPRECIATED_REQUEST });
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/most_appreciated`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      dispatch({
        type: GET_MOST_APPRECIATED_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_MOST_APPRECIATED_ERROR,
        response: error.response,
      });
    });
};
