import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";

export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS";
export const CREATE_COMMENT_ERROR = "CREATE_COMMENT_ERROR";
export const CREATE_COMMENT_REQUEST = "CREATE_COMMENT_REQUEST";

export const createComment = (props) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");
  // let data = new FormData();
  // data.append("employee_comment[comment]", props.employeeComment);
  const current_url = window.location.href;
  var url = new URL(current_url);
  var path = url.pathname;
  var performance_review_id = path.substring(path.lastIndexOf("/") + 1);

  dispatch({ type: CREATE_COMMENT_REQUEST });
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/performance_review/${performance_review_id}/employee_comments`,
    data: {
      comment: props.employeeComment,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      dispatch({
        type: CREATE_COMMENT_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(tokenAuthFailedAction());
      }
      dispatch({
        type: CREATE_COMMENT_ERROR,
        response: error.response,
      });
    });
};
