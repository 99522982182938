import {
    SEND_INVITE_SUCCESS,
    SEND_INVITE_REQUEST,
    SEND_INVITE_ERROR,
  } from "../../actions/InviteUser/inviteuser";
  
  const sendInvite = (state = null, action) => {
    switch (action.type) {
      case SEND_INVITE_REQUEST: {
        return true;
      }
      case SEND_INVITE_SUCCESS: {
        return action.response;
      }
      case SEND_INVITE_ERROR: {
        return action.response;
      }
      default:
        return state;
    }
  };
  
  export default sendInvite;
  