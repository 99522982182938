import axios from "axios";
import moment from "moment";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
export const CREATE_TIMEOFF_REQUEST_SUCCESS = "CREATE_TIMEOFF_REQUEST_SUCCESS";
export const CREATE_TIMEOFF_REQUEST_ERROR = "CREATE_TIMEOFF_REQUEST_ERROR";
export const CREATE_TIMEOFF_REQUEST_REQUEST = "CREATE_TIMEOFF_REQUEST_REQUEST";
export const CREATE_TIMEOFF_REQUEST_RESET = "CREATE_TIMEOFF_REQUEST_RESET";

export const createTimeoffRequest =
  (timeoffRequest, totalTimeOffs) => (dispatch) => {
    const user_id = localStorage.getItem("user_id");
    let start_date = moment(timeoffRequest.startDate).unix();
    let end_date = moment(timeoffRequest.endDate).unix();
    dispatch({ type: CREATE_TIMEOFF_REQUEST_REQUEST });
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/time_offs`,
      data: {
        start_date: start_date,
        end_date: end_date,
        leave_duration: timeoffRequest.leaveDuration,
        leave_type: timeoffRequest.leaveType,
        work_days: timeoffRequest.workDays,
        note: timeoffRequest.note,
        other:
          timeoffRequest?.leaveType === "other_leave"
            ? timeoffRequest?.other
            : "",
        total_time_off: totalTimeOffs,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "access-token": readLocalStorage("access-token"),
      },
    })
      .then((response) => {
        dispatch({
          type: CREATE_TIMEOFF_REQUEST_SUCCESS,
          response: response,
        });
        dispatch({ type: CREATE_TIMEOFF_REQUEST_RESET });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(tokenAuthFailedAction());
        }
        dispatch({
          type: CREATE_TIMEOFF_REQUEST_ERROR,
          response: error.response,
        });
        dispatch({ type: CREATE_TIMEOFF_REQUEST_RESET });
      });
  };
