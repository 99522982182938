import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Button, Form } from "antd";
import { withRouter, useParams } from "react-router-dom";
import "./style.css";
import createPerformanceRating from "../../redux/actions/PerformanceRating/createPerformanceRating";
import { viewPerformanceReview } from "../../redux/actions/PerformanceReview/viewPerformanceReview";
import { Slider } from "antd";

const PerformanceReview = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  localStorage.setItem("performance_review_id", id);
  const { history } = props;

  const viewPerformanceReviewStatus = useSelector(
    (state) => state.viewPerformanceReview
  );
  const createPerformanceReviewStatus = useSelector(
    (state) => state.createPerformanceRating
  );
  const [value, setValue] = useState(1);

  const onChangeSlider = (newValue) => {
    setValue(newValue);
  };

  const getTooltipText = (value) => {
    switch (value) {
      case 1:
        return "1- Unacceptable";
      case 2:
        return "2- Needs Improvement";
      case 3:
        return "3- Meets Expectations";
      case 4:
        return "4- Exceeds Expectations";
      case 5:
        return "5- Outstanding";
      default:
        return "";
    }
  };

  const [review, setReview] = useState("");

  useEffect(() => {
    dispatch(viewPerformanceReview(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (
      viewPerformanceReviewStatus &&
      viewPerformanceReviewStatus.status === 200
    ) {
      setReview(viewPerformanceReviewStatus.data);
    }
  }, [viewPerformanceReviewStatus]);

  const onFinish = (value) => {
    const defaultValue = 1;
    dispatch(
      createPerformanceRating(
        "job_knowledge",
        value.jobKnowledgeComment,
        value.jobKnowledgeRating || defaultValue
      )
    );
    dispatch(
      createPerformanceRating(
        "work_quality",
        value.workQualityComment,
        value.workQualityRating || defaultValue
      )
    );

    dispatch(
      createPerformanceRating(
        "punctuality",
        value.punctualityComment,
        value.punctualityRating || defaultValue
      )
    );

    dispatch(
      createPerformanceRating(
        "attendance",
        value.attendanceComment,
        value.attendanceRating || defaultValue
      )
    );

    dispatch(
      createPerformanceRating(
        "productivity",
        value.productivityComment,
        value.productivityRating || defaultValue
      )
    );

    dispatch(
      createPerformanceRating(
        "communication",
        value.communicationComment,
        value.communicationRating || defaultValue
      )
    );

    dispatch(
      createPerformanceRating(
        "listening_skills",
        value.listeningSkillsComment,
        value.listeningSkillsRating || defaultValue
      )
    );

    dispatch(
      createPerformanceRating(
        "dependability",
        value.dependabilityComment,
        value.dependabilityRating || defaultValue
      )
    );
  };

  useEffect(() => {
    if (
      createPerformanceReviewStatus &&
      createPerformanceReviewStatus.status === 201
    ) {
      history.push(`/performancereview_listing`);
    }
  }, [createPerformanceReviewStatus]);
  const formItemLayout = {
    labelCol: {
      xs: { span: 19 },
      sm: { span: 5 },
    },
  };

  return (
    <>
      <h1 className="create-performance-review-heading">
        Create Performance Review
      </h1>
      <div className="performance-review">
        <div className="rating-section">
          <Form onFinish={onFinish} {...formItemLayout}>
            <Form.Item
              className="ratingradioBtns"
              label=" Job Knowledge"
              name="jobKnowledgeRating"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input your rating!",
              //   },
              // ]}
            >
              <Slider
                min={1}
                max={5}
                onChange={onChangeSlider}
                value={value !== undefined ? value : 1}
                tipFormatter={(value) => getTooltipText(value)}
              />
            </Form.Item>

            <Form.Item
              className="ratingComment"
              label="Comment"
              name="jobKnowledgeComment"
              rules={[
                {
                  required: true,
                  message: "Please input your comment!",
                },
              ]}
            >
              <Input
                placeholder="Write Comment...."
                className="jobKnowledgeCommentInput"
              />
            </Form.Item>
            <Form.Item
              className="ratingradioBtns"
              label="Work Quality"
              name="workQualityRating"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input your rating!",
              //   },
              // ]}
            >
              <Slider
                min={1}
                max={5}
                onChange={onChangeSlider}
                value={value !== undefined ? value : 1}
                tipFormatter={(value) => getTooltipText(value)}
              />
            </Form.Item>

            <div className="commentheading">
              <p> </p>
            </div>

            <Form.Item
              className="ratingComment"
              label="Comment"
              name="workQualityComment"
              rules={[
                {
                  required: true,
                  message: "Please input your comment!",
                },
              ]}
            >
              <Input
                placeholder="Write Comment...."
                className="workQualityCommentInput"
              />
            </Form.Item>

            <Form.Item
              className="ratingradioBtns"
              label="Punctuality"
              name="punctualityRating"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input your rating!",
              //   },
              // ]}
            >
              <Slider
                min={1}
                max={5}
                onChange={onChangeSlider}
                value={value !== undefined ? value : 1}
                tipFormatter={(value) => getTooltipText(value)}
              />
            </Form.Item>

            <Form.Item
              className="ratingComment"
              label="Comment"
              name="punctualityComment"
              rules={[
                {
                  required: true,
                  message: "Please input your comment!",
                },
              ]}
            >
              <Input
                placeholder="Write Comment...."
                className="punctualityCommentInput"
              />
            </Form.Item>

            <Form.Item
              className="ratingradioBtns"
              label="Attendance"
              name="attendanceRating"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input your rating!",
              //   },
              // ]}
            >
              <Slider
                min={1}
                max={5}
                onChange={onChangeSlider}
                value={value !== undefined ? value : 1}
                tipFormatter={(value) => getTooltipText(value)}
              />
            </Form.Item>

            <Form.Item
              className="ratingComment"
              label="Comment"
              name="attendanceComment"
              rules={[
                {
                  required: true,
                  message: "Please input your comment!",
                },
              ]}
            >
              <Input
                placeholder="Write Comment...."
                className="attendanceCommentInput"
              />
            </Form.Item>

            <Form.Item
              className="ratingradioBtns"
              label="Productivity"
              name="productivityRating"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input your rating!",
              //   },
              // ]}
            >
              <Slider
                min={1}
                max={5}
                onChange={onChangeSlider}
                value={value !== undefined ? value : 1}
                tipFormatter={(value) => getTooltipText(value)}
              />
            </Form.Item>

            <Form.Item
              className="ratingComment"
              label="Comment"
              name="productivityComment"
              rules={[
                {
                  required: true,
                  message: "Please input your comment!",
                },
              ]}
            >
              <Input
                placeholder="Write Comment...."
                className="productivityCommentInput"
              />
            </Form.Item>

            <Form.Item
              className="ratingradioBtns"
              label="Communication"
              name="communicationRating"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input your rating!",
              //   },
              // ]}
            >
              <Slider
                min={1}
                max={5}
                onChange={onChangeSlider}
                value={value !== undefined ? value : 1}
                tipFormatter={(value) => getTooltipText(value)}
              />
            </Form.Item>

            <Form.Item
              className="ratingComment"
              label="Comment"
              name="communicationComment"
              rules={[
                {
                  required: true,
                  message: "Please input your comment!",
                },
              ]}
            >
              <Input
                placeholder="Write Comment...."
                className="communicationCommentInput"
              />
            </Form.Item>

            <Form.Item
              className="ratingradioBtns"
              label="Listening skills"
              name="listeningSkillsRating"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input your rating!",
              //   },
              // ]}
            >
              <Slider
                min={1}
                max={5}
                onChange={onChangeSlider}
                value={value !== undefined ? value : 1}
                tipFormatter={(value) => getTooltipText(value)}
              />
            </Form.Item>

            <Form.Item
              className="ratingComment"
              label="Comment"
              name="listeningSkillsComment"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input your comment!",
              //   },
              // ]}
            >
              <Input
                placeholder="Write Comment...."
                className="listeningSkillsCommentInput"
              />
            </Form.Item>

            <Form.Item
              className="ratingradioBtns"
              label="Dependability"
              name="dependabilityRating"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input your rating!",
              //   },
              // ]}
            >
              <Slider
                min={1}
                max={5}
                onChange={onChangeSlider}
                value={value !== undefined ? value : 1}
                tipFormatter={(value) => getTooltipText(value)}
              />
            </Form.Item>

            <Form.Item
              className="ratingComment"
              label="Comment"
              name="dependabilityComment"
              rules={[
                {
                  required: true,
                  message: "Please input your comment!",
                },
              ]}
            >
              <Input
                placeholder="Write Comment...."
                className="dependabilityCommentInput"
              />
            </Form.Item>

            <div className="reviewSubmitBtn">
              <Button
                id="createReviewBtn"
                className="submitBtnPerformanceReview"
                htmlType="submit"
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
export default withRouter(PerformanceReview);
