import {
  CREATE_PERFORMANCE_RATING_SUCCESS,
  CREATE_PERFORMANCE_RATING_REQUEST,
  CREATE_PERFORMANCE_RATING_ERROR,
} from "../../actions/PerformanceRating/createPerformanceRating";

const createPerformanceRating = (state = null, action) => {
  switch (action.type) {
    case CREATE_PERFORMANCE_RATING_REQUEST: {
      return true;
    }
    case CREATE_PERFORMANCE_RATING_SUCCESS: {
      return action.response;
    }
    case CREATE_PERFORMANCE_RATING_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default createPerformanceRating;
