import {
  GET_GOALS_LIST_SUCCESS,
  GET_GOALS_LIST_REQUEST,
  GET_GOALS_LIST_ERROR,
} from "../../actions/Organization/goalsList";

const getGoals = (state = null, action) => {
  switch (action.type) {
    case GET_GOALS_LIST_REQUEST: {
      return true;
    }
    case GET_GOALS_LIST_SUCCESS: {
      return action.response;
    }
    case GET_GOALS_LIST_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getGoals;
