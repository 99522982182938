import {
  CREATE_PERFORMANCE_REVIEW_SUCCESS,
  CREATE_PERFORMANCE_REVIEW_REQUEST,
  CREATE_PERFORMANCE_REVIEW_ERROR,
} from "../../actions/PerformanceReview/createPerformanceReview";

const createPerformanceReview = (state = null, action) => {
  switch (action.type) {
    case CREATE_PERFORMANCE_REVIEW_REQUEST: {
      return true;
    }
    case CREATE_PERFORMANCE_REVIEW_SUCCESS: {
      return action.response;
    }
    case CREATE_PERFORMANCE_REVIEW_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default createPerformanceReview;
