import {
  FILTER_SUCCESS,
  FILTER_REQUEST,
  FILTER_ERROR,
} from "../../actions/TimeOff/filter";

const filterTimeOff = (state = null, action) => {
  switch (action.type) {
    case FILTER_REQUEST: {
      return true;
    }
    case FILTER_SUCCESS: {
      return action.response;
    }
    case FILTER_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default filterTimeOff;
