import {
  CREATE_TEAM_OBJECTIVE_SUCCESS,
  CREATE_TEAM_OBJECTIVE_REQUEST,
  CREATE_TEAM_OBJECTIVE_ERROR,
} from "../../actions/Okr/createTeamObjective";

const createTeamObjective = (state = null, action) => {
  switch (action.type) {
    case CREATE_TEAM_OBJECTIVE_REQUEST: {
      return true;
    }
    case CREATE_TEAM_OBJECTIVE_SUCCESS: {
      return action.response;
    }
    case CREATE_TEAM_OBJECTIVE_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default createTeamObjective;
