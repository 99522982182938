import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AcceptInvitation from "../screens/AcceptInvitation";
import { ProtectedRoute } from "../router/dashboardRoutes";
import Signup from "../screens/Signup";
import ForgotPassword from "../screens/ForgotPassword";
import ResetPassword from "../screens/ResetPassword/ResetPassword";

import Otp from "../screens/OTP";
const Routers = () => {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/otp" component={Otp} />
          <Route exact path="/forgot_password" component={ForgotPassword} />
          <Route exact path="/reset_password" component={ResetPassword} />
          <Route exact path="/invitations/:id" component={AcceptInvitation} />
          <ProtectedRoute path="/" component={ProtectedRoute} />
        </Switch>
      </Router>
    </React.Fragment>
  );
};
export default Routers;
