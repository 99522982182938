import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
export const ADD_TEAM_MEMBER_SUCCESS = "ADD_TEAM_MEMBER_SUCCESS";
export const ADD_TEAM_MEMBER_ERROR = "ADD_TEAM_MEMBER_ERROR";
export const ADD_TEAM_MEMBER_REQUEST = "ADD_TEAM_MEMBER_REQUEST";

export const addTeamMember = (email, insertId) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");
  dispatch({ type: ADD_TEAM_MEMBER_REQUEST });
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/teams/${insertId}/team_members`,
    data: {
      email: email,
    },

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      dispatch({
        type: ADD_TEAM_MEMBER_SUCCESS,
        response: {
          successStatus: response.status,
          successEmail: email,
        },
      });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(tokenAuthFailedAction());
      }
      dispatch({
        type: ADD_TEAM_MEMBER_ERROR,
        response: {
          errorStatus: error.response.status,
          failedEmail: email,
        },
      });
    });
};
