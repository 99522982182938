import { combineReducers } from "redux";
import userRegistration from "../reducers/User/registration";
import userLogin from "../reducers/User/login";
import userLogout from "../reducers/User/logout";
import createOrganization from "../reducers/Organization/createOrganization";
import createWeeklyGoal from "../reducers/Organization/createWeeklyGoal";
import createGoal from "../reducers/Organization/createGoal";
import sendInvite from "../reducers/InviteUser/inviteUser";
import handleUnauthRoutes from "../reducers/Auth/handleUnauthRoutes";
import weeklyGoalList from "../reducers/Organization/weeklyGoalsList";
import deleteWeeklyGoal from "../reducers/Organization/deleteWeeklyGoal";
import WeeklyGoalDetail from "../reducers/Organization/viewWeeklyGoal";
import goalsList from "../reducers/Organization/goalsList";
import deleteGoal from "../reducers/Organization/deleteGoal";
import updateGoal from "../reducers/Organization/updateGoal";
import acceptInvite from "../reducers/InviteUser/acceptInvite";
import updateWeeklyGoal from "../reducers/Organization/updateWeeklyGoal";
import getOrganization from "../reducers/Organization/getOrganization";
import createTeam from "../reducers/Organization/createTeam";
import addTeamMember from "../reducers/Organization/addTeamMember";
import getEmployees from "../reducers/User/getEmployees";
import getOwner from "../reducers/User/getOwner";
import getEmail from "../reducers/User/getEmail";
import getManager from "../reducers/User/getManagers";
import getTeams from "../reducers/Team/getTeams";
import getTeam from "../reducers/Team/getTeam";
import deleteTeam from "../reducers/Team/deleteTeam";
import getTeamMembers from "../reducers/Team/getTeamMembers";
import deleteTeamMember from "./Team/deleteTeamMember";
import updateTeam from "./Team/updateTeam";
import createTimeoffRequest from "../reducers/Organization/createTimeoffRequest";
import getTimeOff from "./TimeOff/getTimeOff";
import getTimeoffDetail from "./TimeOff/getTimeoffDetail";
import getTimeOffCount from "./TimeOff/getTimeOffCount";
import updateTimeOffDetail from "./TimeOff/updateTimeoffDetail";
import filterTimeOff from "./TimeOff/filter";
import leaveTypeCount from "./TimeOff/leave_type_count";
import userSeen from "./TimeOff/userSeen";
import getNotification from "./TimeOff/getNotification";
import getPerformanceReview from "./PerformanceReview/getPerformanceReview";
import createPerformanceRating from "./PerformanceRating/createPerformanceRating";
import viewPerformanceReview from "./PerformanceReview/viewPerformanceReview";
import getPerformanceRating from "./PerformanceRating/getPerformanceRating";
import createPerformanceReview from "./PerformanceReview/createPerformanceReview";
import createComment from "./Comment/createComment";
import viewComment from "./Comment/viewComment";
import slackLogin from "../reducers/Auth/slackLogin";
import getProfileViewDetails from "../reducers/ProfileView/getProfileViewDetails";
import updateProfileView from "../reducers/ProfileView/updateProfileViewDetails";
import getUsers from "../reducers/User/getUsers";
import updateOrganizationHierarchy from "../reducers/Organization/updateOrganizationHierarchy";
import getOrganizationHierarchy from "../reducers/Organization/getOrganizationHierarchy";
import createCheckin from "../reducers/Checkins/createCheckins";
import getCompanyChart from "./CompanyChart/getCompanyChart";
import getCheckin from "./Checkin/getCheckin";
import getCheckins from "./Checkin/getCheckins";
import getOrgUsers from "../reducers/User/getOrgUsers";
import createHighFive from "../reducers/HighFive/createHighFive";
import createHighFiveComment from "../reducers/HighFive/createHighFiveComment";
import getHighFiveComment from "../reducers/HighFive/getHighFiveComment";
import deleteHighFiveComment from "../reducers/HighFive/deleteHighFiveComment";
import getHighFive from "../reducers/HighFive/getHighFive";
import createHighFiveLike from "../reducers/HighFive/createHighFiveLike";
import getHighFiveLike from "../reducers/HighFive/getHighFiveLike";
import getHighFiveLikes from "../reducers/HighFive/getHighFiveLikes";
import getMostAppreciated from "../reducers/HighFive/getMostAppreciated";
import getTopAppreciated from "../reducers/HighFive/getTopAppreciated";
import createObjective from "../reducers/Okr/createObjective";
import createTeamObjective from "../reducers/Okr/createTeamObjective";
import createKeyResult from "../reducers/Okr/createKeyResult";
import updateKeyResult from "../reducers/Okr/updateKeyResult";
import getKeyResult from "../reducers/Okr/getKeyResult";
import getObjective from "../reducers/Okr/getObjective";
import viewObjective from "../reducers/Okr/viewObjective";
import getTeamObjective from "../reducers/Okr/getTeamObjective";
import getRequestedTimeOff from "../reducers/TimeOff/getRequestedTimeOffs";
import getApprovedTimeOffs from "../reducers/TimeOff/getApprovedTimeOffs";
import getRejectedTimeOffs from "../reducers/TimeOff/getRejectedTimeOffs";
import createOtp from "../reducers/Otp/createOtp";
import verifyOtp from "../reducers/Otp/verifyOtp";
import getSql from "../reducers/SqlData/getSqlData";

export default combineReducers({
  userRegistration,
  userLogin,
  userLogout,
  createOrganization,
  createWeeklyGoal,
  createGoal,
  sendInvite,
  handleUnauthRoutes,
  weeklyGoalList,
  deleteWeeklyGoal,
  WeeklyGoalDetail,
  goalsList,
  deleteGoal,
  updateGoal,
  acceptInvite,
  updateWeeklyGoal,
  getOrganization,
  createTeam,
  addTeamMember,
  getEmployees,
  getManager,
  getTeams,
  getTeam,
  deleteTeam,
  getTeamMembers,
  deleteTeamMember,
  updateTeam,
  createTimeoffRequest,
  getTimeOff,
  getTimeOffCount,
  filterTimeOff,
  getTimeoffDetail,
  updateTimeOffDetail,
  leaveTypeCount,
  userSeen,
  getNotification,
  getPerformanceReview,
  createPerformanceRating,
  viewPerformanceReview,
  getPerformanceRating,
  createPerformanceReview,
  createComment,
  viewComment,
  getEmail,
  slackLogin,
  getProfileViewDetails,
  updateProfileView,
  getUsers,
  updateOrganizationHierarchy,
  getOrganizationHierarchy,
  createCheckin,
  getCompanyChart,
  getCheckin,
  getCheckins,
  getOrgUsers,
  createHighFive,
  getHighFive,
  getMostAppreciated,
  getTopAppreciated,
  createHighFiveLike,
  createObjective,
  createTeamObjective,
  createKeyResult,
  getObjective,
  viewObjective,
  getTeamObjective,
  getKeyResult,
  updateKeyResult,
  createHighFiveComment,
  getHighFiveComment,
  deleteHighFiveComment,
  getHighFiveLike,
  getHighFiveLikes,
  getRequestedTimeOff,
  getApprovedTimeOffs,
  getRejectedTimeOffs,
  getOwner,
  createOtp,
  verifyOtp,
  getSql,
});
