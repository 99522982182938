import {
  UPDATE_WEEKLY_GOAL_SUCCESS,
  UPDATE_WEEKLY_GOAL_REQUEST,
  UPDATE_WEEKLY_GOAL_ERROR,
} from "../../actions/Organization/updateWeeklyGoal";

const updateWeeklyGoal = (state = null, action) => {
  switch (action.type) {
    case UPDATE_WEEKLY_GOAL_REQUEST: {
      return true;
    }
    case UPDATE_WEEKLY_GOAL_SUCCESS: {
      return action.response;
    }
    case UPDATE_WEEKLY_GOAL_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default updateWeeklyGoal;
