import React from "react";
import { Button } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import "./style.css";

const PageNotFound = () => {
  const history = useHistory();
  const onClickDashbaordBtn = () => {
    history.push("/dashboard");
  };
  return (
    <div className="page-not-found">
      <div className="page-not-found-icon">
        <QuestionCircleFilled style={{ fontSize: "70px", color: "green" }} />
      </div>

      <p className="page-not-found-heading">Page Not Found!</p>
      <p className="page-not-found-subheading">
        We're sorry, the page you requested could not be found.
      </p>
      <p className="error">Error code:404</p>
      <Button className="go-to-dashbaord-btn" onClick={onClickDashbaordBtn}>
        {" "}
        Go to dashbaord
      </Button>
    </div>
  );
};

export default PageNotFound;
