import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";

export const VIEW_COMMENT_SUCCESS = "VIEW_COMMENT_SUCCESS";
export const VIEW_COMMENT_ERROR = "VIEW_COMMENT_ERROR";
export const VIEW_COMMENT_REQUEST = "VIEW_COMMENT_REQUEST";

export const viewComment = (employee_id) => (dispatch) => {
  const current_url = window.location.href;
  var url = new URL(current_url);
  var path = url.pathname;
  var performance_review_id = path.substring(path.lastIndexOf("/") + 1);

  dispatch({ type: VIEW_COMMENT_REQUEST });
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${employee_id}/performance_review/${performance_review_id}/employee_comments`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  }).then((response) => {
    dispatch({
      type: VIEW_COMMENT_SUCCESS,
      response: response,
    });
  });
  // .catch((error) => {
  //   if (error.response.status === 401) {
  //     dispatch(tokenAuthFailedAction());
  //   }
  //   dispatch({
  //     type: VIEW_COMMENT_ERROR,
  //     response: error.response,
  //   });
  // });
};
