import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import WeeklyGoalDetails from "../../components/WeeklyGoalDetails/WeeklyGoalDetails";
import "./style.css";

const ViewWeeklyGoal = (props) => {
  useEffect(() => {});

  return (
    <>
      <div>
        <WeeklyGoalDetails weeklygoalid={props.match.params.GoalId} />
      </div>
    </>
  );
};
export default withRouter(ViewWeeklyGoal);
