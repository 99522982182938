import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
export const SEND_INVITE_SUCCESS = "SEND_INVITE_SUCCESS";
export const SEND_INVITE_ERROR = "SEND_INVITE_ERROR";
export const SEND_INVITE_REQUEST = "SEND_INVITE_REQUEST ";

export const sendInvite = (inviteList) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");

  dispatch({ type: SEND_INVITE_REQUEST });
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/invite`,
    data: {
      email: inviteList.email,
      role: inviteList.role,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      return dispatch({
        type: SEND_INVITE_SUCCESS,
        response: {
          successStatus: response.status,
          successEmail: inviteList.email,
        },
      });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(tokenAuthFailedAction());
      }
      return dispatch({
        type: SEND_INVITE_ERROR,
        response: {
          errorStatus: error.response.status,
          failedEmail: inviteList.email,
        },
      });
    });
};
