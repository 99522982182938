import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS";
export const GET_EMPLOYEES_ERROR = "GET_EMPLOYEES_ERROR";
export const GET_EMPLOYEES_REQUEST = "GET_EMPLOYEES_REQUEST ";

export const getEmployees = (id) => (dispatch) => {
  dispatch({ type: GET_EMPLOYEES_REQUEST });
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${id}/employees`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_EMPLOYEES_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(tokenAuthFailedAction());
      }
      return dispatch({
        type: GET_EMPLOYEES_ERROR,
        response: error.response,
      });
    });
};
