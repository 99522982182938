import React, { useState, useEffect } from "react";
import { viewObjective } from "../../redux/actions/Okr/viewObjective";
import { getTeamObjective } from "../../redux/actions/Okr/getTeamObjective";
import { getKeyResult } from "../../redux/actions/Okr/getKeyResult";
import { updateKeyResult } from "../../redux/actions/Okr/updateKeyResult";
import { createKeyResult } from "../../redux/actions/Okr/createKeyResult";
import { getOrgUsers } from "../../redux/actions/User/getOrgUsers";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Row, Col, Radio } from "antd";
import { withRouter, useParams } from "react-router-dom";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import Loader from "../../components/Loader";
import { getTeams } from "../../redux/actions/Team/getTeams";
import { convertUNIXString } from "../../utils/convertUnixTimeStamp";
import "./style.css";

const UpdateOkr = (props) => {
  const dispatch = useDispatch();
  let id = useParams();
  id = id.id;
  const getTeamsStatus = useSelector((state) => state.getTeams);
  const viewObjectiveStatus = useSelector((state) => state.viewObjective);
  const getTeamObjectiveStatus = useSelector((state) => state.getTeamObjective);
  const getKeyResultStatus = useSelector((state) => state.getKeyResult);
  const updateKeyResultStatus = useSelector((state) => state.updateKeyResult);
  const createKeyResultStatus = useSelector((state) => state.createKeyResult);
  const [teamList, setTeamList] = useState();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();
  const [keyResult, setKeyResult] = useState();
  const [department, setDepartment] = useState();

  useEffect(() => {
    if (getTeamsStatus && getTeamsStatus.status === 200) {
      setTeamList(getTeamsStatus.data);
    }
  }, [getTeamsStatus]);

  useEffect(() => {
    dispatch(getTeams());
    dispatch(getOrgUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(viewObjective(id));
    dispatch(getTeamObjective(id));
    dispatch(getKeyResult(id));
    setLoader(true);
  }, [dispatch, id]);

  const onFinish = (objective) => {
    let count = Object.keys(objective).length;
    if (objective.fields !== undefined) {
      for (let j = 0; j < objective.fields.length; j++) {
        dispatch(
          createKeyResult(
            id,
            objective.fields[j].keyresultsInput,
            objective.fields[j].targetInput,
            objective.fields[j].unit
          )
        );
      }
    }
    for (let i = 0; i < count; i++) {
      dispatch(updateKeyResult(id, objective[i]));
    }
  };

  useEffect(() => {
    if (viewObjectiveStatus && viewObjectiveStatus.status === 200) {
      setData(viewObjectiveStatus.data[0]);
      setLoader(false);
    }
  }, [viewObjectiveStatus]);

  useEffect(() => {
    if (getTeamObjectiveStatus && getTeamObjectiveStatus.status === 200) {
      setDepartment(getTeamObjectiveStatus.data);
    }
  }, [getTeamObjectiveStatus]);

  useEffect(() => {
    if (updateKeyResultStatus && updateKeyResultStatus.status === 200) {
      window.location.href = "/okrs";
    }
  }, [updateKeyResultStatus]);

  useEffect(() => {
    if (createKeyResultStatus && createKeyResultStatus.status === 201) {
      window.location.href = "/okrs";
    }
  }, [createKeyResultStatus]);

  useEffect(() => {
    if (getKeyResultStatus && getKeyResultStatus.status === 200) {
      setKeyResult(getKeyResultStatus.data);
    }
  }, [getKeyResultStatus]);

  return (
    <>
      <p className="createobjectiveTitle">Update OKR</p>
      <div className="createobjective">
        <Form
          name="basic"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinish}
          initialValues={{ keyresultsInput: "default value" }}
        >
          {loader === false ? (
            <>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div className="title-div">
                    <p className="objectivesLable">Aspirational Objective</p>
                    <p className="objectivesContent">{data && data.title}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={3} xl={3}>
                  <p className="objectivesLable">Goal Type:</p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <p className="objectivesContent">{data && data.goalType}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={3} xl={3}>
                  <p className="objectivesLable">Departments:</p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  {department &&
                    department.map((value) => {
                      const { team_id } = value || {};

                      const team =
                        (teamList &&
                          teamList.find(({ id }) => id === team_id)) ||
                        {};
                      return <p className="objectivesContent">{team.title}</p>;
                    })}
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={3} xl={3}>
                  <p className="objectivesLable">Goal Cycle</p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={3} xl={3}>
                  <p className="objectivesLable">{data && data.timePeriod}</p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <p className="objectivesContent">
                    {convertUNIXString(data && data.startDate)}---
                    {convertUNIXString(data && data.endDate)}
                  </p>
                </Col>
              </Row>

              {/* {result1 && result1.length === 0 ? ( */}
              {/* <> */}
              {keyResult &&
                keyResult.map((value, index) => (
                  <>
                    <Row className="defaultsKeyResultsRow">
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <p className="objectivesLable">
                          Measureable Key Results
                        </p>
                        <Form.Item
                          hidden={true}
                          label=""
                          name={[index, "id"]}
                          initialValue={value.id}
                        >
                          <Input className="id" type="text" />
                        </Form.Item>
                        <Form.Item
                          label=""
                          name={[index, "keyresultsInput"]}
                          initialValue={value.title}
                        >
                          <Input className="keyResultsInput" type="text" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                        <p className="objectivesLable">Target</p>
                        <Form.Item
                          label=""
                          name={[index, "targetInput"]}
                          initialValue={value.target}
                        >
                          <Input
                            className="targetInput"
                            type="number"
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                        <p className="objectivesLable">Target Achieved</p>
                        <Form.Item
                          label=""
                          name={[index, "targetAchievedInput"]}
                          initialValue={value.target_achieved}
                        >
                          <Input
                            className="targetAchievedInput"
                            type="number"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ))}
              {/* </>
            ) : (
                <>
                  {keyResult &&
                    keyResult.map((value, index) => (
                      <Row className="defaultsKeyResultsRow">
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                          <h3>{value.title}</h3>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                          <h3>{value.target}</h3>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                          <h3>{value.target_achieved}</h3>
                        </Col>
                      </Row>
                    ))}
                </>
              )} */}
              <>
                {/* {result1 && result1.length === 0 ? (
                <> */}
                <Form.List name="fields">
                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map((field, index) => (
                          <div key={field.key}>
                            <Row className="keyResultsRow">
                              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                <Form.Item
                                  name={[index, "keyresultsInput"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "This field is required.",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="keyResultsInput"
                                    type="text"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <Form.Item
                                  name={[index, "targetInput"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "This field is required.",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="targetInput"
                                    type="number"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <Form.Item
                                  name={[index, "unit"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select unit.",
                                    },
                                  ]}
                                >
                                  <Radio.Group
                                    optionType="button"
                                    buttonStyle="solid"
                                    size="large"
                                  >
                                    <Radio.Button
                                      className="dollarRadioBtn"
                                      value="$"
                                    >
                                      $
                                    </Radio.Button>
                                    <Radio.Button
                                      className="percentageRadioBtn"
                                      value="%"
                                    >
                                      %
                                    </Radio.Button>
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                {fields.length > 1 ? (
                                  <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    style={{ margin: "0 8px" }}
                                    onClick={() => {
                                      remove(field.name);
                                    }}
                                  />
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                        ))}

                        <Form.Item>
                          <PlusOutlined
                            className="plusIcon"
                            onClick={() => {
                              add();
                            }}
                          />
                        </Form.Item>
                      </div>
                    );
                  }}
                </Form.List>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="updateObjectiveBtn"
                  >
                    Update OKR
                  </Button>
                </Form.Item>
                {/* </>
              ) : (
                  ""
                )} */}
              </>
            </>
          ) : (
            <Loader />
          )}
        </Form>
      </div>
    </>
  );
};

export default withRouter(UpdateOkr);
