import React, { useState, useEffect } from "react";
import { Input, List, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getProfileViewDetails } from "../../redux/actions/ProfileView/getProfileViewDetails";
import Loader from "../../components/Loader";
import chatLogo from "../../assets/chatLogo.png";
import sendBtn from "../../assets/send.svg";
import userDummy from "../../assets/user.png";
import { getSql } from "../../redux/actions/SqlData/getSqlData";
import "./style.css";

const ChatComponent = () => {
  const [chatLoaderState, setChatLoaderState] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const getSqlStatus = useSelector((state) => state.getSql);
  const id = localStorage.getItem("user_id");
  const dispatch = useDispatch();

  function clearsessionStorage() {
    sessionStorage.removeItem("messages");
  }

  window.addEventListener("storage", function (event) {
    if (event.key === null) {
      console.log("sessionStorage cleared in another tab!");
    }
  });

  window.addEventListener("focus", clearsessionStorage);

  useEffect(() => {
    const savedMessages = sessionStorage.getItem("messages");
    if (savedMessages) {
      setMessages(JSON.parse(savedMessages));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("messages", JSON.stringify(messages));
  }, [messages]);

  const handleUserInput = (e) => {
    setUserInput(e.target.value);
  };

  const sendMessageWithInfo = async (userInput, record) => {
    if (userInput.trim() !== "" && record !== "") {
      const read =
        "read the following data and answer the following and provide short answer";
      const content = `${read} ${record} ${userInput}`;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer 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",
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "user",
              content: content,
            },
          ],
        }),
      };

      await sendMessage(requestOptions);
    }
  };

  const sendMessage = async (requestOptions) => {
    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        requestOptions
      );
      const data = await response.json();
      const assistantMessage = {
        role: "assistant",
        content: data.choices[0].message.content,
        timestamp: new Date().toISOString(),
      };
      setMessages((prevMessages) => [...prevMessages, assistantMessage]);
      setChatLoaderState(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSendMessage = async () => {
    const user_id = localStorage.getItem("user_id");

    setChatLoaderState(true);
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", content: userInput, timestamp: new Date().toISOString() },
    ]);

    await dispatch(getSql(user_id, userInput));
    setUserInput("");
  };

  useEffect(() => {
    if (getSqlStatus && getSqlStatus.status === 404) {
      const record = "please provide correct data";
      sendMessageWithInfo(userInput, record);
    }
  }, [getSqlStatus]);

  useEffect(() => {
    if (getSqlStatus && getSqlStatus.status === 200) {
      const record = JSON.stringify(getSqlStatus.data);
      sendMessageWithInfo(userInput, record);
    }
  }, [getSqlStatus]);

  const getProfileViewDetailsStatus = useSelector(
    (state) => state.getProfileViewDetails
  );

  useEffect(() => {
    dispatch(getProfileViewDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (
      getProfileViewDetailsStatus &&
      getProfileViewDetailsStatus.status === 200
    ) {
      setUserDetails(getProfileViewDetailsStatus.data[0]);
    }
  }, [getProfileViewDetailsStatus]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && userInput.trim() !== "") {
      handleSendMessage();
    }
  };

  return (
    <Row justify="center">
      <div
        className="chat-window"
        style={{
          backgroundColor: "white",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          borderRadius: "8px",
          width: "350px",
        }}
      >
        <div className="chat-header">
          <div className="user-details div">
            <div className="chat-logo">
              <img src={chatLogo} alt="" className="chat-logo-style" />
            </div>
            <div className="chat-user-details">
              <p className="chat-username">Bling</p>
              <div className="online">
                <div className="dot-circle"></div>
                <p className="online-status">Online</p>
              </div>
            </div>
          </div>
        </div>
        <div className="chat-content">
          <List
            className="message-list"
            itemLayout="horizontal"
            dataSource={messages}
            renderItem={(message) => {
              const date = new Date(message.timestamp);
              const formattedTime = isNaN(date.getTime())
                ? "Invalid Date"
                : date.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  });

              return (
                <List.Item className="chat-list-items">
                  <List.Item.Meta
                    avatar={null}
                    title={
                      <div className="message-container">
                        <div className={`message ${message.role}`}>
                          {message.content}
                        </div>
                        {message.role === "user" ? (
                          <div className="avatar-container user-avatar">
                            <img
                              src={
                                (userDetails && userDetails.image) || userDummy
                              }
                              alt="User Avatar"
                              className="avatar-img"
                            />
                            <div className="timestamp">{formattedTime}</div>
                          </div>
                        ) : (
                          <div className="avatar-container bot-img">
                            <img
                              src={chatLogo}
                              alt="Bot Avatar"
                              className="avatar-img bot-img-style"
                            />
                            <div className="timestamp">{formattedTime}</div>
                          </div>
                        )}
                      </div>
                    }
                  />
                </List.Item>
              );
            }}
          />
        </div>
        <div className="textarea-with-btn">
          <div className="send-message-div">
            <Input
              placeholder="Type your message here..."
              value={userInput}
              onChange={handleUserInput}
              onKeyDown={handleKeyDown}
              className="chat-input"
            />
            {chatLoaderState === true ? (
              <Loader />
            ) : (
              <div
                className={`send-btn ${!userInput ? "disabled" : ""}`}
                onClick={userInput ? handleSendMessage : null}
              >
                <img src={sendBtn} alt="" className="send-btn-style" />
              </div>
            )}
          </div>
        </div>
      </div>
    </Row>
  );
};

export default ChatComponent;
