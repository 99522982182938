import {
  GET_USERS_SUCCESS,
  GET_USERS_REQUEST,
  GET_USERS_ERROR,
} from "../../actions/User/getUsers";

const getUsers = (state = null, action) => {
  switch (action.type) {
    case GET_USERS_REQUEST: {
      return true;
    }
    case GET_USERS_SUCCESS: {
      return action.response;
    }
    case GET_USERS_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getUsers;
