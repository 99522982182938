import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
export const GET_CHEKIN_SUCCESS = "GET_CHEKIN_SUCCESS";
export const GET_CHEKIN_ERROR = "GET_CHEKIN_ERROR";
export const GET_CHEKIN_REQUEST = "GET_CHEKIN_REQUEST ";

export const getCheckin = (id) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");
  dispatch({ type: GET_CHEKIN_REQUEST });
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/weekly_goals/${id}/check_ins`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_CHEKIN_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(tokenAuthFailedAction());
      }
      return dispatch({
        type: GET_CHEKIN_ERROR,
        response: error.response,
      });
    });
};
