import {
    GET_TEAM_MEMBERS_SUCCESS,
    GET_TEAM_MEMBERS_REQUEST,
    GET_TEAM_MEMBERS_ERROR,
  } from "../../actions/Team/getTeamMembers";
  
  const getTeamMembers = (state = null, action) => {
    switch (action.type) {
      case GET_TEAM_MEMBERS_REQUEST: {
        return true;
      }
      case GET_TEAM_MEMBERS_SUCCESS: {
        return action.response;
      }
      case GET_TEAM_MEMBERS_ERROR: {
        return action.response;
      }
      default:
        return state;
    }
  };
  
  export default getTeamMembers;
  