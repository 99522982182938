import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";

export const UPDATE_TIME_OFF_DETAIL_SUCCESS = "UPDATE_TIME_OFF_DETAIL_SUCCESS";
export const UPDATE_TIME_OFF_DETAIL_ERROR = "UPDATE_TIME_OFF_DETAIL_ERROR";
export const UPDATE_TIME_OFF_DETAIL_REQUEST = "UPDATE_TIME_OFF_DETAIL_REQUEST ";

export const updateTimeOffDetail = (updateTimeOff) => (dispatch) => {
  const current_url = window.location.href;
  var url = new URL(current_url);
  var path = url.pathname;
  var time_off_detail_id = path.substring(path.lastIndexOf("/") + 1);
  const user_id = localStorage.getItem("user_id");
  // let data = new FormData();
  // data.append("time_off[status]", updateTimeOff);

  dispatch({ type: UPDATE_TIME_OFF_DETAIL_REQUEST });
  return axios({
    method: "put",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/time_offs/${time_off_detail_id}`,
    data: {
      status: updateTimeOff,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      localStorage.setItem("leave_type_status", updateTimeOff);
      return dispatch({
        type: UPDATE_TIME_OFF_DETAIL_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_TIME_OFF_DETAIL_ERROR,
        response: error.response,
      });
    });
};
