import {
  CREATE_OTP_SUCCESS,
  CREATE_OTP_REQUEST,
  CREATE_OTP_ERROR,
} from "../../actions/Otp/createOtp";

const createOtp = (state = null, action) => {
  switch (action.type) {
    case CREATE_OTP_REQUEST: {
      return true;
    }
    case CREATE_OTP_SUCCESS: {
      return action.response;
    }
    case CREATE_OTP_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default createOtp;
