import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTimeoffRequest } from "../../redux/actions/Organization/createTimeoffRequest";
import {
  Button,
  Input,
  Form,
  DatePicker,
  Select,
  Row,
  Col,
  InputNumber,
} from "antd";
import { withRouter } from "react-router-dom";
import { getOrganization } from "../../redux/actions/Organization/getOrganization";
import { getApprovedTimeOffs } from "../../redux/actions/TimeOff/getApprovedTimeOffs";
import "./style.css";
import Schema from "async-validator";
const { Option } = Select;

const TimeoffRequest = (props) => {
  Schema.warning = function () {};
  const { history } = props;
  const dispatch = useDispatch();
  const getOrganizationStatus = useSelector((state) => state.getOrganization);
  const createTimeOffRequestStatus = useSelector(
    (state) => state.createTimeoffRequest
  );
  const getApprovedTimeOffsStatus = useSelector(
    (state) => state.getApprovedTimeOffs
  );
  const [organizationDetails, setOrganizationDetails] = useState();
  const [currentUserRemainingTimeoffs, setCurrentUserRemainingTimeoffs] =
    useState("");
  const [userApprovedTimeoffs, setUserApprovedTimeoffs] = useState([]);
  const [currentUserApprovedTimeoffs, setCurrentUserApprovedTimeoffs] =
    useState([]);
  const [totalWorkDays, setTotalWorkDays] = useState(0);
  const [totalCurrentMonthWorkDays, setTotalCurrentMonthWorkDays] = useState(0);

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");

    if (user_id) {
      const filteredTimeoffs = userApprovedTimeoffs.filter(
        (timeoff) => String(timeoff.user_id) === user_id
      );
      setCurrentUserApprovedTimeoffs(filteredTimeoffs);
      setCurrentUserRemainingTimeoffs(filteredTimeoffs[0]);
    }
  }, [userApprovedTimeoffs]);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    const currentMonthTimeoffs = currentUserApprovedTimeoffs.filter(
      (timeoff) => {
        const timeoffStartDate = new Date(timeoff.start_date * 1000);
        return (
          timeoffStartDate.getMonth() + 1 === currentMonth &&
          timeoffStartDate.getFullYear() === currentYear
        );
      }
    );

    const totalCurrentMonthDays = currentMonthTimeoffs.reduce(
      (total, timeoff) => total + timeoff.work_days,
      0
    );
    setTotalCurrentMonthWorkDays(totalCurrentMonthDays);
  }, [currentUserApprovedTimeoffs]);

  useEffect(() => {
    const workDaysArray = currentUserApprovedTimeoffs.map(
      (timeoff) => timeoff.work_days
    );
    const totalDays = workDaysArray.reduce((total, days) => total + days, 0);
    setTotalWorkDays(totalDays);
  }, [currentUserApprovedTimeoffs]);

  const onFinish = (values) => {
    if (values && organizationDetails && organizationDetails.time_off_request) {
      dispatch(
        createTimeoffRequest(
          values,
          organizationDetails && organizationDetails.time_off_request
        )
      );
    }
  };

  useEffect(() => {
    dispatch(getOrganization());
    dispatch(getApprovedTimeOffs());
  }, [dispatch]);

  useEffect(() => {
    if (getOrganizationStatus && getOrganizationStatus.status === 200) {
      setOrganizationDetails(getOrganizationStatus.data[0]);
    }
  }, [getOrganizationStatus]);

  useEffect(() => {
    if (
      createTimeOffRequestStatus &&
      createTimeOffRequestStatus.status === 201
    ) {
      history.push("/timeoffrequestlisting");
    }
  }, [createTimeOffRequestStatus]);
  useEffect(() => {
    if (getApprovedTimeOffsStatus && getApprovedTimeOffsStatus.status === 201) {
      setUserApprovedTimeoffs(getApprovedTimeOffsStatus.data);
    }
  }, [getApprovedTimeOffsStatus]);

  const onChange = (value) => {
    if (value === "other_leave") {
      document.getElementById("otherInputId").style.display = "block";
    } else {
      document.getElementById("otherInputId").style.display = "none";
    }
  };

  const onClickDashboard = () => {
    history.push("/dashboard");
  };

  return (
    <>
      <h1 className="timeoff-request-heading">Time Off Request</h1>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <div className="timeoff-count-div">
            <p className="count-heading-style">Total Leaves</p>
            <p className="remainingTimeoffCount">
              {organizationDetails && organizationDetails.time_off_request}{" "}
            </p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <div className="timeoff-count-div">
            <p className="count-heading-style">Leave Available</p>
            <p className="remainingTimeoffCount">
              {currentUserApprovedTimeoffs.length > 0
                ? currentUserApprovedTimeoffs[0]?.total_time_off
                : organizationDetails && organizationDetails.time_off_request}
            </p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <div className="timeoff-count-div">
            <p className="count-heading-style">Leaves Taken</p>
            <p className="remainingTimeoffCount">{totalWorkDays} </p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <div className="timeoff-count-div">
            <p className="count-heading-style">Used This Month</p>
            <p className="remainingTimeoffCount">
              {totalCurrentMonthWorkDays}{" "}
            </p>
          </div>
        </Col>
      </Row>

      <div className="timeoffrequest">
        {currentUserRemainingTimeoffs &&
        currentUserRemainingTimeoffs.total_time_off === 0 ? (
          <div className="errorBlock">
            <div className="errorBlockContent">
              <p>
                Sorry! you can't make time of request because you don't have
                more work days.
              </p>
              <Button
                type="primary"
                onClick={onClickDashboard}
                className="go-to-dashboard-btn"
              >
                Go to Dashboard
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className="time-off-request-main">
              <div className="time-off-request-form">
                <Form onFinish={onFinish}>
                  <Form.Item
                    className="startDateInput"
                    label="Start Date"
                    name="startDate"
                    rules={[
                      {
                        required: true,
                        message: "Please input your starting date!",
                      },
                    ]}
                  >
                    <DatePicker
                      className="startDateStyle"
                      id="startDate"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    className="endDateInput"
                    label="End Date"
                    name="endDate"
                    rules={[
                      {
                        required: true,
                        message: "Please input  your ending date!",
                      },
                    ]}
                  >
                    <DatePicker
                      className="endDateStyle"
                      id="endDate"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    className="leaveDurationLable"
                    label="Leave Duration"
                    name="leaveDuration"
                    rules={[
                      {
                        required: true,
                        message: "Please input type of leave",
                      },
                    ]}
                  >
                    <Select name="leaveDuration" mode="radio">
                      <Option id="firstHalfLeave" value="first_half">
                        First Half
                      </Option>
                      <Option id="afterLunchLeave" value="after_lunch">
                        After Lunch
                      </Option>
                      <Option id="fullDayLeave" value="full_day">
                        All Day
                      </Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    className="typeofLeaveLable"
                    label="Type of Leave"
                    name="leaveType"
                    rules={[
                      {
                        required: true,
                        message: "Please input type of leave",
                      },
                    ]}
                  >
                    <Select
                      name="leaveType"
                      mode="radio"
                      onChange={onChange}
                      id="typeOfLeave"
                    >
                      <Option id="sickLeave" value="sick_leave">
                        Sick Leave
                      </Option>
                      <Option id="annualLeave" value="annual_leave">
                        Annual Leave
                      </Option>
                      <Option id="emergencyLeave" value="emergency_leave">
                        Emergency Leave
                      </Option>
                      <Option id="vocationalLeave" value="vocational_leave">
                        Vocational Leave
                      </Option>
                      <Option id="otherLeave" value="other_leave">
                        Other
                      </Option>
                    </Select>
                  </Form.Item>
                  <div className="timeofRequestOther" id="otherInputId">
                    <Form.Item
                      name="other"
                      label="Reason"
                      rules={[
                        {
                          required: false,
                          message: "Please input other",
                        },
                      ]}
                    >
                      <Input.TextArea
                        className="inputStyle"
                        placeholder="Write Other Reason..."
                        id="otherInput"
                        name="otherInput"
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    className="workDaysLable"
                    name="workDays"
                    label="Work Days"
                    rules={[
                      {
                        type: "number",
                        min: 1,
                        max:
                          currentUserApprovedTimeoffs.length > 0
                            ? currentUserApprovedTimeoffs[0]?.total_time_off
                            : organizationDetails &&
                              organizationDetails.time_off_request,
                      },
                      {
                        required: true,
                        message: "Please input work days",
                      },
                    ]}
                  >
                    <InputNumber
                      className="workDaysInputStyle"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    className="noteLable"
                    label="Note"
                    name="note"
                    rules={[
                      {
                        required: true,
                        message: "Please input note",
                      },
                    ]}
                  >
                    <Input.TextArea
                      className="inputStyle"
                      placeholder="Note"
                      id="noteInput"
                      name="noteInput"
                    />
                  </Form.Item>

                  <Form.Item>
                    <div className="timeoff-submit-btn-div">
                      <Button
                        className="timeoffSubmitBtn"
                        htmlType="submit"
                        id="timeOffSubmit"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default withRouter(TimeoffRequest);
