import {
  GET_HIGH_FIVE_SUCCESS,
  GET_HIGH_FIVE_REQUEST,
  GET_HIGH_FIVE_ERROR,
} from "../../actions/HighFive/getHighFive";

const getHighFive = (state = null, action) => {
  switch (action.type) {
    case GET_HIGH_FIVE_REQUEST: {
      return true;
    }
    case GET_HIGH_FIVE_SUCCESS: {
      return action.response;
    }
    case GET_HIGH_FIVE_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getHighFive;
