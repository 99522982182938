import { Button, Typography, Input, Form, Row, Col } from "antd";
import registerLeft from "../../assets/register.png";
import "./style.css";

const ResetPassword = () => {
  const onFinish = (values) => {};
  return (
    <>
      <Row>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <div className="register-left">
            <div className="register-left-content">
              <img src={registerLeft} alt="" />
              <h1 className="registration-heading-styling">
                Welcome to High-Knot
              </h1>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <div className="reset-password-main">
            <div className="reset-password-form">
              <Typography className="reset-password-title">
                Reset Your Password
              </Typography>

              <Form hideRequiredMark onFinish={onFinish}>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password placeholder="Password" size="large" />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          "The two passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Confirm  Password"
                    size="large"
                  />
                </Form.Item>
                <Button
                  htmlType="submit"
                  className="submitNewButton"
                  type="primary"
                >
                  Reset my password
                </Button>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ResetPassword;
