import React, { useState } from "react";
import { Tabs, Row, Col } from "antd";
import okrRight from "../../assets/okr-right.png";
import "./style.css";
import TargetSetupComponent from "./TargetSetup";
import Targets from "./Targets";

const { TabPane } = Tabs;

const OkrsPage = () => {
  const [activeTab, setActiveTab] = useState(null);
  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div className="">
      <div>
        <Tabs activeKey={activeTab} type="card" onChange={handleTabChange}>
          <TabPane tab="Target Setup" key="1">
            <TargetSetupComponent />
          </TabPane>
          <TabPane tab="Targets" key="2">
            <Targets />
          </TabPane>
          {/* <TabPane tab="Appraisals" key="3">
            Appraisals
          </TabPane>
          <TabPane tab="Settings" key="4">
            Settings
          </TabPane>
          <TabPane tab="Reports" key="5">
            Reports
          </TabPane> */}
        </Tabs>
        <div className={`okr-content-div ${activeTab ? "hidden" : ""}`}>
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="okr-content-left">
                <p className="stay-updated">
                  Stay Updated on{" "}
                  <span className="employee-okr">Employees OKR</span>
                </p>
              </div>
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="okr-content-right">
                <img src={okrRight} className="okr-right-img" alt="employee" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default OkrsPage;
