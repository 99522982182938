import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";

export const DELETE_HIGH_FIVE_COMMENT_SUCCESS =
  "DELETE_HIGH_FIVE_COMMENT_SUCCESS";
export const DELETE_HIGH_FIVE_COMMENT_ERROR = "DELETE_HIGH_FIVE_COMMENT_ERROR";
export const DELETE_HIGH_FIVE_COMMENT_REQUEST =
  "DELETE_HIGH_FIVE_COMMENT_REQUEST";

export const deleteHighFiveComment = (id, comment_id) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");

  dispatch({ type: DELETE_HIGH_FIVE_COMMENT_REQUEST });
  return axios({
    method: "delete",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/high_fives/${id}/high_five_coments/${comment_id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      dispatch({
        type: DELETE_HIGH_FIVE_COMMENT_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: DELETE_HIGH_FIVE_COMMENT_ERROR,
        response: error.response,
      });
    });
};
