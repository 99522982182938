import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Row, Col } from "antd";
import { acceptInvite } from "../../redux/actions/InviteUser/acceptinvite";
import { getEmail } from "../../redux/actions/User/getEmail";
import registerLeft from "../../assets/register.png";
import "./style.css";
import {
  CognitoUserPool,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import { message } from "antd";

const AcceptInvitation = (props) => {
  const key = "updatable";
  const { history } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const getEmailStatus = useSelector((state) => state.getEmail);
  const acceptInviteStatus = useSelector((state) => state.acceptInvite);
  const [errorState] = useState([
    {
      error: "",
    },
  ]);

  useEffect(() => {
    dispatch(getEmail(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (getEmailStatus && getEmailStatus.status === 200) {
      dispatch(acceptInvite(id));
    }
  }, [getEmailStatus, dispatch, id]);

  useEffect(() => {
    if (acceptInviteStatus && acceptInviteStatus.status === 202) {
      history.push("/homepage");
    }
  }, [acceptInviteStatus, history]);

  const poolData = {
    UserPoolId: `${process.env.REACT_APP_AWS_USER_POOL_ID}`,
    ClientId: `${process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID}`,
  };

  const UserPool = new CognitoUserPool(poolData);
  const onFinish = (values) => {
    if (values) {
      const attributes = [
        new CognitoUserAttribute({ Name: "name", Value: values.firstName }),
        new CognitoUserAttribute({
          Name: "given_name",
          Value: values.lastName,
        }),
        new CognitoUserAttribute({
          Name: "custom:first_name",
          Value: values.firstName,
        }),
        new CognitoUserAttribute({
          Name: "custom:last_name",
          Value: values.lastName,
        }),
      ];

      UserPool.signUp(id, values.password, attributes, null, (err, data) => {
        if (err.code === "UsernameExistsException") {
          console.error("err", err);
        } else {
          console.log("data", data);
          dispatch(acceptInvite(values, id));
        }
      });
    }
    message.loading({ content: "Loading...", key });
    history.push(`/login`);
  };

  return (
    <>
      <Row>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <div className="register-left">
            <div className="register-left-content">
              <img src={registerLeft} alt="" />
              <h1 className="registration-heading-styling">
                Welcome to High-Knot
              </h1>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <div className="accept-invite-div">
            <div className="accept-invitation-inner">
              <h1 className="accept-invitation-heading">Create account</h1>
              <Form
                hideRequiredMark
                className="invitation-form-styling"
                onFinish={onFinish}
              >
                <Form.Item
                  name="firstName"
                  rules={[
                    { required: true, message: "First name is required" },
                    {
                      min: 3,
                      message:
                        "First name should be greater then 3 characters!",
                    },
                  ]}
                >
                  <Input placeholder="First name" size="large" />
                </Form.Item>
                {errorState.firstName ? (
                  <p className="err-msg">First name {errorState.firstName}</p>
                ) : (
                  ""
                )}
                <Form.Item
                  name="lastName"
                  rules={[
                    { required: true, message: "Last name is required" },
                    {
                      min: 3,
                      message: "Last name should be greater then 3 characters!",
                    },
                  ]}
                >
                  <Input placeholder="Last name" size="large" />
                </Form.Item>
                {errorState.lastName ? (
                  <p className="err-msg">Last name {errorState.lastName}</p>
                ) : (
                  ""
                )}
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password placeholder="Password" size="large" />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          "The two passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Confirm  Password"
                    size="large"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="create-account-btn"
                  >
                    Create Account{" "}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default withRouter(AcceptInvitation);
