import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col } from "antd";
import { getUsers } from "../../redux/actions/User/getUsers";
import { getCompanyChart } from "../../redux/actions/ComapanyChart/getCompanyChart";
import userDummy from "../../assets/user.png";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import "./style.css";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      display: true,
      beginAtZero: true,
      scaleLabel: {
        display: true,
        labelString: "Value",
      },
      ticks: {
        min: 1,
        max: 5,
        stepSize: 1,
        callback: function (label) {
          switch (label) {
            case 1:
              return "Awful";
            case 2:
              return "Bad";
            case 3:
              return "Ok";
            case 4:
              return "Happy";
            case 5:
              return "Great";
            default:
              return "";
          }
        },
      },
      gridLines: {
        display: false,
      },
    },
  },
};

export default function LineChart() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [chartData, setChartData] = useState([]);
  const [UsersList, setUsersList] = useState([]);
  const getUsersList = useSelector((state) => state.getUsers);
  const getCompanyChartStatus = useSelector((state) => state.getCompanyChart);

  useEffect(() => {
    dispatch(getCompanyChart());
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    if (getCompanyChartStatus && getCompanyChartStatus.status === 200) {
      setChartData(getCompanyChartStatus.data);
    }
  }, [getCompanyChartStatus]);
  useEffect(() => {
    if (getUsersList && getUsersList.status === 200) {
      setUsersList(getUsersList.data);
    }
  }, [getUsersList]);
  const onClickEmployee = (id) => {
    localStorage.setItem("employee_id", id);
    history.push(`/user_chart`);
  };

  let averageMood = chartData.map((a) => a.averageMood);
  let endDate = chartData.map((a) => a.endDate);

  const labels = endDate;
  const data = {
    labels,
    datasets: [
      {
        data: averageMood,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        pointRadius: 5,
      },
    ],
  };

  return (
    <>
      <p className="company-mood-chart-title">Company Mood Chart</p>
      <Row gutter={12}>
        <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={16}>
          <div className="company-chart">
            <Line options={options} data={data} />
          </div>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={8}>
          <div className="employees-mood-chart-main">
            {UsersList.map((employee, index) => (
              <div
                className="employee-details-div"
                key={index}
                onClick={() => onClickEmployee(employee.id)}
              >
                <Row gutter={16}>
                  <Col
                    className="gutter-row"
                    xs={3}
                    sm={3}
                    md={4}
                    lg={5}
                    xl={5}
                  >
                    <img
                      src={employee.image || userDummy}
                      alt="User"
                      className="employee-image"
                    />
                  </Col>
                  <Col
                    className="gutter-row"
                    sxs={18}
                    sm={18}
                    md={17}
                    lg={15}
                    xl={18}
                  >
                    <p className="weekly-goals-duration">
                      {employee.first_name + " " + employee.last_name}
                    </p>
                    <p className="weekly-goal-assignee">
                      Role: {employee.designation}
                    </p>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
}
