import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
export const ACCEPT_INVITE_SUCCESS = "ACCEPT_INVITE_SUCCESS";
export const ACCEPT_INVITE_ERROR = "ACCEPT_INVITE_ERROR";
export const ACCEPT_INVITE_REQUEST = "ACCEPT_INVITE_REQUEST ";

export const acceptInvite = (user, sub_id, email) => (dispatch) => {
  dispatch({ type: ACCEPT_INVITE_REQUEST });
  return axios({
    method: "put",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/invite`,
    data: {
      email: sub_id,
      first_name: user.firstName,
      last_name: user.lastName,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      return dispatch({
        type: ACCEPT_INVITE_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(tokenAuthFailedAction());
      }
      return dispatch({
        type: ACCEPT_INVITE_ERROR,
        response: error.response,
      });
    });
};
