import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_ERROR = "VERIFY_OTP_ERROR";
export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";

export const verifyOtp = (email, userOtp) => (dispatch) => {
  dispatch({ type: VERIFY_OTP_REQUEST });
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/verify_otp`,
    data: {
      email: email,
      otp: userOtp,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      dispatch({
        type: VERIFY_OTP_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(tokenAuthFailedAction());
      }
      dispatch({
        type: VERIFY_OTP_ERROR,
        response: error.response,
      });
    });
};
