import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import userDummy from "../../assets/user.png";
import { getMostAppreciated } from "../../redux/actions/HighFive/getMostAppreciated";

const MostAppreciatedHighFive = () => {
  const dispatch = useDispatch();
  const [MostAppreciated, setMostAppreciated] = useState();
  const getMostAppreciatedStatus = useSelector(
    (state) => state.getMostAppreciated
  );
  useEffect(() => {
    if (getMostAppreciatedStatus && getMostAppreciatedStatus.status === 200) {
      setMostAppreciated(getMostAppreciatedStatus.data);
    }
  }, [getMostAppreciatedStatus]);
  useEffect(() => {
    dispatch(getMostAppreciated());
  }, [dispatch]);

  return (
    <>
      <div className="mostAppreciatedHighFives">
        <p className="mostAppreciatedHeading">Most Appreciated </p>
        <div className="mostAppreciatedDetailsWithCount">
          {MostAppreciated &&
            MostAppreciated.map((value) => (
              <>
                {value.received_high_five !== null ? (
                  <Row gutter={16}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="topAppreciatedUserImg">
                        <img
                          src={value && value.image ? value.image : userDummy}
                          alt=""
                          className="highfie-users"
                        />
                        <p className="topAppreciatedUsername">
                          {" "}
                          {value.first_name} {value.last_name}
                        </p>
                      </div>
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="topAppreciatedCount">
                        {" "}
                        <p> {value.received_high_five}</p>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </>
            ))}
        </div>
      </div>
    </>
  );
};

export default MostAppreciatedHighFive;
