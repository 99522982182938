import axios from "axios";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
import { readLocalStorage } from "../../../utils/readLocalStorage";
export const GET_GOALS_LIST_SUCCESS = "GET_GOALS_LIST_SUCCESS";
export const GET_GOALS_LIST_ERROR = "GET_GOALS_LIST_ERROR";
export const GET_GOALS_LIST_REQUEST = "GET_GOALS_LIST_REQUEST ";

export const getGoals = (id) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");
  dispatch({ type: GET_GOALS_LIST_REQUEST });
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/weekly_goals/${id}/goals`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  }).then((response) => {
    return dispatch({ type: GET_GOALS_LIST_SUCCESS, response: response });
  });
  // .catch((error) => {
  //   if (error.response.status === 401) {
  //     dispatch(tokenAuthFailedAction());
  //   }
  //   return dispatch({ type: GET_GOALS_LIST_ERROR, response: error });
  // });
};
