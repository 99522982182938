import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, message, Row, Col } from "antd";
import registerLeft from "../../assets/register.png";
import "./style.css";
import { withRouter } from "react-router-dom";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { loginUser } from "../../redux/actions/User/login";
import { createOtp } from "../../redux/actions/Otp/createOtp";
import Loader from "../../components/Loader";

const Login = (props) => {
  const { history } = props;
  function getCookie(otp) {
    let name = otp + "=";
    let spli = document.cookie.split(";");
    for (var j = 0; j < spli.length; j++) {
      let char = spli[j];
      while (char.charAt(0) === " ") {
        char = char.substring(1);
      }
      if (char.indexOf(name) === 0) {
        return char.substring(name.length, char.length);
      }
    }
    return "";
  }
  const dispatch = useDispatch();
  const userLoginReducer = useSelector((state) => state.userLogin);
  const createOtpStatus = useSelector((state) => state.createOtp);

  useEffect(() => {
    if (createOtpStatus && createOtpStatus.status === 200) {
      localStorage.setItem(
        "userOtp",
        createOtpStatus && createOtpStatus.data.otp
      );
    }
  }, [createOtpStatus]);

  const onFinish = (values) => {
    const poolData = {
      UserPoolId: `${process.env.REACT_APP_AWS_USER_POOL_ID}`,
      ClientId: `${process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID}`,
    };

    const UserPool = new CognitoUserPool(poolData);

    const user = new CognitoUser({
      Username: values.email,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: values.email,
      Password: values.password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        localStorage.setItem("access-token", data.accessToken.jwtToken);
        localStorage.setItem("first_name", data.idToken.payload.name);
        localStorage.setItem("last_name", data.idToken.payload.given_name);
        dispatch(
          loginUser(
            values.email,
            data.idToken.payload.name,
            data.idToken.payload.given_name
          )
        );

        var otp = getCookie("otp");
        if (otp !== values.email) {
          dispatch(createOtp(values.email));
          const newOtpExpiryTime = new Date().getTime() + 5 * 60 * 1000;
          var date = new Date();
          date.setTime(newOtpExpiryTime);
          var expiry = "; expires=" + date.toUTCString();
          document.cookie = "otp=" + values.email + expiry + "; path=/";
          history.push("/otp");
        } else {
          setTimeout(orgStatus, 1200);
          message.success({
            content: "you are successfully logged in",
            duration: 3,
          });
          function orgStatus() {
            const orgId = localStorage.getItem("organization_id");
            if (orgId !== "null") {
              history.push("/dashboard");
            } else {
              history.push("/organizationregistration");
            }
          }
        }
      },

      onFailure: (err) => {
        console.error("onFailure:", err);
        message.error({
          content: err.message,
          duration: 3,
        });
      },

      newPasswordRequired: (data) => {
        console.log("newPasswordRequired:", data);
      },
    });
  };

  return (
    <>
      <Row>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <div className="register-left">
            <div className="register-left-content">
              <img src={registerLeft} alt="" />
              <h1 className="registration-heading-styling">
                Welcome to High-Knot
              </h1>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <div className="sign-in-main">
            <div className="signin-main-div">
              <h1 className="signin-heading-styling">Login into High-Knot</h1>
              <div className="signinInputStyle">
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Email"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                    ]}
                  >
                    <div className="passwordInput">
                      <Input
                        prefix={
                          <LockOutlined className="site-form-item-icon" />
                        }
                        type="password"
                        placeholder="Password"
                        id="password"
                        size="large"
                      />
                    </div>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      id="loginBtn"
                    >
                      Log In
                    </Button>

                    <div className="alreadyAccountDiv">
                      <span className="alreadyAccountHeading">
                        Dont have an account?
                      </span>
                      <a href="/signup" className="signUpLink">
                        Register
                      </a>
                      <div
                        className="forgot-password"
                        style={{ textAlign: "center" }}
                      >
                        <a href="/forgot_password" className="signUpLink">
                          {" "}
                          Forgot Password
                        </a>
                      </div>
                    </div>
                  </Form.Item>
                </Form>
                {userLoginReducer === true ? (
                  <Loader className="loaderLogin" />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(Login);
