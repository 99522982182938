import {
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_ERROR,
} from "../../actions/Organization/createTeam";

const createTeam = (state = null, action) => {
  switch (action.type) {
    case CREATE_TEAM_REQUEST: {
      return true;
    }
    case CREATE_TEAM_SUCCESS: {
      return action.response;
    }
    case CREATE_TEAM_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default createTeam;
