import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";

export const GET_PROFILE_VIEW_DETAILS_SUCCESS =
  "GET_PROFILE_VIEW_DETAILS_SUCCESS";
export const GET_PROFILE_VIEW_DETAILS_ERROR = "GET_PROFILE_VIEW_DETAILS_ERROR";
export const GET_PROFILE_VIEW_DETAILS = "GET_PROFILE_VIEW_DETAILS ";

export const getProfileViewDetails = (id) => (dispatch) => {
  dispatch({ type: GET_PROFILE_VIEW_DETAILS });
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${id}/user`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_PROFILE_VIEW_DETAILS_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_PROFILE_VIEW_DETAILS_ERROR,
        response: error.response,
      });
    });
};
