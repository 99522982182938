import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Form, Button, Select, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getOrganization } from "../../redux/actions/Organization/getOrganization";
import { getEmployees } from "../../redux/actions/User/getEmployees";
import { getManagers } from "../../redux/actions/User/getManagers";
import { sendInvite } from "../../redux/actions/InviteUser/inviteuser";
import Loader from "../../components/Loader";
import Schema from "async-validator";
import "./style.css";

const OrganizationDetail = (props) => {
  const { history } = props;
  Schema.warning = function () {};
  const dispatch = useDispatch();
  const id = localStorage.getItem("user_id");
  const getOrganizationStatus = useSelector((state) => state.getOrganization);
  const sendInviteStatus = useSelector((state) => state.sendInvite);
  const getEmployeesList = useSelector((state) => state.getEmployees);
  const getManagersList = useSelector((state) => state.getManager);
  const [organizationDetails, setOrganizationDetails] = useState();
  const [managersList, setManagersList] = useState();
  const [employeesList, setEmployeesList] = useState();

  const onFinish = (values) => {
    if (values) {
      let iniviteArr = [];
      if (values.invite && values.invite.length > 0) {
        for (let i = 0; i < values.invite.length; i++) {
          iniviteArr.push({ email: values.invite[i], role: "employee" });
        }
      }
      if (values.manager && values.manager.length > 0) {
        for (let i = 0; i < values.manager.length; i++) {
          iniviteArr.push({ email: values.manager[i], role: "manager" });
        }
      }

      if (iniviteArr.length > 0) {
        for (let i = 0; i < iniviteArr.length; i++) {
          dispatch(sendInvite(iniviteArr[i]));
        }
      }
    }
  };
  useEffect(() => {
    if (sendInviteStatus && sendInviteStatus.successStatus === 200) {
      message.success({
        content: `Invite send successfully to ${sendInviteStatus.successEmail}`,
        duration: 2,
      });
      history.push("/listing");
    } else if (sendInviteStatus && sendInviteStatus.errorStatus === 422) {
      message.error({
        content: `Invite not send this ${sendInviteStatus.failedEmail} email in invalid`,
        duration: 5,
      });
    }
  }, [sendInviteStatus, history]);

  useEffect(() => {
    dispatch(getOrganization());
    dispatch(getEmployees(id));
    dispatch(getManagers(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (getOrganizationStatus && getOrganizationStatus.status === 200) {
      setOrganizationDetails(getOrganizationStatus.data[0]);
    }
  }, [getOrganizationStatus]);

  useEffect(() => {
    if (getEmployeesList && getEmployeesList.status === 200) {
      setEmployeesList(getEmployeesList.data);
    }
  }, [getEmployeesList]);

  useEffect(() => {
    if (getManagersList && getManagersList.status === 200) {
      setManagersList(getManagersList.data);
    }
  }, [getManagersList]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 20,
        offset: 8,
      },
    },
  };

  return (
    <>
      <h1 className="organization-detail-heading-styling">
        Organization Details
      </h1>
      <div className="organization-detail-main">
        <Form
          {...formItemLayout}
          className="form-align"
          hideRequiredMark
          onFinish={onFinish}
        >
          <Form.Item label="Organization name">
            <span>
              {organizationDetails && organizationDetails.organization_name
                ? organizationDetails.organization_name
                : ""}
            </span>
          </Form.Item>
          <Form.Item label="Description">
            <span>
              {organizationDetails && organizationDetails.description
                ? organizationDetails.description
                : ""}
            </span>
          </Form.Item>
          <Form.Item label="Managers">
            {managersList ? (
              <ol style={{ paddingLeft: 15 }}>
                {managersList.map((manager, uniId) => (
                  <li key={uniId}>
                    {manager.first_name + " " + manager.last_name}
                  </li>
                ))}
              </ol>
            ) : (
              <span>Manager name</span>
            )}
          </Form.Item>

          <Form.Item label="Employees">
            {employeesList ? (
              <ol style={{ paddingLeft: 15 }}>
                {employeesList.map((employee, uniId) => (
                  <li key={uniId}>
                    {employee.first_name + " " + employee.last_name}
                  </li>
                ))}
              </ol>
            ) : (
              <span>Employee name</span>
            )}
          </Form.Item>
          <Form.Item label="Invite Manager" name="manager">
            <Select
              id="organizationManager"
              mode="tags"
              style={{ width: "100%" }}
              placeholder=""
            ></Select>
          </Form.Item>
          <Form.Item label="Invite Employee" name="invite">
            <Select
              id="inviteEmployee"
              mode="tags"
              style={{ width: "100%" }}
              placeholder=""
            ></Select>
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button
              id="updateBtn"
              type="primary"
              htmlType="submit"
              className="updateBtnStyle"
            >
              Update
            </Button>
          </Form.Item>
        </Form>
        {getOrganizationStatus === true ? <Loader /> : ""}
      </div>
    </>
  );
};

export default withRouter(OrganizationDetail);
