export function convertUNIXString(UNIX_timestamp, mode) {
  if (UNIX_timestamp) {
    var convertedString = new Date(UNIX_timestamp * 1000);
    var months;
    var time;
    if (mode === 0) {
      months = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
    } else {
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    }

    var year = convertedString.getFullYear();
    var month = months[convertedString.getMonth()];
    var date = convertedString.getDate();

    if (mode === 0) {
      time = date + "/" + month + "/" + year;
      return time;
    } else {
      time = date + " " + month + " " + year;
      return time;
    }
  } else {
    return "";
  }
}
