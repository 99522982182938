import {
  GET_CHEKIN_SUCCESS,
  GET_CHEKIN_REQUEST,
  GET_CHEKIN_ERROR,
} from "../../actions/Checkin/getCheckin";

const getCheckin = (state = null, action) => {
  switch (action.type) {
    case GET_CHEKIN_REQUEST: {
      return true;
    }
    case GET_CHEKIN_SUCCESS: {
      return action.response;
    }
    case GET_CHEKIN_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getCheckin;
