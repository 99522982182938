import {
    TOKEN_AUTH_FAILED,
    TOKEN_AUTH_SUCCESSFUL,
    TOKEN_AUTH_IN_PROGRESS,
  } from '../../actions/Auth/handleUnauthRoutes';
  
  const authToken = (state = false, action) => {
    switch (action.type) {
      case TOKEN_AUTH_IN_PROGRESS:
        return false;
      case TOKEN_AUTH_FAILED:
        return 'not loggedin';
      case TOKEN_AUTH_SUCCESSFUL:
        return true;
      default:
        return state;
    }
  };

  export default authToken;