import { Button, Typography, Input, Form, message, Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import "./style.css";
import React, { useState } from "react";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import registerLeft from "../../assets/register.png";
const ForgotPassword = (props) => {
  const { history } = props;
  const [stage, setStage] = useState(1);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const Pool = {
    UserPoolId: `${process.env.REACT_APP_AWS_USER_POOL_ID}`,
    ClientId: `${process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID}`,
  };
  const UserPool = new CognitoUserPool(Pool);

  const getUser = () => {
    return new CognitoUser({
      Username: email.toLowerCase(),
      Pool: UserPool,
    });
  };

  const sendCode = (event) => {
    message.success("Verfication code has been sent to your email!");
    getUser().forgotPassword({
      onSuccess: (data) => {},
      onFailure: (err) => {
        message.error(err.message);
        console.error("onFailure:", err);
      },
      inputVerificationCode: (data) => {
        setStage(2);
      },
    });
  };

  const resetPassword = (event) => {
    if (password !== confirmPassword) {
      console.error("Passwords are not the same");
      return;
    }

    getUser().confirmPassword(code, password, {
      onSuccess: (data) => {
        message.success("Your password has beeimportantn successfully reset!");
        history.push("/login");
      },
      onFailure: (err) => {
        message.error(err.message);
        console.error("onFailure:", err);
      },
    });
  };

  return (
    <>
      <Row>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <div className="register-left">
            <div className="register-left-content">
              <img src={registerLeft} alt="" />
              <h1 className="registration-heading-styling">
                Welcome to High-Knot
              </h1>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <div className="forgotPasswordMain">
            <div className="forgotPassword">
              <Typography className="forgotPasswordTitle">
                Forgot Your Password?
              </Typography>
              <Typography className="forgotPasswordDescription">
                Please enter the email address you'd like your password reset
                information sent to{" "}
              </Typography>

              <div className="forgotPasswordInput">
                {stage === 1 && (
                  <>
                    <Form onFinish={sendCode} name="sendCode">
                      <div style={{ marginTop: "20px" }}>
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter email",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter Email"
                            size="large"
                            type="email"
                            className="sendEmailInputStyle"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                          />
                        </Form.Item>
                      </div>
                      <Button
                        htmlType="submit"
                        className="sendEmailBtn"
                        type="primary"
                      >
                        Send Email
                      </Button>
                    </Form>
                  </>
                )}

                {stage === 2 && (
                  <>
                    <Form onFinish={resetPassword} name="resetPassword">
                      <div style={{ marginTop: "20px" }}>
                        <Form.Item
                          name="code"
                          rules={[
                            {
                              required: true,
                              message: "Please enter code",
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            value={code}
                            placeholder="Verification code"
                            onChange={(event) => setCode(event.target.value)}
                          />
                        </Form.Item>

                        <Form.Item
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please input your password!",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input.Password
                            value={password}
                            placeholder="New password"
                            onChange={(event) =>
                              setPassword(event.target.value)
                            }
                          />
                        </Form.Item>

                        <Form.Item
                          name="confirm"
                          dependencies={["password"]}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password!",
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  "The two passwords that you entered do not match!"
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            value={confirmPassword}
                            placeholder="Confirm password"
                            onChange={(event) =>
                              setConfirmPassword(event.target.value)
                            }
                          />
                        </Form.Item>
                      </div>
                      <Button
                        htmlType="submit"
                        className="submitButton"
                        type="primary"
                      >
                        Reset Password
                      </Button>
                    </Form>
                  </>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(ForgotPassword);
