import axios from "axios";

export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_ERROR = "USER_LOGOUT_ERROR";
export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST ";

export const logoutUser = (user) => (dispatch) => {
  dispatch({ type: USER_LOGOUT_REQUEST });
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/users/signout`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return dispatch({ type: USER_LOGOUT_SUCCESS, response: response });
    })
    .catch((error) => {
      return dispatch({ type: USER_LOGOUT_ERROR, response: error.response });
    });
};
