import {
  USER_REGISTRATION_SUCCESS,
  USER_REGISTRATION_REQUEST,
  USER_REGISTRATION_ERROR,
} from "../../actions/User/registration";

const userRegistration = (state = null, action) => {
  switch (action.type) {
    case USER_REGISTRATION_REQUEST: {
      return true;
    }
    case USER_REGISTRATION_SUCCESS: {
      return action.response;
    }
    case USER_REGISTRATION_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default userRegistration;
