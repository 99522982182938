import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import moment from "moment";
export const CREATE_OBJECTIVE_SUCCESS = "CREATE_OBJECTIVE_SUCCESS";
export const CREATE_OBJECTIVE_ERROR = "CREATE_OBJECTIVE_ERROR";
export const CREATE_OBJECTIVE_REQUEST = "CREATE_OBJECTIVE_REQUEST";

export const createObjective = (objective, goalCycle) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");
  let start_date = moment(objective.goalCycle[0]).unix();
  let end_date = moment(objective.goalCycle[1]).unix();

  dispatch({ type: CREATE_OBJECTIVE_REQUEST });
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/objectives`,
    data: {
      title: objective.aspirationalObjective,
      goalType: objective.goalType,
      timePeriod: goalCycle,
      startDate: start_date,
      endDate: end_date,
      completionPercentage: 75,
      objectiveResult: 0,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      dispatch({
        type: CREATE_OBJECTIVE_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: CREATE_OBJECTIVE_ERROR,
        response: error.response,
      });
    });
};
