import {
  CREATE_HIGH_FIVE_COMMENT_SUCCESS,
  CREATE_HIGH_FIVE_COMMENT_REQUEST,
  CREATE_HIGH_FIVE_COMMENT_ERROR,
} from "../../actions/HighFive/createHighFiveComment";

const createHighFiveComment = (state = null, action) => {
  switch (action.type) {
    case CREATE_HIGH_FIVE_COMMENT_REQUEST: {
      return true;
    }
    case CREATE_HIGH_FIVE_COMMENT_SUCCESS: {
      return action.response;
    }
    case CREATE_HIGH_FIVE_COMMENT_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default createHighFiveComment;
