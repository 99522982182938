import React from "react";
import OkrListings from "../OkrListings/OkrListings";
import "./style.css";

const Targets = () => {
  return (
    <div className="">
      <OkrListings />
    </div>
  );
};

export default Targets;
