import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button, Form } from "antd";
import { withRouter, useParams } from "react-router-dom";
import "./style.css";
import { viewPerformanceReview } from "../../redux/actions/PerformanceReview/viewPerformanceReview";
import { useDispatch, useSelector } from "react-redux";
import { getPerformanceRating } from "../../redux/actions/PerformanceRating/getPerformanceRating";
import { createComment } from "../../redux/actions/Comment/createComment";
import { viewComment } from "../../redux/actions/Comment/viewComment";

const ViewPerformanceReview = (props) => {
  const { history } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const getPerformanceRatingStatus = useSelector(
    (state) => state.getPerformanceRating
  );
  const createCommentStatus = useSelector((state) => state.createComment);
  const viewCommentStatus = useSelector((state) => state.viewComment);
  const viewPerformanceReviewStatus = useSelector(
    (state) => state.viewPerformanceReview
  );
  const [knowledge, setKnowledge] = useState(["", ""]);
  const [work, setWork] = useState(["", ""]);
  const [punctuality, setPunctuality] = useState(["", ""]);
  const [productivity, setProductivity] = useState(["", ""]);
  const [comuunication, setComuunication] = useState(["", ""]);
  const [listening, setListening] = useState(["", ""]);
  const [attendance, setAttendance] = useState(["", ""]);
  const [dependability, setDependability] = useState(["", ""]);
  const [coment, setComent] = useState([""]);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (createCommentStatus && createCommentStatus.status === 201) {
      history.push(`/performancereviewdashboard`);
    }
  }, [createCommentStatus]);

  useEffect(() => {
    if (viewCommentStatus && viewCommentStatus.status === 200) {
      setComent(viewCommentStatus.data);
    }
  }, [viewCommentStatus]);

  useEffect(() => {
    if (viewCommentStatus && viewCommentStatus.status === 500) {
      setComent("");
    }
  }, [viewCommentStatus]);

  const mapRatingToText = (rating) => {
    switch (rating) {
      case 1:
        return "1- Unacceptable";
      case 2:
        return "2- Needs Improvement";
      case 3:
        return "3- Meets Expectations";
      case 4:
        return "4- Exceeds Expectations";
      case 5:
        return "5- Outstanding";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (
      getPerformanceRatingStatus &&
      getPerformanceRatingStatus.status === 200
    ) {
      dispatch(viewComment(value && value[0].employee_id));
      const data = getPerformanceRatingStatus.data;
      data &&
        data.forEach((value) => {
          switch (value.rating_name) {
            case "job_knowledge":
              setKnowledge([value.comment, mapRatingToText(value.rating)]);
              break;
            case "work_quality":
              setWork([value.comment, mapRatingToText(value.rating)]);
              break;
            case "punctuality":
              setPunctuality([value.comment, mapRatingToText(value.rating)]);
              break;
            case "productivity":
              setProductivity([value.comment, mapRatingToText(value.rating)]);
              break;
            case "communication":
              setComuunication([value.comment, mapRatingToText(value.rating)]);
              break;
            case "listening_skills":
              setListening([value.comment, mapRatingToText(value.rating)]);
              break;
            case "attendance":
              setAttendance([value.comment, mapRatingToText(value.rating)]);
              break;
            case "dependability":
              setDependability([value.comment, mapRatingToText(value.rating)]);
              break;
            default:
              break;
          }
        });
    }
  }, [getPerformanceRatingStatus]);

  useEffect(() => {
    dispatch(viewPerformanceReview(id));
    dispatch(getPerformanceRating(id));
  }, [dispatch, id]);
  useEffect(() => {
    if (
      viewPerformanceReviewStatus &&
      viewPerformanceReviewStatus.status === 200
    ) {
      setValue(viewPerformanceReviewStatus.data);
    }
  }, [viewPerformanceReviewStatus]);
  const onFinish = (values) => {
    dispatch(createComment(values));
  };
  return (
    <>
      <div className="evaluated-user-info">
        <Row gutter={16}>
          <Col xs={12} sm={12} md={12} lg={16} xl={16}>
            <p className="evaluated-user-name">
              {" "}
              {value && value[0].user_name}'s Performance Review
            </p>
          </Col>
          <Col xs={12} sm={12} md={12} lg={8} xl={8}>
            {value && value[0].avg_rating === 1 && (
              <p className="user-review-emoji">😢</p>
            )}
            {value && value[0].avg_rating === 2 && (
              <p className="user-review-emoji">☹️</p>
            )}
            {value && value[0].avg_rating === 3 && (
              <p className="user-review-emoji">😐</p>
            )}
            {value && value[0].avg_rating === 4 && (
              <p className="user-review-emoji">😎</p>
            )}
            {value && value[0].avg_rating === 5 && (
              <p className="user-review-emoji">🤩</p>
            )}
          </Col>
        </Row>
      </div>

      <div className="view-performance-review">
        <Form onFinish={onFinish}>
          <Row className="ratingRow">
            <Col span={6}>
              <div className="ratingheading">
                <p>Job Knowledge: </p>
              </div>
            </Col>
            <Col span={18}>{knowledge[1]}</Col>
          </Row>
          <Row className="commentRow">
            <Col span={6}>
              <div className="commentheading">
                <p>Comment: </p>
              </div>
            </Col>
            <Col span={18}>
              <p>{knowledge[0]}</p>
            </Col>
          </Row>

          <Row className="ratingRow">
            <Col span={6}>
              <div className="ratingheading">
                <p>Work Quality: </p>
              </div>
            </Col>
            <Col span={18}>{work[1]}</Col>
          </Row>
          <Row className="commentRow">
            <Col span={6}>
              <div className="commentheading">
                <p>Comment: </p>
              </div>
            </Col>
            <Col span={18}>
              <p>{work[0]}</p>
            </Col>
          </Row>

          <Row className="ratingRow">
            <Col span={6}>
              <div className="ratingheading">
                <p>Punctuality: </p>
              </div>
            </Col>
            <Col span={18}>{punctuality[1]}</Col>
          </Row>
          <Row className="commentRow">
            <Col span={6}>
              <div className="commentheading">
                <p>Comment: </p>
              </div>
            </Col>
            <Col span={18}>
              <p>{punctuality[0]}</p>
            </Col>
          </Row>

          <Row className="ratingRow">
            <Col span={6}>
              <div className="ratingheading">
                <p>Attendance: </p>
              </div>
            </Col>
            <Col span={18}>{attendance[1]}</Col>
          </Row>
          <Row className="commentRow">
            <Col span={6}>
              <div className="commentheading">
                <p>Comment: </p>
              </div>
            </Col>
            <Col span={18}>
              <p>{attendance[0]}</p>
            </Col>
          </Row>

          <Row className="ratingRow">
            <Col span={6}>
              <div className="ratingheading">
                <p>Productivity: </p>
              </div>
            </Col>
            <Col span={18}>{productivity[1]}</Col>
          </Row>
          <Row className="commentRow">
            <Col span={6}>
              <div className="commentheading">
                <p>Comment: </p>
              </div>
            </Col>
            <Col span={18}>
              <p>{productivity[0]}</p>
            </Col>
          </Row>

          <Row className="ratingRow">
            <Col span={6}>
              <div className="ratingheading">
                <p>Communication: </p>
              </div>
            </Col>
            <Col span={18}>{comuunication[1]}</Col>
          </Row>
          <Row className="commentRow">
            <Col span={6}>
              <div className="commentheading">
                <p>Comment: </p>
              </div>
            </Col>
            <Col span={18}>
              <p>{comuunication[0]}</p>
            </Col>
          </Row>

          <Row className="ratingRow">
            <Col span={6}>
              <div className="ratingheading">
                <p>Listening skills: </p>
              </div>
            </Col>
            <Col span={18}>{listening[1]}</Col>
          </Row>
          <Row className="commentRow">
            <Col span={6}>
              <div className="commentheading">
                <p>Comment: </p>
              </div>
            </Col>
            <Col span={18}>
              <p>{listening[0]}</p>
            </Col>
          </Row>

          <Row className="ratingRow">
            <Col span={6}>
              <div className="ratingheading">
                <p>Dependability: </p>
              </div>
            </Col>
            <Col span={18}>{dependability[1]}</Col>
          </Row>
          <Row className="commentRow">
            <Col span={6}>
              <div className="commentheading">
                <p>Comment: </p>
              </div>
            </Col>
            <Col span={18}>
              <p>{dependability[0]}</p>
            </Col>
          </Row>
          {localStorage.getItem("currentUserRole") !== "owner" &&
          coment.length === 0 ? (
            <Row className="employeeCommentRow">
              <Col span={6}>
                <div className="employee-comment-heading">
                  <p>My Comment </p>
                </div>
              </Col>
              <Col span={18}>
                <Form.Item
                  className=""
                  label=""
                  name="employeeComment"
                  rules={[
                    {
                      required: true,
                      message: "Please input your comment!",
                    },
                  ]}
                >
                  <Input placeholder="Write Comment...." className="" />
                </Form.Item>
              </Col>
              <div className="reviewSubmitBtn">
                <Button className="commentSubmitBtn" htmlType="submit">
                  Submit
                </Button>
              </div>
            </Row>
          ) : (
            ""
          )}
          {localStorage.getItem("currentUserRole") === "owner" &&
            coment.length !== 0 && (
              <Row className="employeeCommentRow">
                <Col span={6}>
                  <div className="employee-comment-heading">
                    <p>Employee Comment </p>
                  </div>
                </Col>
                <Col span={18}>
                  <p>{coment[0].comment}</p>
                </Col>
              </Row>
            )}
          {localStorage.getItem("currentUserRole") === "owner" &&
            coment.length === 0 && (
              <Row className="employeeCommentRow">
                <Col span={6}>
                  <div className="employee-comment-heading">
                    <p>Employee Comment </p>
                  </div>
                </Col>
                <Col span={18}>
                  <p></p>
                </Col>
              </Row>
            )}
          {localStorage.getItem("currentUserRole") !== "owner" &&
            coment.length !== 0 && (
              <Row className="employeeCommentRow">
                <Col span={6}>
                  <div className="employee-comment-heading">
                    <p>My Comment </p>
                  </div>
                </Col>
                <Col span={18}>
                  <p>{coment[0].comment}</p>
                </Col>
              </Row>
            )}
        </Form>
      </div>
    </>
  );
};
export default withRouter(ViewPerformanceReview);
