import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { MoreOutlined, EyeOutlined } from "@ant-design/icons";
import userDummy from "../../assets/user.png";
import { useDispatch, useSelector } from "react-redux";
import { getWeeklyGoals } from "../../redux/actions/Organization/weeklyGoalsList";
import { getUsers } from "../../redux/actions/User/getUsers";
import { getCheckin } from "../../redux/actions/Checkin/getCheckin";
import { convertUNIXString } from "../../utils/convertUnixTimeStamp";
import "./style.css";

const CheckInModule = () => {
  const dispatch = useDispatch();
  const [UsersList, setUsersList] = useState([]);
  const getUsersList = useSelector((state) => state.getUsers);
  const [checkInData, setCheckInData] = useState([]);
  const goals = useSelector((state) => state.weeklyGoalList);
  const getCheckinStatus = useSelector((state) => state.getCheckin);
  const [matchingWeeklyGoals, setMatchingWeeklyGoals] = useState([]);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getWeeklyGoals());
  }, [dispatch]);

  useEffect(() => {
    if (goals && goals.data) {
      goals.data.forEach((weeklyGoal) => {
        dispatch(getCheckin(weeklyGoal.id));
      });
    }
  }, [dispatch, goals]);

  useEffect(() => {
    if (getCheckinStatus && getCheckinStatus.status === 200) {
      setCheckInData((prevCheckInData) => [
        ...prevCheckInData,
        ...getCheckinStatus.data,
      ]);
    }
  }, [getCheckinStatus]);

  useEffect(() => {
    if (goals && goals.data) {
      const matchingGoals = goals.data.filter((weeklyGoal) =>
        checkInData.some((checkin) => checkin.WId === weeklyGoal.id)
      );
      setMatchingWeeklyGoals(matchingGoals);
    }
  }, [goals, checkInData]);

  useEffect(() => {
    if (getUsersList && getUsersList.status === 200) {
      setUsersList(getUsersList.data);
    }
  }, [getUsersList]);

  const mapUserIdToUserData = (userId) => {
    if (!UsersList || UsersList.length === 0) {
      return { name: "Loading...", imageUrl: userDummy };
    }

    const user = UsersList.find((user) => user.id === userId);

    if (user) {
      return {
        name: `${user.first_name} ${user.last_name}`,
        imageUrl: user.image || userDummy,
      };
    } else {
      return { name: "Unknown User", imageUrl: userDummy };
    }
  };

  return (
    <div className="weekly-goals-module-style">
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <p className="weekly-goals-heading">Check Ins</p>
        </Col>
        <Col className="gutter-row" span={12}>
          <div className="dots-div">
            <MoreOutlined className="menu-icon-style" />
          </div>
        </Col>
      </Row>
      <div className="details-div">
        {matchingWeeklyGoals && matchingWeeklyGoals.length > 0 ? (
          matchingWeeklyGoals.map((data, index) => (
            <div className="goal-details-div" key={index}>
              <Link to={`/view_checkin/${data.id}`}>
                <Row gutter={16}>
                  <Col
                    className="gutter-row"
                    xs={3}
                    sm={3}
                    md={4}
                    lg={5}
                    xl={3}
                  >
                    <img
                      src={mapUserIdToUserData(data.userId).imageUrl}
                      alt="User"
                      className="user-dummy-image-style"
                    />
                  </Col>
                  <Col
                    className="gutter-row"
                    xs={18}
                    sm={18}
                    md={17}
                    lg={15}
                    xl={18}
                  >
                    <p className="weekly-goals-duration">
                      {convertUNIXString(data.startDate) +
                        " - " +
                        convertUNIXString(data.endDate)}
                    </p>
                    <p className="weekly-goal-assignee">
                      by: {mapUserIdToUserData(data.userId).name}
                    </p>
                  </Col>
                  <Col
                    className="gutter-row"
                    xs={3}
                    sm={3}
                    md={3}
                    lg={4}
                    xl={3}
                  >
                    <div className="weeklygoal-action-button">
                      <p className="view-text">view</p>
                      <div className="view-icon-div">
                        <EyeOutlined className="eye-icon-style" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Link>
            </div>
          ))
        ) : (
          <div style={{ marginTop: "100px" }}>
            <span>No Check Ins available</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckInModule;
