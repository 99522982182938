import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";

export const CREATE_PERFORMANCE_RATING_SUCCESS =
  "CREATE_PERFORMANCE_RATING_SUCCESS";
export const CREATE_PERFORMANCE_RATING_ERROR =
  "CREATE_PERFORMANCE_RATING_ERROR";
export const CREATE_PERFORMANCE_RATING_REQUEST =
  "CREATE_PERFORMANCE_RATING_REQUEST";

const createPerformanceRating = (name, comment, rating) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");
  const current_url = window.location.href;
  var url = new URL(current_url);
  var path = url.pathname;
  var time_off_detail_id = path.substring(path.lastIndexOf("/") + 1);

  dispatch({ type: CREATE_PERFORMANCE_RATING_REQUEST });
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/performance_review/${time_off_detail_id}/review_rating`,
    data: {
      rating_name: name,
      comment: comment,
      rating: rating,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      dispatch({
        type: CREATE_PERFORMANCE_RATING_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(tokenAuthFailedAction());
      }
      dispatch({
        type: CREATE_PERFORMANCE_RATING_ERROR,
        response: error.response,
      });
    });
};
export default createPerformanceRating;
