import {
  GOAL_UPDATE_SUCCESS,
  GOAL_UPDATE_REQUEST,
  GOAL_UPDATE_ERROR,
} from "../../actions/Organization/updateGoal";

const updateGoal = (state = null, action) => {
  switch (action.type) {
    case GOAL_UPDATE_REQUEST: {
      return true;
    }
    case GOAL_UPDATE_SUCCESS: {
      return action.response;
    }
    case GOAL_UPDATE_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default updateGoal;
