import {
    GET_OBJECTIVE_SUCCESS,
    GET_OBJECTIVE_REQUEST,
    GET_OBJECTIVE_ERROR,
  } from "../../actions/Okr/getObjective";
  
  const getObjective = (state = null, action) => {
    switch (action.type) {
      case GET_OBJECTIVE_REQUEST: {
        return true;
      }
      case GET_OBJECTIVE_SUCCESS: {
        return action.response;
      }
      case GET_OBJECTIVE_ERROR: {
        return action.response;
      }
      default:
        return state;
    }
  };
  
  export default getObjective;
  