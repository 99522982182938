import {
  GET_HIGH_FIVE_LIKES_SUCCESS,
  GET_HIGH_FIVE_LIKES_ERROR,
  GET_HIGH_FIVE_LIKES_REQUEST,
} from "../../actions/HighFive/getHighFiveLikes";

const getHighFiveLikes = (state = null, action) => {
  switch (action.type) {
    case GET_HIGH_FIVE_LIKES_REQUEST: {
      return true;
    }
    case GET_HIGH_FIVE_LIKES_SUCCESS: {
      return action.response;
    }
    case GET_HIGH_FIVE_LIKES_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getHighFiveLikes;
