import {
  GET_MANAGERS_SUCCESS,
  GET_MANAGERS_REQUEST,
  GET_MANAGERS_ERROR,
} from "../../actions/User/getManagers";

const getManagers = (state = null, action) => {
  switch (action.type) {
    case GET_MANAGERS_REQUEST: {
      return true;
    }
    case GET_MANAGERS_SUCCESS: {
      return action.response;
    }
    case GET_MANAGERS_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getManagers;
