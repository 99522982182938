import {
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_ERROR,
} from "../../actions/Otp/verifyOtp";

const verifyOtp = (state = null, action) => {
  switch (action.type) {
    case VERIFY_OTP_REQUEST: {
      return true;
    }
    case VERIFY_OTP_SUCCESS: {
      return action.response;
    }
    case VERIFY_OTP_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default verifyOtp;
