import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";

export const GET_TIME_OFF_DETAIL_SUCCESS = "GET_TIME_OFF_DETAIL_SUCCESS";
export const GET_TIME_OFF_DETAIL_ERROR = "GET_TIME_OFF_DETAIL_ERROR";
export const GET_TIME_OFF_DETAIL = "GET_TIME_OFF_DETAIL ";

export const getTimeoffDetail = () => (dispatch) => {
  const user_id = localStorage.getItem("user_id");
  const current_url = window.location.href;
  var url = new URL(current_url);
  var path = url.pathname;
  var time_off_detail_id = path.substring(path.lastIndexOf("/") + 1);

  dispatch({ type: GET_TIME_OFF_DETAIL });
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/time_offs/${time_off_detail_id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_TIME_OFF_DETAIL_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_TIME_OFF_DETAIL_ERROR,
        response: error.response,
      });
    });
};
