import axios from "axios";

export const USER_REGISTRATION_SUCCESS = "USER_REGISTRATION_SUCCESS";
export const USER_REGISTRATION_ERROR = "USER_REGISTRATION_ERROR";
export const USER_REGISTRATION_REQUEST = "USER_REGISTRATION_REQUEST ";

export const registerUser = (user) => (dispatch) => {
  let data = new FormData();
  data.append("user[password]", user.password);
  data.append("user[email]", user.email);
  data.append("user[first_name]", user.firstName);
  data.append("user[last_name]", user.lastName);

  dispatch({ type: USER_REGISTRATION_REQUEST });
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/users/sign_up`,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return dispatch({ type: USER_REGISTRATION_SUCCESS, response: response.status });
    })
    .catch((error) => {
      return dispatch({ type: USER_REGISTRATION_ERROR, response: error.response });
    });
};
