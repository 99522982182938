import {
  UPDATE_KEY_RESULT_SUCCESS,
  UPDATE_KEY_RESULT_REQUEST,
  UPDATE_KEY_RESULT_ERROR,
} from "../../actions/Okr/updateKeyResult";

const updateKeyResult = (state = null, action) => {
  switch (action.type) {
    case UPDATE_KEY_RESULT_REQUEST: {
      return true;
    }
    case UPDATE_KEY_RESULT_SUCCESS: {
      return action.response;
    }
    case UPDATE_KEY_RESULT_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default updateKeyResult;
