import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
export const DELETE_TEAM_MEMBER_SUCCESS = "DELETE_TEAM_MEMBER_SUCCESS";
export const DELETE_TEAM_MEMBER_ERROR = "DELETE_TEAM_MEMBER_ERROR";
export const DELETE_TEAM_MEMBER_REQUEST = "DELETE_TEAM_MEMBER_REQUEST";

export const deleteTeamMember = (teamId, teamMemberId) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");

  dispatch({ type: DELETE_TEAM_MEMBER_REQUEST });

  return axios({
    method: "delete",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/teams/${teamId}/team_members/${teamMemberId}`,

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      dispatch({
        type: DELETE_TEAM_MEMBER_SUCCESS,
        response: response.status,
      });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(tokenAuthFailedAction());
      }
      dispatch({
        type: DELETE_TEAM_MEMBER_ERROR,
        response: error.response.status,
      });
    });
};
