import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { MoreOutlined } from "@ant-design/icons";
import rejected from "../../assets/rejected.png";
import userDummy from "../../assets/user.png";
import approved from "../../assets/approved.png";
import processing from "../../assets/processing.png";
import { convertUNIXString } from "../../utils/convertUnixTimeStamp";
import { useDispatch, useSelector } from "react-redux";
import { getTimeOff } from "../../redux/actions/TimeOff/getTimeOff";
import { getUsers } from "../../redux/actions/User/getUsers";
import "./style.css";

const TimeOffsModule = () => {
  const [record, setRecord] = useState("");
  const dispatch = useDispatch();
  const getTimeOffStatus = useSelector((state) => state.getTimeOff);
  const [UsersList, setUsersList] = useState([]);
  const getUsersList = useSelector((state) => state.getUsers);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getTimeOff());
  }, [dispatch]);

  useEffect(() => {
    if (getUsersList && getUsersList.status === 200) {
      setUsersList(getUsersList.data);
    }
  }, [getUsersList]);

  useEffect(() => {
    if (getTimeOffStatus && getTimeOffStatus.status === 201) {
      setRecord(getTimeOffStatus.data);
    }
  }, [getTimeOffStatus]);

  const getUserImageByUserId = (userId) => {
    const user = UsersList.find((user) => user.id === userId);
    return user ? user.image || userDummy : userDummy;
  };

  const getStatusBar = (status) => {
    switch (status) {
      case "approved":
        return (
          <div className="bar-text">
            <p className="approved-text">Approved</p>
            <div className="approved-bar">
              <img src={approved} alt="" />
            </div>
          </div>
        );
      case "requested":
        return (
          <div className="bar-text">
            <p className="approved-text">Requested</p>
            <div className="approved-bar">
              <img src={processing} alt="" />
            </div>
          </div>
        );
      case "rejected":
        return (
          <div className="bar-text">
            <p className="approved-text">Rejected</p>
            <div className="approved-bar">
              <img src={rejected} alt="" />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="weekly-goals-module-style">
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <p className="weekly-goals-heading">Time off requests</p>
        </Col>
        <Col className="gutter-row" span={12}>
          <div className="dots-div">
            <MoreOutlined className="menu-icon-style" />
          </div>
        </Col>
      </Row>
      <div className="details-div">
        {record &&
          record.map((timeoff, uniId) => (
            <div className="timeoff-details-div" key={uniId}>
              <Link to={`/timeoffdetail/${timeoff.id}`}>
                <Row gutter={16}>
                  <Col
                    className="gutter-row"
                    xs={3}
                    sm={3}
                    md={4}
                    lg={5}
                    xl={3}
                  >
                    <img
                      src={getUserImageByUserId(timeoff.user_id)}
                      alt="Dashboard"
                      className="user-dummy-image-style"
                    />
                  </Col>
                  <Col
                    className="gutter-row"
                    xs={13}
                    sm={13}
                    md={20}
                    lg={19}
                    xl={13}
                  >
                    <p className="weekly-goals-duration">
                      {convertUNIXString(timeoff.start_date)} -{" "}
                      {convertUNIXString(timeoff.end_date)}
                    </p>
                    <p className="weekly-goal-assignee">
                      by: {timeoff.user_name}
                    </p>
                  </Col>
                  <Col
                    className="gutter-row"
                    xs={8}
                    sm={8}
                    md={24}
                    lg={24}
                    xl={8}
                  >
                    {getStatusBar(timeoff.status)}
                  </Col>
                </Row>
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TimeOffsModule;
