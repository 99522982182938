import {
  VIEW_ORGANIZATION_SUCCESS,
  VIEW_ORGANIZATION_REQUEST,
  VIEW_ORGANIZATION_ERROR,
} from "../../actions/Organization/getOrganization";

const getOrganization = (state = null, action) => {
  switch (action.type) {
    case VIEW_ORGANIZATION_REQUEST: {
      return true;
    }
    case VIEW_ORGANIZATION_SUCCESS: {
      return action.response;
    }
    case VIEW_ORGANIZATION_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getOrganization;
