import { Row, Col, Table, Dropdown, Menu, Icon, Button } from "antd";
import React, { useState, useEffect } from "react";
import { useParams, useHistory, withRouter } from "react-router-dom";
import { getObjective } from "../../redux/actions/Okr/getObjective";
import { getKeyResult } from "../../redux/actions/Okr/getKeyResult";
import { useDispatch, useSelector } from "react-redux";
import { getTeams } from "../../redux/actions/Team/getTeams";
import { getTeamObjective } from "../../redux/actions/Okr/getTeamObjective";
import {
  SortDescendingOutlined,
  FilterOutlined,
  DownCircleFilled,
} from "@ant-design/icons";
import "./style.css";

const SubMenu = Menu.SubMenu;
const OkrListings = (props) => {
  const isMobile = window.innerWidth <= 768;
  const history = useHistory();
  const dispatch = useDispatch();
  const getTeamsStatus = useSelector((state) => state.getTeams);
  const getTeamObjectiveStatus = useSelector((state) => state.getTeamObjective);
  const getObjectiveStatus = useSelector((state) => state.getObjective);
  const getKeyResultStatus = useSelector((state) => state.getKeyResult);
  const [data1, setData1] = useState();
  const [record, setRecord] = useState();
  const [keyResultData, setKeyResultData] = useState();
  const [teamList, setTeamList] = useState();
  const [department, setDepartment] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const { id } = useParams();
  localStorage.setItem("key_result_id", id);

  const onCompleteHandleChange = () => {
    const data =
      getObjectiveStatus &&
      getObjectiveStatus.data.filter(
        (objective) => objective.completionPercentage >= 80
      );
    setData1(data);
  };
  const onIncompleteHandleChange = () => {
    const data =
      getObjectiveStatus &&
      getObjectiveStatus.data.filter(
        (objective) => objective.completionPercentage < 80
      );
    setData1(data);
  };
  const onActiveHandleChange = () => {
    const data =
      getObjectiveStatus &&
      getObjectiveStatus.data.filter(
        (objective) => objective.status === "not expire"
      );
    setData1(data);
  };
  const onExpireHandleChange = () => {
    const data =
      getObjectiveStatus &&
      getObjectiveStatus.data.filter(
        (objective) => objective.status === "expire"
      );
    setData1(data);
  };
  const onMonthlyHandleChange = () => {
    const monthData =
      getObjectiveStatus &&
      getObjectiveStatus.data.filter(
        (objective) => objective.timePeriod === "month"
      );
    setData1(monthData);
  };
  const onQaurterlyHandleChange = () => {
    const quarterData =
      getObjectiveStatus &&
      getObjectiveStatus.data.filter(
        (objective) => objective.timePeriod === "quarter"
      );
    setData1(quarterData);
  };
  const onHalfYearlyHandleChange = () => {
    const data =
      getObjectiveStatus &&
      getObjectiveStatus.data.filter(
        (objective) => objective.timePeriod === "halfYear"
      );
    setData1(data);
  };
  const onYearlyHandleChange = () => {
    const data =
      getObjectiveStatus &&
      getObjectiveStatus.data.filter(
        (objective) => objective.timePeriod === "year"
      );
    setData1(data);
  };

  const onClickDropdown = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (getTeamObjectiveStatus && getTeamObjectiveStatus.status === 200) {
      setDepartment(getTeamObjectiveStatus.data);
    }
  }, [getTeamObjectiveStatus]);

  useEffect(() => {
    if (getTeamsStatus && getTeamsStatus.status === 200) {
      setTeamList(getTeamsStatus.data);
    }
  }, [getTeamsStatus]);

  useEffect(() => {
    dispatch(getTeams());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getObjective());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getKeyResult(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (getObjectiveStatus && getObjectiveStatus.status === 200) {
      setData1(getObjectiveStatus.data);
    }
  }, [getObjectiveStatus]);

  useEffect(() => {
    if (getKeyResultStatus && getKeyResultStatus.status === 200) {
      setKeyResultData(getKeyResultStatus.data);
    }
  }, [getKeyResultStatus]);

  const handleFetch = (expand, record1) => {
    const keys = [];
    if (expand) {
      keys.push(record1.key);
      dispatch(getKeyResult(record1.key));
      dispatch(getTeamObjective(record1.key));
    }
    setExpandedRowKeys(keys);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "OKR Weight",
      dataIndex: "goal_type",
    },
    {
      title: "Date",
      dataIndex: "time_period",
    },
    {
      title: "Objective Result",
      dataIndex: "objective_result",
    },
    {
      title: "Actions",
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                onClick={() => {
                  history.push(`/update_okr/${record.key}`);
                }}
              >
                Edit OKR
              </Menu.Item>
              <Menu.Item key="2">Initiate OKR</Menu.Item>
              <Menu.Item key="3">Delete OKR</Menu.Item>
            </Menu>
          }
          placement="bottomRight"
        >
          <Button
            type="primary"
            icon={<DownCircleFilled />}
            size="large"
            className="action-btn"
          >
            {isMobile ? null : "Actions"}
          </Button>
        </Dropdown>
      ),
    },
  ];
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a
          href="#/"
          onClick={() => {
            onExpireHandleChange();
          }}
        >
          Expire
        </a>
      </Menu.Item>
      <Menu.Item key="1">
        <a
          href="#/"
          onClick={() => {
            onActiveHandleChange();
          }}
        >
          Active
        </a>
      </Menu.Item>
      <Menu.Item key="2">
        <a
          href="#/"
          onClick={() => {
            onCompleteHandleChange();
          }}
        >
          Complete
        </a>
      </Menu.Item>
      <Menu.Item key="3">
        <a
          href="#/"
          onClick={() => {
            onIncompleteHandleChange();
          }}
        >
          Incomplete
        </a>
      </Menu.Item>

      <SubMenu
        key="sub1"
        title={
          <span>
            <Icon type="mail" />
            <span>Time Period</span>
          </span>
        }
      >
        <Menu.Item key="4">
          <a
            href="#/"
            onClick={() => {
              onMonthlyHandleChange();
            }}
          >
            Monthly
          </a>
        </Menu.Item>
        <Menu.Item key="5">
          <a
            href="/"
            onClick={() => {
              onQaurterlyHandleChange();
            }}
          >
            Quarterly
          </a>
        </Menu.Item>
        <Menu.Item key="6">
          <a
            href="/"
            onClick={() => {
              onHalfYearlyHandleChange();
            }}
          >
            Half Yearly
          </a>
        </Menu.Item>
        <Menu.Item key="7">
          <a
            href="/"
            onClick={() => {
              onYearlyHandleChange();
            }}
          >
            Yearly
          </a>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
  useEffect(() => {
    const data = [];
    if (data1 !== undefined) {
      for (let i = 0; i < data1.length; i++) {
        data.push({
          key: data1[i].id,
          title: data1[i].title,
          goal_type: data1[i].goalType,
          time_period: data1[i].timePeriod,
          objective_result: data1[i].objectiveResult + "%",
        });
        // dispatch(getKeyResult(data1[i].id));
        // dispatch(getTeamObjective(data1[i].id));
      }
      setRecord(data);
    }
  }, [data1, dispatch]);

  const evenRowClassName = (record, index) => {
    if (index % 2 === 1) {
      return "even-row";
    }
    return "";
  };

  return (
    <>
      <div className="okrListings">
        <Row>
          <Col span={17}>
            <h1 className="okrListingsTitle">Manage Targets</h1>
          </Col>
          <Col span={2}>
            <div className="sortingIconHeading">
              <p className="sortingIcon">
                <SortDescendingOutlined />{" "}
              </p>
              <p className="sortingIconTitle">Sort</p>
            </div>
          </Col>
          <Col span={2}>
            <div className="filterIconHeading">
              <p className="filterIcon">
                <Dropdown overlay={menu} trigger={["click"]}>
                  <a
                    href="/"
                    className="ant-dropdown-link filterIconTitle"
                    onClick={onClickDropdown}
                  >
                    Filter <FilterOutlined />
                  </a>
                </Dropdown>
              </p>
            </div>
          </Col>
          <Col span={3}>
            <Button
              type="primary"
              icon={<DownCircleFilled />}
              size="large"
              className="export-btn"
            >
              Export
            </Button>
          </Col>
        </Row>

        {record !== undefined && (
          <Table
            onRow={(record) => {
              return {
                // onClick: () => {
                //   history.push(`/update_okr/${record.key}`);
                // },
              };
            }}
            columns={columns}
            expandedRowKeys={expandedRowKeys}
            expandable={{
              onExpand: handleFetch,
              rowExpandable: (record) => record.name !== "Not Expandable",
              expandedRowRender: (record) => (
                <table>
                  <tr>
                    <th>Key Results</th>
                    <th>Target</th>
                    <th>Target Achieved</th>
                  </tr>

                  {keyResultData.map((value) => (
                    <tr>
                      <td>{value.title}</td>
                      <td>{value.target}</td>
                      <td>{value.target_achieved}</td>
                    </tr>
                  ))}
                  <tr className="objectiveTeamsRow">
                    <th className="objectiveTeamsHeading">Objective Teams</th>
                    <td className="objectiveTeamsData">
                      {department &&
                        department.map((value) => {
                          const { team_id } = value || {};
                          const team =
                            (teamList &&
                              teamList.find(({ id }) => id === team_id)) ||
                            {};
                          return (
                            <p className="objectivesContent">{team.title}</p>
                          );
                        })}
                    </td>
                  </tr>
                </table>
              ),
            }}
            dataSource={record}
            rowClassName={evenRowClassName}
          />
        )}
      </div>
    </>
  );
};

export default withRouter(OkrListings);
