import {
    UPDATE_TEAM_SUCCESS,
    UPDATE_TEAM_REQUEST,
    UPDATE_TEAM_ERROR,
  } from "../../actions/Team/updateTeam";
  
  const updateTeam = (state = null, action) => {
    switch (action.type) {
      case UPDATE_TEAM_REQUEST: {
        return true;
      }
      case UPDATE_TEAM_SUCCESS: {
        return action.response;
      }
      case UPDATE_TEAM_ERROR: {
        return action.response;
      }
      default:
        return state;
    }
  };
  
  export default updateTeam;
  