import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";

export const UPDATE_ORGANIZATION_HIERARCHY_SUCCESS =
  "UPDATE_ORGANIZATION_HIERARCHY_SUCCESS";
export const UPDATE_ORGANIZATION_HIERARCHY_ERROR =
  "UPDATE_ORGANIZATION_HIERARCHY_ERROR";
export const UPDATE_ORGANIZATION_HIERARCHY_REQUEST =
  "UPDATE_ORGANIZATION_HIERARCHY_REQUEST ";

export const updateOrganizationHierarchy =
  (OrganizationHierarchy) => (dispatch) => {
    OrganizationHierarchy = JSON.stringify(OrganizationHierarchy);
    dispatch({ type: UPDATE_ORGANIZATION_HIERARCHY_REQUEST });
    const user_id = localStorage.getItem("user_id");
    const organization_id = localStorage.getItem("organization_id");

    return axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/organization_hierarchy/${organization_id}`,
      data: {
        hierarchy: OrganizationHierarchy,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "access-token": readLocalStorage("access-token"),
      },
    })
      .then((response) => {
        return dispatch({
          type: UPDATE_ORGANIZATION_HIERARCHY_SUCCESS,
          response: response,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(tokenAuthFailedAction());
        }
        return dispatch({
          type: UPDATE_ORGANIZATION_HIERARCHY_ERROR,
          response: error.response.status,
        });
      });
  };
