import axios from "axios";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
import { readLocalStorage } from "../../../utils/readLocalStorage";
export const WEEKLY_GOAL_DELETE_SUCCESS = "WEEKLY_GOAL_DELETE_SUCCESS";
export const WEEKLY_GOAL_DELETE_ERROR = "WEEKLY_GOAL_DELETE_ERROR";
export const WEEKLY_GOAL_DELETE_REQUEST = "WEEKLY_GOAL_DELETE_REQUEST ";
export const WEEKLY_GOAL_DELETE_RESET = "WEEKLY_GOAL_DELETE_RESET";

export const deleteWeeklyGoal = (id) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");
  dispatch({ type: WEEKLY_GOAL_DELETE_REQUEST });
  return axios({
    method: "delete",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/weekly_goals/${id}`,
    crossDomain: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      dispatch({ type: WEEKLY_GOAL_DELETE_SUCCESS, response: response });
      dispatch({ type: WEEKLY_GOAL_DELETE_RESET });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(tokenAuthFailedAction());
      }
      dispatch({
        type: WEEKLY_GOAL_DELETE_ERROR,
        response: error.response.status,
      });
      dispatch({ type: WEEKLY_GOAL_DELETE_RESET });
    });
};
