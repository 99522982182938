import React, { useState, useEffect } from "react";
import { Row, Col, Input, Form, Button, DatePicker, Select, Radio } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { createObjective } from "../../redux/actions/Okr/createObjective";
import { getTeams } from "../../redux/actions/Team/getTeams";
import { createTeamObjective } from "../../redux/actions/Okr/createTeamObjective";
import { createKeyResult } from "../../redux/actions/Okr/createKeyResult";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import "./style.css";

const TargetSetupComponent = (props) => {
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  const getTeamsStatus = useSelector((state) => state.getTeams);
  const createObjectiveStatus = useSelector((state) => state.createObjective);
  const createTeamObjectiveStatus = useSelector(
    (state) => state.createTeamObjective
  );
  const createKeyResultStatus = useSelector((state) => state.createKeyResult);
  const [goalCycle, setGoalCycle] = useState();
  const [teamList, setTeamList] = useState();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [objective, setObjective] = useState();

  useEffect(() => {
    if (createKeyResultStatus && createKeyResultStatus.status === 201) {
      setLoader(false);
    }
  }, [createKeyResultStatus, dispatch]);

  useEffect(() => {
    if (createObjectiveStatus && createObjectiveStatus.status === 422) {
      setLoader(false);
      alert("Your date has expired");
    }
  }, [createObjectiveStatus, dispatch]);

  useEffect(() => {
    if (createTeamObjectiveStatus && createTeamObjectiveStatus.status === 200) {
      createKeyResultData();
    }
  }, [createTeamObjectiveStatus, dispatch.apply]);

  const createKeyResultData = () => {
    if (data === undefined) {
      setLoader(false);
      return;
    }

    let objective_id = objective.id;
    dispatch(createKeyResult(objective.id, data.title, data.target, data.unit));

    if (data.keyResults && data.keyResults.length > 0) {
      data.keyResults.forEach((keyResult) => {
        const keyresult = keyResult.title;
        const target = keyResult.target;
        const unit = keyResult.unit;

        dispatch(createKeyResult(objective_id, keyresult, target, unit));
      });
    }
  };

  useEffect(() => {
    if (createObjectiveStatus && createObjectiveStatus.status === 201) {
      setObjective(createObjectiveStatus.data);
      const objective_id = createObjectiveStatus.data.id;
      const department = data.department;

      for (let i = 0; i < department.length; i++) {
        dispatch(createTeamObjective(objective_id, department[i]));
      }
    }
  }, [createObjectiveStatus, data, dispatch]);

  useEffect(() => {
    if (getTeamsStatus && getTeamsStatus.data) {
      setTeamList(getTeamsStatus.data);
      setLoader(false);
    }
  }, [getTeamsStatus]);

  useEffect(() => {
    dispatch(getTeams());
    setLoader(true);
  }, [dispatch]);

  const onFinish = (objective) => {
    setData(objective);
    dispatch(createObjective(objective, goalCycle));
    const defaultKeyResults = [
      {
        title: objective.title,
        target: objective.target,
        unit: objective.unit,
      },
    ];
    createKeyResultData(defaultKeyResults);
  };

  const onChangeRadio = (e) => {
    setGoalCycle(e.target.value);
  };

  const onFinishFailed = () => {
    console.log("Form submit failed:");
  };

  const { Option } = Select;

  function onChange(value) {
    localStorage.setItem("department", value);
  }

  return (
    <div className="target-setup-main-div">
      {loader === false ? (
        <Form
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{
            width: "100%",
          }}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
              <p className="okr-label-style">Title</p>
              <Form.Item
                label=""
                name="aspirationalObjective"
                rules={[
                  {
                    required: true,
                    message: "Please input your objective title!",
                  },
                ]}
              >
                <Input size="large" placeholder="" className="input-style" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
              <p className="okr-label-style">Okrs Weight</p>
              <Form.Item
                label=""
                name="goalType"
                rules={[
                  {
                    required: true,
                    message: "Please input your goal type!",
                  },
                ]}
              >
                <Input size="large" className="input-style" value="" />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
              <p className="okr-label-style">Teams</p>
              <Form.Item name="department" rules={[{ required: true }]}>
                <Select
                  mode="multiple"
                  size="large"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  className="select-input-style"
                  onChange={onChange}
                >
                  {teamList &&
                    teamList.map((value) => (
                      <Option value={value.id}>{value.title}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16}>
              <p className="okr-label-style">Goal Cycle</p>
              <Form.Item
                label=""
                name="goalCycle"
                rules={[
                  {
                    required: true,
                    message: "Please input your date!",
                  },
                ]}
              >
                <RangePicker
                  size="large"
                  style={{
                    background: "#e3edf9",
                    borderRadius: "5px",
                    border: "1px solid #e3edf9",
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8}>
              <p className="okr-label-style">Time Period</p>
              <Form.Item
                name="timePeriod"
                rules={[
                  { required: true, message: "Please select time period." },
                ]}
              >
                <div className="time-period-radio-btns">
                  <Radio.Group
                    onChange={onChangeRadio}
                    value={goalCycle}
                    buttonStyle="solid"
                    size="large"
                  >
                    <Radio.Button value="month">Month</Radio.Button>
                    <Radio.Button value="quarter">Quarter</Radio.Button>
                    <Radio.Button value="halfYear">Half Year</Radio.Button>
                    <Radio.Button value="year">Year</Radio.Button>
                  </Radio.Group>
                </div>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <p className="okr-label-style">Measurable Key Result</p>
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please input title.",
                  },
                ]}
              >
                <Input size="large" placeholder="" className="input-style" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <p className="okr-label-style">Target</p>
              <Form.Item
                name="target"
                rules={[
                  {
                    required: true,
                    message: "Please input target.",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        parseFloat(value) > 100 &&
                        getFieldValue("unit") === "%"
                      ) {
                        return Promise.reject(
                          "Percentage cannot be greater than 100"
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  size="large"
                  placeholder=""
                  className="input-style"
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
              <p className="okr-label-style">Unit</p>
              <Form.Item
                name="unit"
                rules={[
                  {
                    required: true,
                    message: "Please select unit.",
                  },
                ]}
              >
                <Radio.Group
                  onChange={onChange}
                  defaultValue="a"
                  optionType="button"
                  buttonStyle="solid"
                  size="large"
                >
                  <Radio.Button className="dollarRadioBtn" value="$">
                    $
                  </Radio.Button>
                  <Radio.Button className="percentageRadioBtn" value="%">
                    %
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Form.List name="keyResults">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <Row gutter={16} className="more-key-results">
                      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Form.Item
                          name={[name, "title"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input title.",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder=""
                            className="input-style"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <Form.Item
                          name={[name, "target"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input target.",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder=""
                            className="input-style"
                            type="number"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                        <Form.Item
                          name={[name, "unit"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select unit.",
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={onChange}
                            defaultValue="a"
                            optionType="button"
                            buttonStyle="solid"
                          >
                            <Radio.Button className="dollarRadioBtn" value="$">
                              $
                            </Radio.Button>
                            <Radio.Button
                              className="percentageRadioBtn"
                              value="%"
                            >
                              %
                            </Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    style={{ marginTop: "20px" }}
                    icon={<PlusOutlined />}
                  >
                    Add More Targets
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item>
            <Button htmlType="submit" className="submit-okr-button">
              Submit
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default TargetSetupComponent;
