import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import userDummy from "../../assets/user.png";
import { getUsers } from "../../redux/actions/User/getUsers";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import "./style.css";

const EmployeeProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [UsersList, setUsersList] = useState([]);
  const getUsersList = useSelector((state) => state.getUsers);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);
  useEffect(() => {
    if (getUsersList && getUsersList.status === 200) {
      setUsersList(getUsersList.data);
    }
  }, [getUsersList]);

  const selectedEmployee = UsersList.find(
    (employee) => employee.id === parseInt(id)
  );

  const onClickEmployee = (id) => {
    history.push(`/profile/${id}`);
  };
  // Filter out the selected employee from the list
  const filteredEmployeesList = UsersList.filter(
    (employee) => employee.id !== parseInt(id)
  );

  return (
    <>
      <h1 className="organization-detail-heading-styling">
        {" "}
        {selectedEmployee && selectedEmployee.first_name}{" "}
        {selectedEmployee && selectedEmployee.last_name}
        {"'s"} Profile
      </h1>
      <Row gutter={16}>
        <Col className="gutter-row" span={16}>
          <div className="employee-profile-main">
            <div className="employee-profile">
              <div className="user-profile-details">
                {selectedEmployee && (
                  <>
                    <div className="employee-image">
                      <img
                        src={selectedEmployee.image || userDummy}
                        alt="employee"
                        className="employee-image-style rectangular-image"
                      />
                    </div>
                    <p className="employee-name">
                      {selectedEmployee.first_name} {selectedEmployee.last_name}
                    </p>
                    <p className="employee-designation">
                      {selectedEmployee.designation}
                    </p>
                    <p className="employee-designation">
                      {selectedEmployee.user_role}
                    </p>
                    <p className="employee-designation">
                      {selectedEmployee.contact}
                    </p>
                    <p className="employee-teams">Teams:</p>
                    <p className="teams">Marketing, Sale and Research</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className="weekly-goals-module-style">
            <Row gutter={16}>
              <Col className="gutter-row" span={16}>
                <p className="weekly-goals-heading">Employees</p>
              </Col>
              <Col className="gutter-row" span={8}>
                <div className="dots-div">
                  <MoreOutlined className="menu-icon-style" />
                </div>
              </Col>
            </Row>
            <div className="details-div">
              {filteredEmployeesList.map((employee, uniId) => (
                <div
                  className="goal-details-div"
                  key={uniId}
                  onClick={() => onClickEmployee(employee.id)}
                >
                  <Row gutter={16}>
                    <Col
                      className="gutter-row"
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <img
                        src={employee.image || userDummy}
                        alt="employee"
                        className="list-employee-image-style"
                      />
                    </Col>
                    <Col
                      className="gutter-row"
                      sxs={18}
                      sm={18}
                      md={18}
                      lg={18}
                      xl={18}
                    >
                      <p className="weekly-goals-duration">
                        {" "}
                        {employee.first_name + " " + employee.last_name}
                      </p>
                      <p className="weekly-goal-assignee">
                        {" "}
                        {employee.designation}
                      </p>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default EmployeeProfile;
