import React, { useState, useEffect } from "react";
import {
  Input,
  Modal,
  Button,
  Row,
  Col,
  Select,
  DatePicker,
  Form,
  Popconfirm,
} from "antd";
import { withRouter, Link } from "react-router-dom";
import {
  HeartOutlined,
  MessageOutlined,
  HeartFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { getHighFive } from "../../redux/actions/HighFive/getHighFive";
import { createHighFiveLike } from "../../redux/actions/HighFive/createHighFiveLike";
import { getHighFiveLike } from "../../redux/actions/HighFive/getHighFiveLike";
import { getHighFiveLikes } from "../../redux/actions/HighFive/getHighFiveLikes";
import { createHighFiveComment } from "../../redux/actions/HighFive/createHighFiveComment";
import { getHighFiveComment } from "../../redux/actions/HighFive/getHighFiveComment";
import { deleteHighFiveComment } from "../../redux/actions/HighFive/deleteHighFiveComment";
import { getOrgUsers } from "../../redux/actions/User/getOrgUsers";
import MostAppreciatedHighFive from "../../components/HighFiveDashboard/mostAppreciated";
import HighFiveUser from "../../components/HighFiveDashboard/highFiveUser";
import TopHighFives from "../../components/HighFiveDashboard/topHighFives";

const HighFiveDashboard = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const [highFiveId, setHighFiveId] = useState("");
  const [data, setData] = useState();
  const [UsersList, setUsersList] = useState();
  const [highFiveComment, setHighFiveComment] = useState();
  const [likedPerson, setLikedPerson] = useState();
  const [highFiveLikes, setHighFiveLikes] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [commentid, setCommentid] = useState(false);
  const [isLikeModalVisible, setIsLikeModalVisible] = useState(false);
  const getOrgUsersStatus = useSelector((state) => state.getOrgUsers);
  const getHighFiveCommentStatus = useSelector(
    (state) => state.getHighFiveComment
  );
  const deleteHighFiveCommentStatus = useSelector(
    (state) => state.deleteHighFiveComment
  );
  const createHighFiveCommentStatus = useSelector(
    (state) => state.createHighFiveComment
  );
  const getHighFiveLikeStatus = useSelector((state) => state.getHighFiveLike);
  const getHighFiveLikesStatus = useSelector((state) => state.getHighFiveLikes);
  const getHighFiveStatus = useSelector((state) => state.getHighFive);
  const createHighFiveLikeStatus = useSelector(
    (state) => state.createHighFiveLike
  );

  const showLikeModal = (value) => {
    setIsLikeModalVisible(true);
    dispatch(getHighFiveLike(value));
  };

  const handleLikeOk = () => {
    setIsLikeModalVisible(false);
  };

  const handleLikeCancel = () => {
    setIsLikeModalVisible(false);
  };
  const showModal = (id) => {
    localStorage.setItem("selected_high_five_id", id);
    setIsModalVisible(true);
    dispatch(getHighFiveComment(id));
    setCommentid(id);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onDeleteHighFiveComment = (selected_high_five_id, id) => {
    dispatch(deleteHighFiveComment(selected_high_five_id, id));
  };

  useEffect(() => {
    dispatch(getOrgUsers());
    dispatch(getHighFive("", "", ""));
    localStorage.removeItem("status");
  }, [dispatch]);

  const onFinish = (values) => {
    const formData = new FormData(),
      formDataObj = Object.fromEntries(formData.entries());
    formDataObj.comment = values.comment;
    for (let i = 0; i < highFiveId.length; i++) {
      if (highFiveId[i].id === commentid) {
        dispatch(createHighFiveComment(highFiveId[i].id, formDataObj.comment));
      }
    }
    form.resetFields();
  };

  useEffect(() => {
    if (createHighFiveLikeStatus && createHighFiveLikeStatus.status === 201) {
      dispatch(getHighFive("", "", ""));
      dispatch(getHighFiveLikes());
    }
  }, [createHighFiveLikeStatus, dispatch]);

  useEffect(() => {
    if (getHighFiveLikesStatus && getHighFiveLikesStatus.status === 200) {
      setHighFiveLikes(getHighFiveLikesStatus.data);
    }
  }, [getHighFiveLikesStatus]);
  useEffect(() => {
    const selected_high_five_id = localStorage.getItem("selected_high_five_id");
    if (
      deleteHighFiveCommentStatus &&
      deleteHighFiveCommentStatus.status === 200
    ) {
      dispatch(getHighFiveComment(selected_high_five_id));
      dispatch(getHighFive("", "", ""));
    }
  }, [deleteHighFiveCommentStatus]);

  useEffect(() => {
    if (getHighFiveStatus && getHighFiveStatus.status === 200) {
      setHighFiveId(getHighFiveStatus.data);
    }
  }, [getHighFiveStatus]);

  useEffect(() => {
    if (createHighFiveLikeStatus && createHighFiveLikeStatus.status === 204) {
      dispatch(getHighFive("", "", ""));
      dispatch(getHighFiveLikes());
    }
  }, [createHighFiveLikeStatus, dispatch]);

  useEffect(() => {
    if (getHighFiveLikeStatus && getHighFiveLikeStatus.status === 200) {
      setLikedPerson(getHighFiveLikeStatus.data);
    }
  }, [getHighFiveLikeStatus]);

  useEffect(() => {
    if (getOrgUsersStatus && getOrgUsersStatus.status === 200) {
      setUsersList(getOrgUsersStatus.data);
    }
  }, [getOrgUsersStatus]);

  useEffect(() => {
    if (getHighFiveStatus && getHighFiveStatus.status === 200) {
      setData(getHighFiveStatus.data);
    }
  }, [getHighFiveStatus]);

  useEffect(() => {
    if (getHighFiveCommentStatus && getHighFiveCommentStatus.status === 200) {
      setHighFiveComment(getHighFiveCommentStatus.data);
    }
  }, [getHighFiveCommentStatus]);

  useEffect(() => {
    const selected_high_five_id = localStorage.getItem("selected_high_five_id");
    if (
      createHighFiveCommentStatus &&
      createHighFiveCommentStatus.status === 201
    ) {
      dispatch(getHighFive("", "", ""));
      dispatch(getHighFiveComment(selected_high_five_id));
    }
  }, [createHighFiveCommentStatus, dispatch]);

  function onChange(value) {
    dispatch(getHighFive(value));
    localStorage.setItem("selected_user", value);
  }
  const selectUser = localStorage.getItem("selected_user");

  useEffect(() => {
    localStorage.removeItem("selected_user");
  }, []);

  function onChangeStatus(status) {
    dispatch(getHighFive(localStorage.getItem("selected_user"), status));
    localStorage.setItem("status", status);
  }
  function onChangeMonth(range) {
    const start_date = range[0].format().substring(0, 10);
    const end_date = range[1].format().substring(0, 10);
    dispatch(
      getHighFive(
        localStorage.getItem("selected_user"),
        localStorage.getItem("status"),
        start_date,
        end_date
      )
    );
  }

  function onLike(value) {
    dispatch(createHighFiveLike(value));
  }

  useEffect(() => {
    dispatch(getHighFiveLikes());
  }, [dispatch]);
  const selected_high_five_id = localStorage.getItem("selected_high_five_id");
  const current_user_id = localStorage.getItem("user_id");

  return (
    <>
      <Modal
        visible={isLikeModalVisible}
        onOk={handleLikeOk}
        onCancel={handleLikeCancel}
      >
        <div className="likeCountListing">
          {likedPerson &&
            likedPerson.map((value) => (
              <Row className="likedPersonInfo">
                <img
                  className="userImage"
                  src={`${process.env.REACT_APP_BACKEND_API_BASE}${
                    value && value.image.orignal
                  }`}
                  alt=""
                />

                <p className="likedUsername">{value.likedBy}</p>
              </Row>
            ))}
        </div>
      </Modal>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="commentModal"
      >
        <div className="commentContent">
          <Row>
            {highFiveComment &&
              highFiveComment.map((value) => (
                <div className="CommentPersonInfo">
                  <img
                    className="userImage"
                    src={value && value.user_image}
                    alt=""
                  />
                  <div className="commentAndDate">
                    <p className="commentedUsername">{value.coment}</p>
                    <p className="commentedDate">
                      {value && value.date.substring(0, 10)}
                    </p>
                  </div>
                  <div>
                    <Popconfirm
                      title="Are you sure delete this comment?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() =>
                        onDeleteHighFiveComment(
                          selected_high_five_id,
                          value && value.id
                        )
                      }
                    >
                      {value && value.user_id == current_user_id ? (
                        <DeleteOutlined
                          style={{ color: "white", paddingLeft: "20px" }}
                        />
                      ) : (
                        ""
                      )}
                    </Popconfirm>
                  </div>
                </div>
              ))}
          </Row>
        </div>
        <div commnetInputBtnDiv>
          <Row>
            <Form form={form} onFinish={onFinish}>
              <Col span="16">
                <div className="commentTextArea">
                  <Form.Item
                    name="comment"
                    rules={[
                      {
                        required: true,
                        message: "Please input your comment first!",
                      },
                    ]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </div>
              </Col>
              <Col span="8">
                <div className="commentButton">
                  <Form.Item>
                    <Button className="commentBtnStyle" htmlType="submit">
                      Comment
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Form>
          </Row>
        </div>
      </Modal>
      <h1 className="highFivesDashboardTitle">High five</h1>
      <h1 className="highFivesDashboardFilter">Filter</h1>
      <div className="highFivesDashboardFilters">
        <Row>
          <Col span="6">
            <div className="peoplesFilter">
              <h1 className="peopleHeading">People</h1>
              <Select
                showSearch
                onChange={onChange}
                placeholder="Select a user"
                className="search"
              >
                {" "}
                {UsersList &&
                  UsersList.map((value) => (
                    <Option value={value.id}>
                      {value.first_name + value.last_name}
                    </Option>
                  ))}
              </Select>
            </div>
          </Col>
          <Col span="6">
            <div className="typeFilter">
              <h1 className="typeHeading">Type</h1>
              {selectUser ? (
                <Select
                  showSearch
                  onChange={onChangeStatus}
                  style={{ width: 200 }}
                  placeholder="Sent and Received"
                >
                  <Option value="sent">Sent</Option>
                  <Option value="received">Receive</Option>
                </Select>
              ) : (
                <Select
                  showSearch
                  onChange={onChangeStatus}
                  style={{ width: 200 }}
                  placeholder="Sent and Received"
                  disabled="true"
                >
                  <Option value="sent">Sent</Option>
                  <Option value="received">Receive</Option>
                </Select>
              )}
            </div>
          </Col>
          <Col span="6">
            <div className="timePeriodFilter">
              <h1 className="timePeriodHeading">Time Period</h1>
              <DatePicker.RangePicker onChange={onChangeMonth} />
            </div>
          </Col>
          <Col span="6"></Col>
        </Row>
      </div>

      <Row>
        <Col span="15">
          <div className="createHighFivesSection">
            <Row>
              <div className="giveHighFiveDiv">
                <h1 className="createHighFiveHeading">Give a High Five</h1>
                <p className="createHighFiveContent">
                  Who did an amazing work that made your day? High fives will be
                  posted on this public dashboard
                </p>
                <Link to="/createhighfive">
                  <Button className="createHighFiveButton">
                    Create High Five
                  </Button>
                </Link>
              </div>
            </Row>
          </div>

          <div className="highFivesListSection">
            {data &&
              data.map((value) => (
                <>
                  <div className="currentListDate">
                    <p className="currentListDateMonth">
                      {/* {value && value.date.substring(0, 10)} */}
                    </p>
                  </div>
                  <div className="highFiversfListScrollBar">
                    <div className="GivenHighFiveList">
                      <Row>
                        <Col span="5">
                          <div className="twoHighFivesCreator">
                            <img
                              className="userOneImag"
                              src={value && value.from_user_image}
                              alt=""
                            />

                            <img
                              className="userImage"
                              src={value && value.to_user_image}
                              alt=""
                            />
                          </div>
                        </Col>
                        <Col span="19">
                          <p className="givenHighFiveTitle">
                            {value.from_user_name} to {value.to_user_name}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span="5"></Col>
                        <Col span="19">
                          <p className="givenHighFiveContent">{value.coment}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span="5"></Col>
                        <Col span="1">
                          {highFiveLikes?.some(
                            ({ high_five_id }) => value.id === high_five_id
                          ) ? (
                            <HeartFilled
                              onClick={() => onLike(value.id)}
                              className="likeIcon"
                            />
                          ) : (
                            <HeartOutlined
                              onClick={() => onLike(value.id)}
                              className="likeIcon"
                            />
                          )}
                        </Col>
                        <Col span="2">
                          <p
                            className="likesCount"
                            onClick={() => showLikeModal(value.id)}
                          >
                            {value.likes_count}
                          </p>
                        </Col>
                        <Col span="1">
                          <MessageOutlined
                            className="commentIcon"
                            onClick={() => showModal(value.id)}
                          />
                        </Col>
                        <Col span="2">
                          <p className="commentsCount">{value.coments_count}</p>
                        </Col>
                        <Col span="13">
                          <p className="givenHighFiveContent"></p>
                        </Col>
                      </Row>

                      <div className="dottedLine"></div>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </Col>
        <Col span="2"></Col>
        <Col span="7">
          <HighFiveUser />
          <MostAppreciatedHighFive />
          <TopHighFives />
        </Col>
      </Row>
    </>
  );
};

export default withRouter(HighFiveDashboard);
