import {
  USER_SEEN_SUCCESS,
  USER_SEEN_REQUEST,
  USER_SEEN_ERROR,
} from "../../actions/TimeOff/userSeen";

const userSeen = (state = null, action) => {
  switch (action.type) {
    case USER_SEEN_REQUEST: {
      return true;
    }
    case USER_SEEN_SUCCESS: {
      return action.response;
    }
    case USER_SEEN_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default userSeen;
