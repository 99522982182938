import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, DatePicker, message } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { createWeeklyGoal } from "../../redux/actions/Organization/createWeeklyGoal";
import { createGoal } from "../../redux/actions/Organization/createGoal";
import Loader from "../../components/Loader";
import Schema from "async-validator";
import moment from "moment";
import "./style.css";

const AddWeeklyGoals = (props) => {
  const dispatch = useDispatch();
  Schema.warning = function () {};
  const createWeeklyGoalStatus = useSelector((state) => state.createWeeklyGoal);
  const [goalsState, setGoalsState] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [goalLoaderState, setgoalLoaderState] = useState(false);
  const { TextArea } = Input;
  const { history } = props;
  function onChangeMonth(range) {
    setStartDate(moment(range[0]).unix());
    setEndDate(moment(range[1]).unix());
  }
  const onFinish = (values) => {
    if (values) {
      setGoalsState(values.goals);
      dispatch(createWeeklyGoal(values, startDate, endDate));
    }
  };
  useEffect(() => {
    if (createWeeklyGoalStatus && createWeeklyGoalStatus.status === 422) {
    } else if (
      createWeeklyGoalStatus &&
      createWeeklyGoalStatus.status === 201
    ) {
      if (goalsState && goalsState.length > 0) {
        setgoalLoaderState(true);
        for (let i = 0; i < goalsState.length; i++) {
          dispatch(createGoal(createWeeklyGoalStatus.data.id, goalsState[i]));
        }
      }
      setgoalLoaderState(false);
      history.push("/listing");
    } else if (
      createWeeklyGoalStatus &&
      createWeeklyGoalStatus.status === 500
    ) {
      message.error("Internal Server Error");
    }
  }, [createWeeklyGoalStatus, goalsState, dispatch, history]);

  return (
    <>
      <h1 className="addweeklygoals-heading-styling">Create Weekly Goals </h1>
      <div className="addweeklygoals-main">
        <div className="addWeeklyGoals-inner">
          <Form hideRequiredMark onFinish={onFinish}>
            <Form.Item
              label="Duration"
              name="duration"
              rules={[
                {
                  required: true,
                  message: "Please input  your duration!",
                },
              ]}
            >
              <DatePicker.RangePicker
                onChange={onChangeMonth}
                style={{ width: "100%" }}
                value={[
                  startDate ? moment.unix(startDate) : null,
                  endDate ? moment.unix(endDate) : null,
                ]}
              />
            </Form.Item>
            <Form.Item
              label="Details"
              name="description"
              rules={[
                {
                  required: true,
                  min: 5,
                  message: "Detail should be atleast 5 character!",
                },
              ]}
            >
              <TextArea rows={2} />
            </Form.Item>

            <Form.List name="goals">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <div
                        key={field.key}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Form.Item
                          label={`Goal ${index + 1}`}
                          required={true}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input goal",
                            },
                            {
                              min: 5,
                              message:
                                "Goal name should be at least 5 characters!",
                            },
                          ]}
                        >
                          <Form.Item
                            {...field}
                            required={true}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input goal",
                              },
                              {
                                min: 5,
                                message:
                                  "Goal name should be at least 5 characters!",
                              },
                            ]}
                            noStyle
                          >
                            <Input placeholder="goal" />
                          </Form.Item>
                        </Form.Item>

                        {fields.length > 1 && (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        )}
                      </div>
                    ))}
                    <div className="weeklyGoalActionBtns">
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                          }}
                          style={{
                            width: "100%",
                            marginTop: "10px",
                          }}
                        >
                          <PlusOutlined /> Add Goal
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          id="addWeeklyGoalBtn"
                          type="primary"
                          htmlType="submit"
                          style={{ width: 150 }}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                );
              }}
            </Form.List>
          </Form>

          {createWeeklyGoalStatus === true || goalLoaderState === true ? (
            <Loader />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(AddWeeklyGoals);
