import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form, Select, Button, message, Popconfirm, Input } from "antd";
import { getTeam } from "../../redux/actions/Team/getTeam";
import { getTeamMembers } from "../../redux/actions/Team/getTeamMembers";
import { addTeamMember } from "../../redux/actions/Organization/addTeamMember";
import { deleteTeamMember } from "../../redux/actions/Team/deleteTeamMember";
import { updateTeam } from "../../redux/actions/Team/updateTeam";
import { getOrgUsers } from "../../redux/actions/User/getOrgUsers";
import Schema from "async-validator";
import { CloseOutlined } from "@ant-design/icons";
import Loader from "../../components/Loader";
import "./style.css";

const TeamUpdate = (props) => {
  Schema.warning = function () {};
  const history = useHistory();
  const dispatch = useDispatch();
  const getTeamStatus = useSelector((state) => state.getTeam);
  const getEmployeesList = useSelector((state) => state.getTeamMembers);
  const addTeamMemberStatus = useSelector((state) => state.addTeamMember);
  const deleteTeamMemberStatus = useSelector((state) => state.deleteTeamMember);
  const updateTeamStatus = useSelector((state) => state.updateTeam);
  const [employeesList, setEmployeesList] = useState([]);
  const [teamDetail, setTeamDetail] = useState();

  const { TextArea } = Input;

  const user_id = Number(localStorage.getItem("user_id"));

  const [usersList, setUsersList] = useState([]);
  const getOrgUsersStatus = useSelector((state) => state.getOrgUsers);

  useEffect(() => {
    if (getOrgUsersStatus && getOrgUsersStatus.status === 200) {
      setUsersList(getOrgUsersStatus.data);
    }
  }, [getOrgUsersStatus]);

  useEffect(() => {
    dispatch(getOrgUsers());
  }, [dispatch]);

  const validateMembers = (_, value) => {
    const isValid = value.every((selectedUserEmail) =>
      usersList.some((user) => user.email === selectedUserEmail)
    );

    if (!isValid) {
      return Promise.reject("Select only from the provided users.");
    }

    return Promise.resolve();
  };

  useEffect(() => {
    dispatch(getTeam(props.match.params.teamId));
    dispatch(getTeamMembers(props.match.params.teamId));
  }, [props.match.params.teamId, dispatch]);

  useEffect(() => {
    if (getTeamStatus && getTeamStatus.data) {
      setTeamDetail(getTeamStatus.data[0]);
    }
  }, [getTeamStatus]);

  useEffect(() => {
    if (getEmployeesList && getEmployeesList.status === 200) {
      setEmployeesList(getEmployeesList.data);
    }
  }, [getEmployeesList]);

  useEffect(() => {
    if (addTeamMemberStatus && addTeamMemberStatus.successStatus === 201) {
      message.success(`${addTeamMemberStatus.successEmail} added successfully`);
      history.push(`/team/list`);
      dispatch(getTeamMembers(props.match.params.teamId));
    } else if (addTeamMemberStatus && addTeamMemberStatus.errorStatus === 500) {
      message.error(
        `${addTeamMemberStatus.failedEmail} is not a member of organization`
      );
    } else if (addTeamMemberStatus && addTeamMemberStatus.errorStatus === 422) {
      message.error(
        `${addTeamMemberStatus.failedEmail} is already added in the team`
      );
    }
  }, [addTeamMemberStatus, dispatch, props.match.params.teamId, history]);

  useEffect(() => {
    if (deleteTeamMemberStatus === 204) {
      dispatch(getTeamMembers(props.match.params.teamId));
    } else if (deleteTeamMemberStatus === 500) {
      message.error("Internal Server Error");
    }
  }, [deleteTeamMemberStatus, props.match.params.teamId, dispatch]);

  useEffect(() => {
    if (updateTeamStatus === 200) {
      dispatch(getTeam(props.match.params.teamId));
    } else if (updateTeamStatus === 500) {
      message.error("Internal Server Error");
    }
  }, [updateTeamStatus, props.match.params.teamId, dispatch]);

  const onFinish = (values) => {
    if (values) {
      let teamID = props.match.params.teamId;
      let teamDetails = {
        teamName: values.teamName,
        description: values.description,
      };
      if (
        teamDetail.title !== teamDetails.teamName ||
        teamDetail.description !== teamDetails.description
      ) {
        dispatch(updateTeam(teamDetails, teamID));
      }
      for (let i = 0; i < values.members.length; i++) {
        dispatch(addTeamMember(values.members[i], teamID));
      }
    }
  };

  const deleteTeamMembers = (teamMemberId) => {
    dispatch(deleteTeamMember(props.match.params.teamId, teamMemberId));
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 20,
        offset: 8,
      },
    },
  };
  return (
    <>
      <h1 className="team-detail-heading-styling ">Update Team:</h1>
      {teamDetail ? (
        <div className="team-detail-main">
          <Form
            {...formItemLayout}
            className="form-align"
            hideRequiredMark
            onFinish={onFinish}
            initialValues={{
              description: teamDetail ? teamDetail.description : "",
              teamName: teamDetail ? teamDetail.title : "",
            }}
          >
            <h1 className="team-heading-style ">
              {teamDetail ? teamDetail.title : ""}
            </h1>
            <Form.Item
              label="Team Name"
              name="teamName"
              rules={[
                {
                  required: true,
                  message: "Please input your project name!",
                },
                {
                  min: 3,
                  message: "Team Name name should be atleast 3 character!",
                },
                {
                  max: 25,
                  message:
                    "Team Name name should not be greater then 25 character!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please input your team description!",
                },
                {
                  min: 3,
                  message: "Team description should be atleast 3 character!",
                },
              ]}
            >
              <TextArea rows={2} />
            </Form.Item>
            <Form.Item label="Created By">
              <span className="team-detail-item-detail ">Software House</span>
            </Form.Item>

            <Form.Item label="Members">
              {employeesList && employeesList.length ? (
                <ol style={{ paddingLeft: 15 }}>
                  {employeesList.map((employee, uniId) => (
                    <li key={uniId}>
                      {`${employee.first_name} ${employee.last_name} `}

                      <Popconfirm
                        title="Are you sure delete this member?"
                        onConfirm={() => deleteTeamMembers(employee.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <CloseOutlined style={{ marginLeft: 10 }} />
                      </Popconfirm>
                    </li>
                  ))}
                </ol>
              ) : (
                <span>No employee found</span>
              )}
            </Form.Item>
            <Form.Item
              label="Add Members"
              name="members"
              rules={[{ validator: validateMembers }]}
            >
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Select members"
              >
                {usersList
                  .filter(
                    (user) =>
                      user.id !== user_id &&
                      !employeesList.some(
                        (employee) => employee.email === user.email
                      )
                  )
                  .map((user) => (
                    <Select.Option key={user.id} value={user.email}>
                      {user.email}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Button
                id="teamUpdateBtn"
                type="primary"
                htmlType="submit"
                style={{ width: 150 }}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default withRouter(TeamUpdate);
