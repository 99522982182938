import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import { getCheckin } from "../../redux/actions/Checkin/getCheckin";
import { getWeeklyGoals } from "../../redux/actions/Organization/weeklyGoalsList";
import { getProfileViewDetails } from "../../redux/actions/ProfileView/getProfileViewDetails";
import { convertUNIXString } from "../../utils/convertUnixTimeStamp";
import { Rate } from "antd";
import "./style.css";

const ViewCheckIn = (props) => {
  const { id } = useParams();
  const goals = useSelector((state) => state.weeklyGoalList);
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState("");
  const [matchingWeeklyGoal, setMatchingWeeklyGoal] = useState(null);
  const user_id = localStorage.getItem("user_id");
  const getProfileViewDetailsStatus = useSelector(
    (state) => state.getProfileViewDetails
  );
  const getCheckinStatus = useSelector((state) => state.getCheckin);
  const [value, setValue] = useState("");

  const matchingGoal =
    goals &&
    goals.data &&
    goals.data.find((goal) => goal.id === parseInt(id, 10));

  useEffect(() => {
    if (matchingGoal && !matchingWeeklyGoal) {
      setMatchingWeeklyGoal(matchingGoal);
    }
  }, [matchingGoal, matchingWeeklyGoal]);

  useEffect(() => {
    dispatch(getWeeklyGoals());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProfileViewDetails(user_id));
  }, [dispatch, user_id]);

  useEffect(() => {
    if (
      getProfileViewDetailsStatus &&
      getProfileViewDetailsStatus.status === 200
    ) {
      setUserDetails(getProfileViewDetailsStatus.data[0]);
    }
  }, [getProfileViewDetailsStatus]);

  useEffect(() => {
    dispatch(getCheckin(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (getCheckinStatus && getCheckinStatus.status === 200) {
      setValue(getCheckinStatus.data);
    }
  }, [getCheckinStatus]);

  return (
    <>
      <p className="view-checkin-title">View Checkin</p>
      <div className="viewcheckInsMain">
        <div className="viewCheckInContent">
          <div className="weekly-goal-info">
            <div className="user-content">
              <img
                src={userDetails && userDetails.image}
                alt=""
                className="checkin-user-img"
              />
              <div className="user-info">
                <p className="checkin-user-name">
                  {userDetails && userDetails.first_name}{" "}
                  {userDetails && userDetails.last_name}
                </p>
                <p className="checkin-user-designation">
                  {userDetails && userDetails.designation}
                </p>
              </div>
            </div>
            <p className="checkin-weekly-goal-duration">
              {convertUNIXString(
                matchingWeeklyGoal && matchingWeeklyGoal.startDate
              )}
              {" to "}
              {convertUNIXString(
                matchingWeeklyGoal && matchingWeeklyGoal.endDate
              )}
            </p>
          </div>
          <p className="awfulRating">How are you feeling today?</p>
          <div className="ratingRow">
            <p className="awfulRating">Awful</p>

            <Rate
              disabled
              value={value && value[0] ? value[0].answer : 0}
              className="ratings"
            />
            <p className="greatRating">Great</p>
          </div>

          <p className="prioritiesQuestion">
            What are the most imprortant priorities in the coming week?
          </p>

          <p>{value && value[1] && value[1].answer}</p>

          <p className="blockersQuestion">
            Did you have any blockers this week?
          </p>

          <p>{value && value[2] && value[2].answer}</p>

          <p className="sharingQuestion">
            What do you want to share with others? (if anything)
          </p>

          <p>{value && value[3] && value[3].answer}</p>
        </div>
      </div>
    </>
  );
};

export default withRouter(ViewCheckIn);
