import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
export const UPDATE_WEEKLY_GOAL_SUCCESS = "UPDATE_WEEKLY_GOAL_SUCCESS";
export const UPDATE_WEEKLY_GOAL_ERROR = "UPDATE_WEEKLY_GOAL_ERROR";
export const UPDATE_WEEKLY_GOAL_REQUEST = "UPDATE_WEEKLY_GOAL_REQUEST ";

export const updateWeeklyGoal =
  (description, startDate, endDate, weeklyGoalId) => (dispatch) => {
    const user_id = localStorage.getItem("user_id");
    dispatch({ type: UPDATE_WEEKLY_GOAL_REQUEST });
    return axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/weekly_goals/${weeklyGoalId}`,
      data: {
        startDate: startDate,
        endDate: endDate,
        description: description,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "access-token": readLocalStorage("access-token"),
      },
    })
      .then((response) => {
        return dispatch({
          type: UPDATE_WEEKLY_GOAL_SUCCESS,
          response: response.status,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(tokenAuthFailedAction());
        }
        return dispatch({
          type: UPDATE_WEEKLY_GOAL_ERROR,
          response: error.response.status,
        });
      });
  };
