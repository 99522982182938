import axios from "axios";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
import { readLocalStorage } from "../../../utils/readLocalStorage";
export const GOAL_DELETE_SUCCESS = "GOAL_DELETE_SUCCESS";
export const GOAL_DELETE_ERROR = "GOAL_DELETE_ERROR";
export const GOAL_DELETE_REQUEST = "GOAL_DELETE_REQUEST ";
export const GOAL_DELETE_RESET = "GOAL_DELETE_RESET";

export const deleteGoal = (weeklyGoalId, goalId) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");
  dispatch({ type: GOAL_DELETE_REQUEST });
  return axios({
    method: "delete",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/weekly_goals/${weeklyGoalId}/goals/${goalId}`,
    crossDomain: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      dispatch({ type: GOAL_DELETE_SUCCESS, response: response.status });
      dispatch({ type: GOAL_DELETE_RESET });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(tokenAuthFailedAction());
      }
      dispatch({ type: GOAL_DELETE_ERROR, response: error.response.status });
      dispatch({ type: GOAL_DELETE_RESET });
    });
};
