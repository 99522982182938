import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { getOrgUsers } from "../../redux/actions/User/getOrgUsers";
import dummyImage from "./dummy-image.png";

const HighFiveUser = () => {
  const dispatch = useDispatch();
  const [UsersList, setUsersList] = useState();
  const getOrgUsersStatus = useSelector((state) => state.getOrgUsers);
  useEffect(() => {
    if (getOrgUsersStatus && getOrgUsersStatus.status === 200) {
      setUsersList(getOrgUsersStatus.data);
    }
  }, [getOrgUsersStatus]);

  useEffect(() => {
    dispatch(getOrgUsers());
  }, [dispatch]);

  const current_user_id = localStorage.getItem("user_id");
  return (
    <>
      {UsersList &&
        UsersList.map((user) => (
          <>
            {user && user.id == current_user_id ? (
              <div className="givenSentHighFivesSection">
                <div className="userImageWhoGiveHighFive">
                  {user && user.image ? (
                    <img
                      className="userImgSentReceivedSection"
                      src={`${user && user.image}`}
                      alt=""
                    />
                  ) : (
                    <img
                      id="roundedProfileImg"
                      src={dummyImage}
                      className="userOneImag"
                      alt="Preview"
                    />
                  )}
                  <p className="userNameSentReceivedSection">
                    {user.first_name} {user.last_name}
                  </p>
                  <p className="userdesignationSentReceivedSection">
                    {user.designation}
                  </p>
                </div>

                <Row>
                  <Col span="10">
                    <p className="highFiveGiveStyle">Given</p>
                    <p className="givenHighFiveCount">
                      {user.received_high_five}
                    </p>
                  </Col>
                  <Col span="4">
                    <div class="vertical"></div>
                  </Col>
                  <Col span="10">
                    <p className="highFiveSentStyle">Sent</p>
                    <p className="sentHighFiveCount">{user.sent_high_five}</p>
                  </Col>
                </Row>
              </div>
            ) : (
              ""
            )}
          </>
        ))}
    </>
  );
};
export default HighFiveUser;
