import {
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_ERROR,
} from "../../actions/User/getEmployees";

const getEmployees = (state = null, action) => {
  switch (action.type) {
    case GET_EMPLOYEES_REQUEST: {
      return true;
    }
    case GET_EMPLOYEES_SUCCESS: {
      return action.response;
    }
    case GET_EMPLOYEES_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getEmployees;
