import {
  CREATE_WEEKLY_GOAL_SUCCESS,
  CREATE_WEEKLY_GOAL_REQUEST,
  CREATE_WEEKLY_GOAL_ERROR,
  CREATE_WEEKLY_GOAL_RESET
} from "../../actions/Organization/createWeeklyGoal";

const createWeeklyGoal = (state = null, action) => {
  switch (action.type) {
    case CREATE_WEEKLY_GOAL_REQUEST: {
      return true;
    }
    case CREATE_WEEKLY_GOAL_SUCCESS: {
      return action.response;
    }
    case CREATE_WEEKLY_GOAL_ERROR: {
      return action.response;
    }
    case CREATE_WEEKLY_GOAL_RESET: {
      return null;
    }
    default:
      return state;
  }
};

export default createWeeklyGoal;
