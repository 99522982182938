import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, message, Popconfirm, Select } from "antd";
import { getOrganization } from "../../redux/actions/Organization/getOrganization";
import { getTeam } from "../../redux/actions/Team/getTeam";
import { getTeamMembers } from "../../redux/actions/Team/getTeamMembers";
import { addTeamMember } from "../../redux/actions/Organization/addTeamMember";
import { deleteTeamMember } from "../../redux/actions/Team/deleteTeamMember";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { getOrgUsers } from "../../redux/actions/User/getOrgUsers";
import "./style.css";

const TeamDetail = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const getOrganizationStatus = useSelector((state) => state.getOrganization);
  const getTeamStatus = useSelector((state) => state.getTeam);
  const getEmployeesList = useSelector((state) => state.getTeamMembers);
  const addTeamMemberStatus = useSelector((state) => state.addTeamMember);
  const deleteTeamMemberStatus = useSelector((state) => state.deleteTeamMember);
  const [organizationDetails, setOrganizationDetails] = useState();
  const [employeesList, setEmployeesList] = useState([]);
  const [teamDetail, setTeamDetail] = useState();

  const user_id = Number(localStorage.getItem("user_id"));

  const [usersList, setUsersList] = useState([]);
  const getOrgUsersStatus = useSelector((state) => state.getOrgUsers);

  useEffect(() => {
    if (getOrgUsersStatus && getOrgUsersStatus.status === 200) {
      setUsersList(getOrgUsersStatus.data);
    }
  }, [getOrgUsersStatus]);

  useEffect(() => {
    dispatch(getOrgUsers());
  }, [dispatch]);

  const validateMembers = (_, value) => {
    const isValid = value.every((selectedUserEmail) =>
      usersList.some((user) => user.email === selectedUserEmail)
    );

    if (!isValid) {
      return Promise.reject("Select only from the provided users.");
    }

    return Promise.resolve();
  };

  useEffect(() => {
    if (addTeamMemberStatus && addTeamMemberStatus.successStatus === 201) {
      message.success("Team member added successfully");
      history.push(`/team/list`);
    }
  }, [addTeamMemberStatus, history]);

  useEffect(() => {
    dispatch(getTeam(props.match.params.teamId));
    dispatch(getTeamMembers(props.match.params.teamId));
  }, [props.match.params.teamId, dispatch]);

  useEffect(() => {
    if (getTeamStatus && getTeamStatus.data) {
      setTeamDetail(getTeamStatus.data);
    }
  }, [getTeamStatus]);

  useEffect(() => {
    if (getEmployeesList && getEmployeesList.status === 200) {
      setEmployeesList(getEmployeesList.data);
    }
  }, [getEmployeesList]);
  useEffect(() => {
    dispatch(getOrganization());
  }, [dispatch]);

  useEffect(() => {
    if (getOrganizationStatus && getOrganizationStatus.status === 200) {
      setOrganizationDetails(getOrganizationStatus.data[0]);
    }
  }, [getOrganizationStatus]);

  useEffect(() => {
    if (deleteTeamMemberStatus === 204) {
      dispatch(getTeamMembers(props.match.params.teamId));
    } else if (deleteTeamMemberStatus === 500) {
      message.error("Internal Server Error");
    }
  }, [deleteTeamMemberStatus, props.match.params.teamId, dispatch]);

  const onFinish = (values) => {
    let teamID = props.match.params.teamId;
    dispatch(addTeamMember(values.members, teamID));
  };

  const deleteTeamMembers = (teamMemberId) => {
    dispatch(deleteTeamMember(props.match.params.teamId, teamMemberId));
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 20,
        offset: 8,
      },
    },
  };
  return (
    <>
      <h1 className="team-detail-heading-styling ">View Team:</h1>
      <div className="team-detail-main">
        <div style={{ float: "right", paddingTop: "5px", paddingRight: "5px" }}>
          <Link to={`/team/${props.match.params.teamId}/update`}>
            <EditOutlined style={{ paddingRight: 10, paddingLeft: 10 }} />
          </Link>
        </div>
        <h1 className="view-team-heading-style ">
          {" "}
          {teamDetail ? teamDetail[0].title : ""}
        </h1>

        <Form
          {...formItemLayout}
          className="form-align"
          hideRequiredMark
          onFinish={onFinish}
        >
          <Form.Item label="Organization name">
            <span className="team-detail-item-detail ">
              {organizationDetails && organizationDetails.organization_name}
            </span>
          </Form.Item>
          <Form.Item label="Created By">
            <span className="team-detail-item-detail">
              {teamDetail ? teamDetail[0].user_name : ""}
            </span>
          </Form.Item>

          <Form.Item label="Members">
            {employeesList && employeesList.length ? (
              <ol style={{ paddingLeft: 15 }}>
                {employeesList.map((employee, uniId) => (
                  <li key={uniId}>
                    {/* {`${employee.first_name} ${employee.last_name} `} */}
                    {`${employee.email}  `}
                    <Popconfirm
                      title="Are you sure delete this member?"
                      onConfirm={() => deleteTeamMembers(employee.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <CloseOutlined style={{ marginLeft: 10 }} />
                    </Popconfirm>
                  </li>
                ))}
              </ol>
            ) : (
              <span>No employee found</span>
            )}
          </Form.Item>
          <Form.Item
            label="Add Members"
            name="members"
            rules={[{ validator: validateMembers }]}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Select members"
            >
              {usersList
                .filter(
                  (user) =>
                    user.id !== user_id &&
                    !employeesList.some(
                      (employee) => employee.email === user.email
                    )
                )
                .map((user) => (
                  <Select.Option key={user.id} value={user.email}>
                    {user.email}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button
              type="primary"
              htmlType="submit"
              className="update-team-btn"
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default withRouter(TeamDetail);
