import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Empty, Popconfirm, message, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getTeams } from "../../redux/actions/Team/getTeams";
import { deleteTeam } from "../../redux/actions/Team/deleteTeam";
import { DeleteOutlined } from "@ant-design/icons";
import "./style.css";
const { Link } = Typography;

const TeamListing = (props) => {
  const dispatch = useDispatch();
  const getTeamsStatus = useSelector((state) => state.getTeams);
  const deleteTeamStatus = useSelector((state) => state.deleteTeam);
  const [teamList, setTeamList] = useState();

  useEffect(() => {
    if (getTeamsStatus && getTeamsStatus.data) {
      setTeamList(getTeamsStatus.data);
    }
  }, [getTeamsStatus]);

  useEffect(() => {
    dispatch(getTeams());
  }, [dispatch]);

  useEffect(() => {
    if (deleteTeamStatus === 204) {
      dispatch(getTeams());
    } else if (deleteTeamStatus === 500) {
      message.delete("Internal Server Error");
    }
  }, [deleteTeamStatus, dispatch]);

  const deleteTeamFunc = (teamId) => {
    dispatch(deleteTeam(teamId));
    dispatch(getTeams());
  };

  return (
    <>
      <h1 className="team-listing-detail-heading-styling">Team List</h1>
      <Row gutter={16}>
        {teamList && teamList.length ? (
          teamList.map((team) => (
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <div className="team-new-style">
                <div className="delete-team-icon-style">
                  <Popconfirm
                    title="Are you sure delete this task?"
                    onConfirm={() => deleteTeamFunc(team.id)}
                    //onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </div>
                <Link href={`/team/${team.id}/detail`}>
                  <div className="team-content">
                    <p className="team-title-style">{team.title}</p>

                    <p className="team-description">{team.description}</p>
                    <p className="team-creator">Created By: {team.user_name}</p>
                  </div>
                </Link>
              </div>
            </Col>
          ))
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No teams found"
          />
        )}
      </Row>
    </>
  );
};

export default withRouter(TeamListing);
