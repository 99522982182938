import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
export const CREATE_CHECKIN_SUCCESS = "CREATE_CHECKIN_SUCCESS";
export const CREATE_CHECKIN_ERROR = "CREATE_CHECKIN_ERROR";
export const CREATE_CHECKIN_REQUEST = "CREATE_CHECKIN_REQUEST ";

export const createCheckin = (id, sortId, answer) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");
  dispatch({ type: CREATE_CHECKIN_REQUEST });

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/weekly_goals/${id}/check_ins`,
    data: {
      answer: answer,
      sort_id: sortId,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      return dispatch({
        type: CREATE_CHECKIN_SUCCESS,
        response: response.status,
      });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(tokenAuthFailedAction());
      }
      return dispatch({
        type: CREATE_CHECKIN_ERROR,
        response: error.response,
      });
    });
};
