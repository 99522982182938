import {
    CREATE_TIMEOFF_REQUEST_SUCCESS,
    CREATE_TIMEOFF_REQUEST_REQUEST,
    CREATE_TIMEOFF_REQUEST_ERROR,
    CREATE_TIMEOFF_REQUEST_RESET
  } from "../../actions/Organization/createTimeoffRequest";
  
  const createTimeoffRequest = (state = null, action) => {
    switch (action.type) {
      case CREATE_TIMEOFF_REQUEST_REQUEST: {
        return true;
      }
      case CREATE_TIMEOFF_REQUEST_SUCCESS: {
        return action.response;
      }
      case CREATE_TIMEOFF_REQUEST_ERROR: {
        return action.response;
      }
      case CREATE_TIMEOFF_REQUEST_RESET: {
        return null;
      }
      default:
        return state;
    }
  };
  
  export default createTimeoffRequest;
  