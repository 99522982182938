import {
  CREATE_COMMENT_SUCCESS,
  CREATE_COMMENT_REQUEST,
  CREATE_COMMENT_ERROR,
} from "../../actions/Comment/createComment";

const createComment = (state = null, action) => {
  switch (action.type) {
    case CREATE_COMMENT_REQUEST: {
      return true;
    }
    case CREATE_COMMENT_SUCCESS: {
      return action.response;
    }
    case CREATE_COMMENT_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default createComment;
