import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { MoreOutlined, EyeOutlined } from "@ant-design/icons";
import userDummy from "../../assets/user.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { convertUNIXString } from "../../utils/convertUnixTimeStamp";
import { getObjective } from "../../redux/actions/Okr/getObjective";
import { getUsers } from "../../redux/actions/User/getUsers";
import "./style.css";

const OkrModule = () => {
  const dispatch = useDispatch();
  const getObjectiveStatus = useSelector((state) => state.getObjective);
  const [objectives, setObjectives] = useState();
  const [UsersList, setUsersList] = useState([]);
  const getUsersList = useSelector((state) => state.getUsers);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    if (getUsersList && getUsersList.status === 200) {
      setUsersList(getUsersList.data);
    }
  }, [getUsersList]);

  useEffect(() => {
    dispatch(getObjective());
  }, [dispatch]);
  useEffect(() => {
    if (getObjectiveStatus && getObjectiveStatus.status === 200) {
      setObjectives(getObjectiveStatus.data);
    }
  }, [getObjectiveStatus]);
  const mapUserIdToUserData = (userId) => {
    if (!UsersList || UsersList.length === 0) {
      return { name: "Loading...", imageUrl: userDummy };
    }

    const user = UsersList.find((user) => user.id === userId);

    if (user) {
      return {
        name: `${user.first_name} ${user.last_name}`,
        imageUrl: user.image || userDummy,
      };
    } else {
      return { name: "Unknown User", imageUrl: userDummy };
    }
  };

  return (
    <div className="weekly-goals-module-style">
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <p className="weekly-goals-heading">OKRS</p>
        </Col>
        <Col className="gutter-row" span={12}>
          <div className="dots-div">
            <MoreOutlined className="menu-icon-style" />
          </div>
        </Col>
      </Row>
      <div className="details-div">
        {objectives && objectives ? (
          objectives.map((data, index) => (
            <div className="goal-details-div" key={index}>
              <Link to={`/update_okr/${data.id}`}>
                <Row gutter={16}>
                  <Col
                    className="gutter-row"
                    xs={3}
                    sm={3}
                    md={4}
                    lg={5}
                    xl={3}
                  >
                    <img
                      src={mapUserIdToUserData(data.userId).imageUrl}
                      alt="User"
                      className="user-dummy-image-style"
                    />
                  </Col>
                  <Col
                    className="gutter-row"
                    sxs={18}
                    sm={18}
                    md={17}
                    lg={15}
                    xl={18}
                  >
                    <p className="weekly-goals-duration">
                      {convertUNIXString(data.startDate) +
                        " - " +
                        convertUNIXString(data.endDate)}
                    </p>
                    <p className="weekly-goal-assignee">
                      by: {mapUserIdToUserData(data.userId).name}
                    </p>
                  </Col>
                  <Col
                    className="gutter-row"
                    xs={3}
                    sm={3}
                    md={3}
                    lg={4}
                    xl={3}
                  >
                    <div className="weeklygoal-action-button">
                      <p className="view-text">view</p>
                      <div className="view-icon-div">
                        <EyeOutlined className="eye-icon-style" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Link>
            </div>
          ))
        ) : (
          <div style={{ marginTop: "100px" }}>
            <span>No okrs available</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default OkrModule;
