import {
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_ERROR,
} from "../../actions/TimeOff/getNotification";

const getNotification = (state = null, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_REQUEST: {
      return true;
    }
    case GET_NOTIFICATION_SUCCESS: {
      return action.response;
    }
    case GET_NOTIFICATION_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getNotification;
