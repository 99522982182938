import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getTimeOffCount } from "../../redux/actions/TimeOff/getTimeOffCount";
import { EllipsisOutlined } from "@ant-design/icons";

const RequestsStatus = () => {
  const dispatch = useDispatch();
  const [pendingTimeOffs, setPendingTimeOffs] = useState("");
  const [approvedTimeOffs, setApprovedTimeOffs] = useState("");
  const [rejectedTimeOffs, setRejectedTimeOffs] = useState("");
  const getTimeOffCountStatus = useSelector((state) => state.getTimeOffCount);

  useEffect(() => {
    dispatch(getTimeOffCount());
  }, [dispatch]);

  useEffect(() => {
    if (getTimeOffCountStatus && getTimeOffCountStatus.status === 200) {
      setPendingTimeOffs(
        getTimeOffCountStatus &&
          getTimeOffCountStatus.data.requested[0]["COUNT(status)"]
      );
      setApprovedTimeOffs(
        getTimeOffCountStatus &&
          getTimeOffCountStatus.data.approved[0]["COUNT(status)"]
      );
      setRejectedTimeOffs(
        getTimeOffCountStatus &&
          getTimeOffCountStatus.data.rejected[0]["COUNT(status)"]
      );
    }
  }, [getTimeOffCountStatus]);

  return (
    <>
      <div className="leavestatus">
        <Row>
          <Col span={24} className="doticonrow">
            <EllipsisOutlined className="doticon" />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <p className="approvedheading">Approved</p>
          </Col>
          <Col span={8}>
            <p className="rejectedheading">Rejected</p>
          </Col>
          <Col span={8}>
            <p className="pendingheading">Pending</p>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <h1 className="approvedcount">{approvedTimeOffs}</h1>
          </Col>
          <Col span={8}>
            <h1 className="rejectedcount">{rejectedTimeOffs}</h1>
          </Col>
          <Col span={8}>
            <h1 className="pendingcount">{pendingTimeOffs}</h1>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RequestsStatus;
