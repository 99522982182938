import {
    GET_TEAMS_SUCCESS,
    GET_TEAMS_REQUEST,
    GET_TEAMS_ERROR,
  } from "../../actions/Team/getTeams";
  
  const getTeams = (state = null, action) => {
    switch (action.type) {
      case GET_TEAMS_REQUEST: {
        return true;
      }
      case GET_TEAMS_SUCCESS: {
        return action.response;
      }
      case GET_TEAMS_ERROR: {
        return action.response;
      }
      default:
        return state;
    }
  };
  
  export default getTeams;
  