import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";

export const USER_SEEN_SUCCESS = "USER_SEEN_SUCCESS";
export const USER_SEEN_ERROR = "USER_SEEN_ERROR";
export const USER_SEEN_REQUEST = "USER_SEEN_REQUEST ";

export const userSeen = () => (dispatch) => {
  const role = localStorage.getItem("currentUserRole");
  const time_off_detail_id = localStorage.getItem("TimeOffDetailId");

  dispatch({ type: USER_SEEN_REQUEST });
  if (role === "owner") {
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_API_BASE}/v1/time_offs/${time_off_detail_id}/owner_seen `,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "access-token": readLocalStorage("access-token"),
      },
    })
      .then((response) => {
        return dispatch({ type: USER_SEEN_SUCCESS, response: response });
      })
      .catch((error) => {
        return dispatch({ type: USER_SEEN_ERROR, response: error.response });
      });
  } else {
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_API_BASE}/v1/time_offs/${time_off_detail_id}/employee_seen`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "access-token": readLocalStorage("access-token"),
      },
    })
      .then((response) => {
        return dispatch({ type: USER_SEEN_SUCCESS, response: response });
      })
      .catch((error) => {
        return dispatch({ type: USER_SEEN_ERROR, response: error.response });
      });
  }
};
