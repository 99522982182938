import {
  GOAL_DELETE_SUCCESS,
  GOAL_DELETE_REQUEST,
  GOAL_DELETE_ERROR,
  GOAL_DELETE_RESET
} from "../../actions/Organization/deleteGoal";

const deleteGoal = (state = null, action) => {
  switch (action.type) {
    case GOAL_DELETE_REQUEST: {
      return true;
    }
    case GOAL_DELETE_SUCCESS: {
      return action.response;
    }
    case GOAL_DELETE_ERROR: {
      return action.response;
    }
    case GOAL_DELETE_RESET: {
      return null;
    }
    default:
      return state;
  }
};

export default deleteGoal;
