import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Form, Input, Button, Select, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createOrganization } from "../../redux/actions/Organization/createOrganization";
import { getOrganization } from "../../redux/actions/Organization/getOrganization";
import { sendInvite } from "../../redux/actions/InviteUser/inviteuser";
import Loader from "../../components/Loader";
import Schema from "async-validator";

import "./style.css";

const OrganizationRegistration = (props) => {
  Schema.warning = function () {};
  const { history } = props;
  const dispatch = useDispatch();
  const organizationStatus = useSelector((state) => state.createOrganization);
  const sendInviteStatus = useSelector((state) => state.sendInvite);
  const [errorState, setErrorState] = useState([{ name: "", description: "" }]);
  const [inviteList, setInviteList] = useState([]);
  const [sendInviteStatusTracker, setSendInviteStatusTracker] = useState(true);

  const onFinish = (values) => {
    if (values) {
      let iniviteArr = [];
      if (values.invite && values.invite.length > 0) {
        for (let i = 0; i < values.invite.length; i++) {
          iniviteArr.push({ email: values.invite[i], role: "employee" });
        }
      }
      if (values.manager && values.manager.length > 0) {
        for (let i = 0; i < values.manager.length; i++) {
          iniviteArr.push({ email: values.manager[i], role: "manager" });
        }
      }
      setInviteList(iniviteArr);
      setErrorState({ error: "" });
      dispatch(createOrganization(values));
    }
  };

  useEffect(() => {
    if (organizationStatus && organizationStatus.status === 201) {
      message.success("organization successfully register");
      localStorage.setItem(
        "organizationName",
        organizationStatus.data.organization_name
      );
      localStorage.setItem("currentUserRole", "owner");
      dispatch(getOrganization());
      history.push("/dashboard");
    }
  }, [organizationStatus, history]);

  useEffect(() => {
    if (organizationStatus && organizationStatus.status === 500) {
      message.success("user already assign to an organization");
      history.push("/organization/details");
    }
  }, [organizationStatus, history]);

  useEffect(() => {
    if (
      organizationStatus &&
      organizationStatus.status === 201 &&
      inviteList.length > 0 &&
      sendInviteStatusTracker === true
    ) {
      for (let i = 0; i < inviteList.length; i++) {
        dispatch(sendInvite(inviteList[i]));
      }
      setSendInviteStatusTracker(false);
    } else if (sendInviteStatus && sendInviteStatus.successStatus === 201) {
      message.success("Invitation send successfully");
      history.push("/homepage");
    }
  }, [
    organizationStatus,
    inviteList,
    sendInviteStatus,
    history,
    sendInviteStatusTracker,
    dispatch,
  ]);

  const { TextArea } = Input;

  return (
    <>
      <h1 className="organization-heading-styling">
        Organization Registration
      </h1>
      <div className="organization-main">
        <div className="org-registration-form-content">
          <Form className="form-align" hideRequiredMark onFinish={onFinish}>
            <Form.Item
              label="Org. Name:"
              name="organizationName"
              rules={[
                {
                  required: true,
                  message: "Please input your project name!",
                },
                {
                  min: 3,
                  message: "Organization name should be atleast 3 character!",
                },
                {
                  max: 25,
                  message:
                    "Organization name should not be greater then 25 character!",
                },
              ]}
            >
              <Input id="organizationName" />
            </Form.Item>

            <Form.Item
              label="Total Time Off"
              name="total_time_off"
              rules={[
                {
                  required: true,
                  message: "Please input Total Time Off",
                },
              ]}
            >
              <Input id="totalTimeOff" />
            </Form.Item>
            {errorState.name ? (
              <div className="err-msg" style={{ textAlign: "center" }}>
                Organization{errorState.name}
              </div>
            ) : (
              ""
            )}
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please input your project description!",
                },
                {
                  min: 3,
                  message: "Project description should be atleast 3 character!",
                },
              ]}
            >
              <TextArea rows={2} id="organizationDescription" />
            </Form.Item>
            {errorState.description ? (
              <span className="err-msg">
                Description{errorState.description}
              </span>
            ) : (
              ""
            )}
            <Form.Item label="Invite Manager" name="manager">
              <Select
                id="organizationManager"
                mode="tags"
                style={{ width: "100%" }}
                placeholder=""
              ></Select>
            </Form.Item>
            <Form.Item label="Invite Employee" name="invite">
              <Select
                id="inviteOrganizationEmployee"
                mode="tags"
                style={{ width: "100%" }}
                placeholder=""
              ></Select>
            </Form.Item>

            <div style={{ marginBottom: "30px", marginTop: "30px" }}></div>
            <Form.Item>
              <div className="register-btn-div">
                <Button
                  id="registerBtn"
                  type="primary"
                  htmlType="submit"
                  className="registerBtn"
                >
                  Register
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
        {organizationStatus === true ? <Loader /> : ""}
      </div>
    </>
  );
};

export default withRouter(OrganizationRegistration);
