import {
  GET_REJECTED_TIME_OFF_SUCCESS,
  GET_REJECTED_TIME_OFF_REQUEST,
  GET_REJECTED_TIME_OFF_ERROR,
} from "../../actions/TimeOff/getRejectedTimeOffs";

const getRejectedTimeOffs = (state = null, action) => {
  switch (action.type) {
    case GET_REJECTED_TIME_OFF_REQUEST: {
      return true;
    }
    case GET_REJECTED_TIME_OFF_SUCCESS: {
      return action.response;
    }
    case GET_REJECTED_TIME_OFF_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getRejectedTimeOffs;
