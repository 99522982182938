import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  message,
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  PlusOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { getWeeklyGoalDetail } from "../../redux/actions/Organization/viewWeeklyGoal";
import { updateWeeklyGoal } from "../../redux/actions/Organization/updateWeeklyGoal";
import { deleteWeeklyGoal } from "../../redux/actions/Organization/deleteWeeklyGoal";
import { useDispatch, useSelector } from "react-redux";
import DatePickerCalender from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCheckin } from "../../redux/actions/Checkin/getCheckin";
import { getGoals } from "../../redux/actions/Organization/goalsList";
import { deleteGoal } from "../../redux/actions/Organization/deleteGoal";
import { updateGoal } from "../../redux/actions/Organization/updateGoal";
import "./style.css";
import { convertUNIXString } from "../../utils/convertUnixTimeStamp";
import moment from "moment";

const WeeklyGoalDetails = (props) => {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const { history, weeklygoalid } = props;
  const [visibleForm, setVisibleForm] = useState(false);
  const [value, setValue] = useState("");
  const [durationStartDate, setDurationStartDate] = useState("");
  const [durationEndDate, setDurationEndDate] = useState("");
  const WeeklyGoalDetail = useSelector((state) => state.WeeklyGoalDetail);
  const WeeklyGoalUpdateStatus = useSelector((state) => state.updateWeeklyGoal);
  const deleteWeeklyGoalStatus = useSelector((state) => state.deleteWeeklyGoal);
  const { confirm } = Modal;

  const getCheckinStatus = useSelector((state) => state.getCheckin);
  const [checkinValue, setCheckinValue] = useState("");

  useEffect(() => {
    if (
      WeeklyGoalDetail &&
      WeeklyGoalDetail.data &&
      WeeklyGoalDetail.data.length > 0
    ) {
      const weeklyGoalId = WeeklyGoalDetail.data[0].id;
      dispatch(getCheckin(weeklyGoalId));
    }
  }, [dispatch, WeeklyGoalDetail]);

  useEffect(() => {
    if (getCheckinStatus && getCheckinStatus.status === 200) {
      setCheckinValue(getCheckinStatus.data);
    }
  }, [getCheckinStatus]);

  useEffect(() => {
    if (WeeklyGoalDetail && WeeklyGoalDetail.status === 200) {
      setValue(WeeklyGoalDetail.data);
    }
  }, [WeeklyGoalDetail]);

  useEffect(() => {
    dispatch(getWeeklyGoalDetail(props.weeklygoalid));
  }, [dispatch, props.weeklygoalid]);

  useEffect(() => {
    if (WeeklyGoalUpdateStatus === 200) {
      window.location.reload();
      dispatch(getWeeklyGoalDetail(props.weeklygoalid));
    }
  }, [WeeklyGoalUpdateStatus, weeklygoalid, dispatch, props.weeklygoalid]);

  useEffect(() => {
    if (WeeklyGoalUpdateStatus && WeeklyGoalUpdateStatus === 403) {
      message.error("You don't have permission to update this goal");
    }
  }, [WeeklyGoalUpdateStatus]);

  useEffect(() => {
    if (deleteWeeklyGoalStatus && deleteWeeklyGoalStatus.status === 204) {
      history.push("/dashboard");
    }
  }, [deleteWeeklyGoalStatus, history]);

  useEffect(() => {
    if (deleteWeeklyGoalStatus && deleteWeeklyGoalStatus === 403) {
      message.error("You don't have permission to delete this goal");
    }
  }, [deleteWeeklyGoalStatus]);

  const onClickDeleteWeeklyGoal = (id) => {
    confirm({
      title: "Do you want to delete this Weekly Goal?",
      onOk() {
        dispatch(deleteWeeklyGoal(id));
      },
      onCancel() {},
    });
  };

  const unixStartDate = value && value[0].startDate;
  const unixEndDate = value && value[0].endDate;

  const formattedStartDate = moment.unix(unixStartDate).format("MM-DD-YYYY");
  const formattedEndDate = moment.unix(unixEndDate).format("MM-DD-YYYY");

  const startDate =
    formattedStartDate && formattedStartDate
      ? new Date(formattedStartDate)
      : null;
  const endDate =
    formattedEndDate && formattedEndDate ? new Date(formattedEndDate) : null;

  const highlightDates = (date) => {
    return (
      (startDate && endDate && date >= startDate && date <= endDate) ||
      (startDate && !endDate && date >= startDate)
    );
  };

  function onChangeMonth(range) {
    setDurationStartDate(moment(range[0]).unix());
    setDurationEndDate(moment(range[1]).unix());
  }

  const showForm = () => {
    setVisibleForm(true);
  };

  const goalsList = useSelector((state) => state.goalsList);
  const deleteGoalStatus = useSelector((state) => state.deleteGoal);
  const updateGoalStatus = useSelector((state) => state.updateGoal);
  const [goalsListState, setGoalsListState] = useState([]);

  useEffect(() => {
    if (goalsList && goalsList.status === 200) {
      setGoalsListState(goalsList.data);
    }
  }, [goalsList]);

  useEffect(() => {
    dispatch(getGoals(weeklygoalid));
  }, [dispatch, weeklygoalid]);

  useEffect(() => {
    if (deleteGoalStatus && deleteGoalStatus === 204) {
      dispatch(getGoals(weeklygoalid));
    } else if (deleteGoalStatus && deleteGoalStatus === 403) {
      message.error("You don't have permission to delete this goal");
    }
  }, [deleteGoalStatus, weeklygoalid, dispatch]);

  useEffect(() => {
    if (updateGoalStatus && updateGoalStatus === 403) {
      message.error("You don't have permission do update this goal");
    } else if (updateGoalStatus && updateGoalStatus === 200) {
      dispatch(getGoals(weeklygoalid));
    }
  }, [updateGoalStatus, weeklygoalid, dispatch]);

  const showConfirmDeleteGoal = (goalId) => {
    confirm({
      title: "Do you want to delete this  Goal?",
      onOk() {
        dispatch(deleteGoal(weeklygoalid, goalId));
      },
      onCancel() {},
    });
  };

  const [form] = Form.useForm();
  const [editingIndices, setEditingIndices] = useState([]);

  const handleEditClick = (goalId) => {
    if (!editingIndices.includes(goalId)) {
      setEditingIndices([...editingIndices, goalId]);
    }
  };

  const onClickCancel = (goalId) => {
    setEditingIndices(editingIndices.filter((id) => id !== goalId));
  };

  const onFinishAllGoals = async () => {
    try {
      const values = await form.validateFields();

      editingIndices.forEach(async (goalId) => {
        await dispatch(
          updateGoal(weeklygoalid, goalId, values[`goal-${goalId}`])
        );
      });

      setEditingIndices([]);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };
  const onClickCancelWeeklyGoal = () => {
    setVisibleForm(false);
  };
  const onFinish = (values) => {
    if (values) {
      const dateRangeModified =
        durationStartDate !== "" && durationEndDate !== "";
      const defaultStartDate = moment(startDate).unix();
      const defaultEndDate = moment(endDate).unix();
      if (!dateRangeModified) {
        setDurationStartDate(defaultStartDate);
        setDurationEndDate(defaultEndDate);
      }
      dispatch(
        updateWeeklyGoal(
          values.description,
          dateRangeModified ? durationStartDate : defaultStartDate,
          dateRangeModified ? durationEndDate : defaultEndDate,
          WeeklyGoalDetail.data[0].id
        )
      );
    }
  };

  const onClickCreateCheckin = () => {
    history.push(`/create_checkin/${WeeklyGoalDetail.data[0].id}`);
  };
  const onClickViewCheckin = () => {
    history.push(`/view_checkin/${WeeklyGoalDetail.data[0].id}`);
  };
  const currentDate = new Date();
  const isEndDatePassed = currentDate > endDate;

  const user_id = localStorage.getItem("user_id");

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <p className="viewWeeklygoals-heading-styling">View Weekly Goals</p>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}></Col>
      </Row>

      <div className="viewWeeklygoalyMain">
        <Form onFinish={onFinish}>
          <p className="weekly-goal-title">Weekly Goal</p>
          <Row>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              {visibleForm && WeeklyGoalDetail && WeeklyGoalDetail.data ? (
                <>
                  <div className="closeWeeklyGoal">
                    <CloseOutlined onClick={onClickCancelWeeklyGoal} />
                  </div>
                  <Form.Item label="Duration" name="duration">
                    <DatePicker.RangePicker
                      onChange={onChangeMonth}
                      style={{ width: "100%" }}
                      defaultValue={[moment(startDate), moment(endDate)]}
                    />
                  </Form.Item>
                  <Form.Item label="Details" name="description">
                    <TextArea
                      rows={2}
                      defaultValue={WeeklyGoalDetail.data[0].description}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      id="updateWeeklyGoalBtn"
                      type="primary"
                      htmlType="submit"
                    >
                      Update Weekly Goal
                    </Button>
                  </Form.Item>
                </>
              ) : null}

              {!visibleForm && WeeklyGoalDetail && WeeklyGoalDetail.data ? (
                <>
                  <div style={{ float: "right" }}>
                    <span style={{ marginLeft: 10 }}>
                      <EditOutlined
                        className="weekly-goal-edit-icon"
                        onClick={showForm}
                      />
                    </span>
                    <DeleteOutlined
                      className="weekly-goal-delete-icon"
                      onClick={() =>
                        onClickDeleteWeeklyGoal(WeeklyGoalDetail.data[0].id)
                      }
                    />
                  </div>
                  <div className="duration-div">
                    <p className="duration-label">Duration:</p>
                    <p className="durations">
                      {convertUNIXString(WeeklyGoalDetail.data[0].startDate) +
                        " - " +
                        convertUNIXString(WeeklyGoalDetail.data[0].endDate)}
                    </p>
                  </div>
                  <div className="duration-div">
                    <p className="details-label">Details:</p>
                    <p className="details">
                      {WeeklyGoalDetail.data[0].description}
                    </p>
                  </div>
                </>
              ) : null}
              <p className="relevant-goals-title"> Goals</p>
              <Form form={form}>
                {goalsListState &&
                  goalsListState.map((item, index) => (
                    <div key={item.id}>
                      {editingIndices.includes(item.id) ? (
                        <>
                          <div className="closeIconDiv">
                            <CloseOutlined
                              onClick={() => onClickCancel(item.id)}
                            />
                          </div>
                          <label htmlFor="">Goal {index + 1}:</label>
                          <Form.Item
                            name={`goal-${item.id}`}
                            rules={[
                              {
                                min: 5,
                                message:
                                  "Goal should be at least 5 characters!",
                              },
                            ]}
                          >
                            <Input
                              type="textarea"
                              defaultValue={item.detail}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </>
                      ) : (
                        <>
                          <Row>
                            <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                              <p className="relevant-goals">
                                Goal {index + 1}: {item.detail}
                              </p>
                            </Col>

                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                              <div className="action-icons">
                                <EditOutlined
                                  className="goal-edit-icon"
                                  onClick={() => handleEditClick(item.id)}
                                />{" "}
                                <DeleteOutlined
                                  onClick={() => showConfirmDeleteGoal(item.id)}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  ))}
                {editingIndices.length > 0 && (
                  <Form.Item>
                    <Button
                      id="updateAllGoalsBtn"
                      type="primary"
                      onClick={onFinishAllGoals}
                    >
                      Update Goals
                    </Button>
                  </Form.Item>
                )}
              </Form>
              <div className="checkin-btn">
                {checkinValue &&
                  checkinValue.length === 0 &&
                  isEndDatePassed &&
                  user_id === String(value[0]?.userId) && (
                    <Button
                      type="outlined"
                      onClick={onClickCreateCheckin}
                      className="checkin-btn-style"
                    >
                      <PlusOutlined /> Create Checkin
                    </Button>
                  )}
                {checkinValue &&
                  checkinValue.length > 0 &&
                  user_id === String(value[0]?.userId) && (
                    <Button
                      type="outlined"
                      onClick={onClickViewCheckin}
                      className="checkin-btn-style"
                    >
                      <EyeOutlined /> View Checkin
                    </Button>
                  )}
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="date-picker-style">
                <DatePickerCalender
                  startDate={startDate}
                  endDate={endDate}
                  monthsShown={1}
                  selected={endDate}
                  selectsRange
                  inline
                  disabledKeyboardNavigation
                  highlightDates={highlightDates}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default withRouter(WeeklyGoalDetails);
