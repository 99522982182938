import {
  ADD_TEAM_MEMBER_SUCCESS,
  ADD_TEAM_MEMBER_REQUEST,
  ADD_TEAM_MEMBER_ERROR,
} from "../../actions/Organization/addTeamMember";

const addTeamMember = (state = null, action) => {
  switch (action.type) {
    case ADD_TEAM_MEMBER_REQUEST: {
      return true;
    }
    case ADD_TEAM_MEMBER_SUCCESS: {
      return action.response;
    }
    case ADD_TEAM_MEMBER_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default addTeamMember;
