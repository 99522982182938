import {
  USER_LOGIN_SUCCESS,
  USER_LOGIN_REQUEST,
  USER_LOGIN_ERROR,
} from "../../actions/User/login";

const userLogin = (state = null, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST: {
      return true;
    }
    case USER_LOGIN_SUCCESS: {
      return { ...state, userlogin: action.response };
    }
    case USER_LOGIN_ERROR: {
      return { ...state, userlogin: action.response };
    }
    default:
      return state;
  }
};

export default userLogin;
