import { Button, Form, Rate, Input } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getWeeklyGoals } from "../../redux/actions/Organization/weeklyGoalsList";
import { createCheckin } from "../../redux/actions/Checkins/createCheckin";
import { getProfileViewDetails } from "../../redux/actions/ProfileView/getProfileViewDetails";
import { convertUNIXString } from "../../utils/convertUnixTimeStamp";
import "./style.css";
import { useHistory } from "react-router-dom";

const CreateCheckIn = () => {
  const history = useHistory();
  const { id } = useParams();
  const goals = useSelector((state) => state.weeklyGoalList);
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState("");
  const [matchingWeeklyGoal, setMatchingWeeklyGoal] = useState(null);
  const user_id = localStorage.getItem("user_id");
  const getProfileViewDetailsStatus = useSelector(
    (state) => state.getProfileViewDetails
  );

  const matchingGoal =
    goals &&
    goals.data &&
    goals.data.find((goal) => goal.id === parseInt(id, 10));

  useEffect(() => {
    if (matchingGoal && !matchingWeeklyGoal) {
      setMatchingWeeklyGoal(matchingGoal);
    }
  }, [matchingGoal, matchingWeeklyGoal]);

  useEffect(() => {
    dispatch(getWeeklyGoals());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProfileViewDetails(user_id));
  }, [dispatch, user_id]);

  useEffect(() => {
    if (
      getProfileViewDetailsStatus &&
      getProfileViewDetailsStatus.status === 200
    ) {
      setUserDetails(getProfileViewDetailsStatus.data[0]);
    }
  }, [getProfileViewDetailsStatus]);
  const [rateValue, setRateValue] = useState("");
  const createCheckinStatus = useSelector((state) => state.createCheckin);
  const handleChange = (rateValue) => {
    setRateValue(rateValue);
  };
  useEffect(() => {
    if (createCheckinStatus === true) {
      history.push("/dashboard");
    }
  }, [createCheckinStatus, history]);
  const onFinish = (values) => {
    dispatch(createCheckin(`${id}`, 1, values.rateValue));
    dispatch(createCheckin(`${id}`, 2, values.prioritiesAnswer));
    dispatch(createCheckin(`${id}`, 3, values.blockersAnswer));
    dispatch(createCheckin(`${id}`, 4, values.sharingAnswer));
  };
  return (
    <>
      <p className="create-checkin-heading">Create Checkin</p>
      <div className="create-checkin-main">
        <div className="check-in-form">
          <div className="weekly-goal-info">
            <div className="user-content">
              <img
                src={userDetails && userDetails.image}
                alt=""
                className="checkin-user-img"
              />
              <div className="user-info">
                <p className="checkin-user-name">
                  {userDetails && userDetails.first_name}{" "}
                  {userDetails && userDetails.last_name}
                </p>
                <p className="checkin-user-designation">
                  {userDetails && userDetails.designation}
                </p>
              </div>
            </div>
            <p className="checkin-weekly-goal-duration">
              {convertUNIXString(
                matchingWeeklyGoal && matchingWeeklyGoal.startDate
              )}
              {" to "}
              {convertUNIXString(
                matchingWeeklyGoal && matchingWeeklyGoal.endDate
              )}
            </p>
          </div>
          <Form onFinish={onFinish}>
            <p className="awfulRating">How are you feeling today?</p>
            <div className="checkin-rating-question">
              <p className="awful">Awful</p>

              <Form.Item
                name="rateValue"
                rules={[
                  {
                    required: true,
                    message: "Please input your answer!",
                  },
                ]}
              >
                <Rate
                  onChange={handleChange}
                  value={rateValue}
                  className="ratings"
                />
              </Form.Item>

              <p className="great">Great</p>
            </div>
            <p className="prioritiesQuestion">
              What are the most imprortant priorities in the coming week?
            </p>
            <Form.Item
              name="prioritiesAnswer"
              rules={[
                {
                  required: true,
                  message: "Please input your answer!",
                },
              ]}
            >
              <Input placeholder="Answer" type="text" />
            </Form.Item>
            <p className="blockersQuestion">
              Did you have any blockers this week?
            </p>

            <Form.Item
              name="blockersAnswer"
              rules={[
                {
                  required: true,
                  message: "Please input your answer!",
                },
              ]}
            >
              <Input placeholder="Answer" />
            </Form.Item>
            <p className="sharingQuestion">
              What do you want to share with others? (if anything)
            </p>

            <Form.Item
              name="sharingAnswer"
              rules={[
                {
                  required: true,
                  message: "Please input your answer!",
                },
              ]}
            >
              <Input placeholder="Answer" type="text" />
            </Form.Item>
            <Form.Item>
              <div className="submit-checkin-div">
                <Button
                  className="submit-checkin-btn"
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default CreateCheckIn;
