import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";

export const CREATE_HIGH_FIVE_LIKE_SUCCESS = "CREATE_HIGH_FIVE_LIKE_SUCCESS";
export const CREATE_HIGH_FIVE_LIKE_CHEKIN_ERROR =
  "CREATE_HIGH_FIVE_LIKE_CHEKIN_ERROR";
export const CREATE_HIGH_FIVE_LIKE_CHEKIN_REQUEST =
  "CREATE_HIGH_FIVE_LIKE_CHEKIN_REQUEST ";

export const createHighFiveLike = (id) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");
  dispatch({ type: CREATE_HIGH_FIVE_LIKE_CHEKIN_REQUEST });
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/high_fives/${id}/high_five_likes`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      return dispatch({
        type: CREATE_HIGH_FIVE_LIKE_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      return dispatch({
        type: CREATE_HIGH_FIVE_LIKE_CHEKIN_ERROR,
        response: error.response,
      });
    });
};
