import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getEmployees } from "../../redux/actions/User/getEmployees";
import { getOwner } from "../../redux/actions/User/getOwner";
import { getManagers } from "../../redux/actions/User/getManagers";
import { getOrganization } from "../../redux/actions/Organization/getOrganization";

const OrganizationUsers = () => {
  const dispatch = useDispatch();
  const [managersList, setManagersList] = useState([""]);
  const [employeesList, setEmployeesList] = useState([""]);
  const [getOwnerValue, setGetOwnerValue] = useState([""]);
  const [getOrganizationList, setOrganizationList] = useState([""]);
  const getEmployeesList = useSelector((state) => state.getEmployees);
  const getOwnerStatus = useSelector((state) => state.getOwner);
  const getManagersList = useSelector((state) => state.getManager);
  const getOrganizationStatus = useSelector((state) => state.getOrganization);

  const user_id = localStorage.getItem("user_id");
  useEffect(() => {
    dispatch(getEmployees(user_id));
  }, [dispatch, user_id]);
  useEffect(() => {
    dispatch(getOwner(user_id));
  }, [dispatch, user_id]);

  useEffect(() => {
    dispatch(getManagers(user_id));
  }, [dispatch, user_id]);

  useEffect(() => {
    dispatch(getOrganization(user_id));
  }, [dispatch, user_id]);

  useEffect(() => {
    if (getOrganizationStatus && getOrganizationStatus.status === 200) {
      setOrganizationList(getOrganizationStatus && getOrganizationStatus.data);
    }
  }, [getOrganizationStatus]);

  useEffect(() => {
    if (getOwnerStatus && getOwnerStatus.status === 200) {
      setGetOwnerValue(getOwnerStatus && getOwnerStatus.data);
    }
  }, [getOwnerStatus]);

  useEffect(() => {
    if (getEmployeesList && getEmployeesList.status === 200) {
      setEmployeesList(getEmployeesList.data);
    }
  }, [getEmployeesList]);

  useEffect(() => {
    if (getManagersList && getManagersList.status === 200) {
      setManagersList(getManagersList.data);
    }
  }, [getManagersList]);

  return (
    <>
      <div className="organizationdetails">
        <Row>
          <Col span={24}>
            <div className="detailsheadingsection">
              <p className="organizationdetailsheading">Organization Details</p>
              <h1 className="orgmanagername">
                Name:{" "}
                {getOrganizationList &&
                  getOrganizationList[0].organization_name}
              </h1>
              <h1 className="orgmanagername">
                Description:{" "}
                {getOrganizationList && getOrganizationList[0].description}
              </h1>
            </div>
          </Col>
        </Row>

        <div className="detailscontentsection">
          <Row>
            <Col span={16}>
              {managersList ? (
                <span>
                  {managersList.map((manager, uniId) => (
                    <div className="candidateNameDiv" key={uniId}>
                      <p className="orgmanagername">
                        {manager.first_name + " " + manager.last_name}
                      </p>
                    </div>
                  ))}
                </span>
              ) : (
                <span>Manager name</span>
              )}
            </Col>
            <Col span={8}>
              {managersList ? (
                <span>
                  {managersList.map((manager, uniId) => (
                    <div className="candidatePostDiv" key={uniId}>
                      <p className="managerpost">Manager</p>
                    </div>
                  ))}
                </span>
              ) : (
                <span>Manager name</span>
              )}
            </Col>
          </Row>

          <Row>
            <Col span={16}>
              {getOwnerValue ? (
                <span>
                  {getOwnerValue.map((owner, uniId) => (
                    <div className="candidateNameDiv" key={uniId}>
                      <p className="orgmanagername">
                        {owner.first_name + " " + owner.last_name}
                      </p>
                    </div>
                  ))}
                </span>
              ) : (
                <span>Owner name</span>
              )}
            </Col>
            <Col span={8}>
              {getOwnerValue ? (
                <span>
                  {getOwnerValue.map((owner, uniId) => (
                    <div className="candidatePostDiv" key={uniId}>
                      <p className="managerpost">Owner</p>
                    </div>
                  ))}
                </span>
              ) : (
                <span>Owner name</span>
              )}
            </Col>
          </Row>

          <Row>
            <Col span={16}>
              {employeesList ? (
                <span>
                  {employeesList.map((employee, uniId) => (
                    <div className="candidateNameDiv" key={uniId}>
                      <p className="orgemployeename">
                        {employee.first_name + " " + employee.last_name}
                      </p>
                    </div>
                  ))}
                </span>
              ) : (
                <span>Employee name</span>
              )}
            </Col>
            <Col span={8}>
              {employeesList ? (
                <span>
                  {employeesList.map((employee, uniId) => (
                    <div className="candidatePostDiv" key={uniId}>
                      <p className="employeepost">Employee</p>
                    </div>
                  ))}
                </span>
              ) : (
                <span>Employee name</span>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default OrganizationUsers;
