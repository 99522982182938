import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { MoreOutlined, EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import userDummy from "../../assets/user.png";
import ratingOneImage from "../../assets/ratingOne.png";
import ratingTwoImage from "../../assets/ratingTwo.png";
import ratingThreeImage from "../../assets/ratingThree.png";
import ratingFourImage from "../../assets/ratingFour.png";
import ratingFiveImage from "../../assets/ratingFive.png";
import { getUsers } from "../../redux/actions/User/getUsers";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getPerformanceReview } from "../../redux/actions/PerformanceReview/getPerformanceReview";
import { getCheckins } from "../../redux/actions/Checkin/getCheckins";
import { getTeams } from "../../redux/actions/Team/getTeams";
import { PDFDocument, rgb } from "pdf-lib";
import "./style.css";

const EmployeesModule = () => {
  const history = useHistory();
  const getTeamsStatus = useSelector((state) => state.getTeams);
  const dispatch = useDispatch();
  const [teamList, setTeamList] = useState();
  const [UsersList, setUsersList] = useState([]);
  const getUsersList = useSelector((state) => state.getUsers);
  const getCheckinsStatus = useSelector((state) => state.getCheckins);
  const [checkinsValue, setCheckinsValue] = useState([]);
  const [review, setReview] = useState([""]);
  const getPerformanceReviewStatus = useSelector(
    (state) => state.getPerformanceReview
  );
  useEffect(() => {
    if (
      getPerformanceReviewStatus &&
      getPerformanceReviewStatus.status === 200
    ) {
      setReview(getPerformanceReviewStatus.data);
    }
  }, [getPerformanceReviewStatus]);
  useEffect(() => {
    dispatch(getPerformanceReview());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getTeams());
  }, [dispatch]);

  const onClickEmployee = (id) => {
    history.push(`/profile/${id}`);
  };

  useEffect(() => {
    if (getUsersList && getUsersList.status === 200) {
      setUsersList(getUsersList.data);
    }
  }, [getUsersList]);

  useEffect(() => {
    if (getTeamsStatus && getTeamsStatus.status === 200) {
      setTeamList(getTeamsStatus.data);
    }
  }, [getTeamsStatus]);

  const generatePDF = async (
    firstName,
    lastName,
    designation,
    image,
    contact,
    teamTitles,
    userId
  ) => {
    try {
      // Call the API to fetch check-ins
      const response = await dispatch(getCheckins(userId));
      const pdfDoc = await PDFDocument.create();
      const page = pdfDoc.addPage();

      const pageWidth = page.getSize().width;
      const pageHeight = page.getSize().height;

      const hexToRgb = (hex) => {
        hex = hex.replace("#", "");
        const r = parseInt(hex.substring(0, 2), 16) / 255;
        const g = parseInt(hex.substring(2, 4), 16) / 255;
        const b = parseInt(hex.substring(4, 6), 16) / 255;
        return [r, g, b];
      };

      const backgroundColor = rgb(...hexToRgb("#5AADE1"));

      const backgroundWidth = pageWidth * 0.1;
      page.drawRectangle({
        x: 0,
        y: 0,
        width: backgroundWidth,
        height: pageHeight,
        color: backgroundColor,
      });
      page.drawRectangle({
        x: pageWidth - backgroundWidth,
        y: 0,
        width: backgroundWidth,
        height: pageHeight,
        color: backgroundColor,
      });

      const contentWidth = pageWidth * 0.8;
      const contentX = (pageWidth - contentWidth) / 2;

      const fontSize = 24;
      const smallerFontSize = 16;
      const mediumFontSize = 20;
      const boldFont = await pdfDoc.embedFont("Helvetica-Bold");
      const regularFont = await pdfDoc.embedFont("Helvetica");
      const fontColor = rgb(0.2, 0.5, 0.8);
      const blackColor = rgb(0, 0, 0);

      const textWidth = boldFont.widthOfTextAtSize("Simple Deploy", fontSize);

      const textX = (page.getSize().width - textWidth) / 2;
      const textY = 800 - fontSize - 10;
      page.drawText("Simple Deploy", {
        x: textX,
        y: textY,
        size: fontSize,
        font: boldFont,
        color: fontColor,
      });

      const userImage = await pdfDoc.embedPng(image || userDummy);
      const userImageWidth = 100;
      const userImageHeight = 100;
      const userImageX = (page.getSize().width - userImageWidth) / 2;
      const userImageY = 780 - userImageHeight - 50;
      page.drawImage(userImage, {
        x: userImageX,
        y: userImageY,
        width: userImageWidth,
        height: userImageHeight,
      });

      const userName = `${firstName} ${lastName}`;
      const userNameWidth = boldFont.widthOfTextAtSize(
        userName,
        mediumFontSize
      );

      const userNameX = (page.getSize().width - userNameWidth) / 2;
      const userNameY = userImageY - mediumFontSize - 10;
      page.drawText(userName, {
        x: userNameX,
        y: userNameY,
        size: mediumFontSize,
        font: boldFont,
        color: fontColor,
      });
      const userDesignation = `${designation}`;
      const userDesignationWidth = regularFont.widthOfTextAtSize(
        userDesignation,
        smallerFontSize
      );

      const userDesignationX =
        (page.getSize().width - userDesignationWidth) / 2;
      const userDesignationY = userNameY - mediumFontSize - 10;

      page.drawText(userDesignation, {
        x: userDesignationX,
        y: userDesignationY,
        size: smallerFontSize,
        font: regularFont,
        color: blackColor,
      });

      let userContact = "";
      userContact = `${contact}`;

      const userContactWidth = regularFont.widthOfTextAtSize(
        userContact,
        smallerFontSize
      );

      const userContactX = (page.getSize().width - userContactWidth) / 2;

      const contactPaddingTop = 20;
      const contactPaddingBottom = 20;
      const userContactY =
        userNameY - mediumFontSize - contactPaddingTop - contactPaddingBottom;

      page.drawText(userContact, {
        x: userContactX,
        y: userContactY,
        size: smallerFontSize,
        font: regularFont,
        color: blackColor,
      });

      const userReviews = review.filter((rev) => rev.user_id === userId);
      let latestReview = null;
      if (userReviews.length > 0) {
        latestReview = userReviews[userReviews.length - 1];
        const startDate = new Date(latestReview.start_date * 1000);
        const endDate = new Date(latestReview.end_date * 1000);
        const formattedStartDate = startDate.toLocaleDateString();
        const formattedEndDate = endDate.toLocaleDateString();
      }

      let getMoodValue = "";

      const userCheckins = response.response.data;
      if (userCheckins.length > 0) {
        const latestCheckin = userCheckins
          .reverse()
          .find((checkin) => checkin.sort_id === 1);
        getMoodValue = latestCheckin ? latestCheckin.answer : "";
      }

      const moodValueText = "Mood Value: ";
      const moodValue = `${getMoodValue}`;
      const moodWidth = regularFont.widthOfTextAtSize(
        moodValueText + moodValue,
        smallerFontSize
      );

      const moodX = (page.getSize().width - moodWidth) / 2;
      const moodPaddingTop = 15;
      const moodY = 540 - smallerFontSize - moodPaddingTop;

      page.drawText(moodValueText + moodValue, {
        x: moodX,
        y: moodY,
        size: smallerFontSize,
        font: regularFont,
        color: blackColor,
      });

      let formattedStartDate = "";
      let formattedEndDate = "";

      if (userReviews.length > 0) {
        const latestReview = userReviews[userReviews.length - 1];
        const startDate = new Date(latestReview.start_date * 1000);
        const endDate = new Date(latestReview.end_date * 1000);
        formattedStartDate = startDate.toLocaleDateString();
        formattedEndDate = endDate.toLocaleDateString();

        const reviewPeriodHeading = "Review Period ";

        const reviewPeriodHeadingWidth = regularFont.widthOfTextAtSize(
          reviewPeriodHeading,
          smallerFontSize
        );

        const reviewPeriodHeadingXCenter =
          (page.getSize().width - reviewPeriodHeadingWidth) / 2;

        page.drawText(reviewPeriodHeading, {
          x: reviewPeriodHeadingXCenter,
          y: 500 - mediumFontSize - 10,
          size: smallerFontSize,
          font: regularFont,
          color: blackColor,
        });
      }

      const reviewPeriodValue = `${formattedStartDate} to ${formattedEndDate}`;

      const reviewPeriodValueWidth = regularFont.widthOfTextAtSize(
        reviewPeriodValue,
        smallerFontSize
      );

      const reviewPeriodValueXCenter =
        (page.getSize().width - reviewPeriodValueWidth) / 2;

      page.drawText(reviewPeriodValue, {
        x: reviewPeriodValueXCenter,
        y: 460 - mediumFontSize - 10,
        size: smallerFontSize,
        font: regularFont,
        color: blackColor,
      });

      const latestRatingText = "Latest Rating: ";

      let emoji = "";
      if (latestReview) {
        const ratingValue = latestReview.avg_rating;
        switch (ratingValue) {
          case 1:
            emoji = await pdfDoc.embedPng(ratingOneImage);
            break;
          case 2:
            emoji = await pdfDoc.embedPng(ratingTwoImage);
            break;
          case 3:
            emoji = await pdfDoc.embedPng(ratingThreeImage);
            break;
          case 4:
            emoji = await pdfDoc.embedPng(ratingFourImage);
            break;
          case 5:
            emoji = await pdfDoc.embedPng(ratingFiveImage);
            break;
          default:
            break;
        }
      }

      const latestRatingX =
        (page.getSize().width -
          regularFont.widthOfTextAtSize(latestRatingText, smallerFontSize)) /
        2;

      page.drawText(latestRatingText, {
        x: latestRatingX,
        y: 400,
        size: smallerFontSize,
        font: regularFont,
        color: blackColor,
      });
      if (emoji) {
        const imageWidth = 15;
        const imageHeight = 15;
        const imageX =
          latestRatingX +
          regularFont.widthOfTextAtSize(latestRatingText, smallerFontSize);
        const imageY = 397;

        page.drawImage(emoji, {
          x: imageX,
          y: imageY,
          width: imageWidth,
          height: imageHeight,
        });
      } else {
        const latestRatingValue = `${
          latestReview ? latestReview.avg_rating : "N/A"
        }`;

        const latestRatingWidth = regularFont.widthOfTextAtSize(
          latestRatingText + latestRatingValue,
          smallerFontSize
        );

        const latestRatingValueX =
          latestRatingX +
          regularFont.widthOfTextAtSize(latestRatingText, smallerFontSize);

        page.drawText(latestRatingValue, {
          x: latestRatingValueX,
          y: 400,
          size: smallerFontSize,
          font: regularFont,
          color: blackColor,
        });
      }

      const teamsHeading = "Teams";

      const teamsHeadingWidth = regularFont.widthOfTextAtSize(
        teamsHeading,
        smallerFontSize
      );

      const teamsHeadingXCenter =
        (page.getSize().width - teamsHeadingWidth) / 2;

      page.drawText(teamsHeading, {
        x: teamsHeadingXCenter,
        y: 370,
        size: smallerFontSize,
        font: regularFont,
        color: blackColor,
      });

      const teamTitlesValue = Array.isArray(teamTitles)
        ? teamTitles.join(", ")
        : teamTitles;

      const teamTitlesWidth = regularFont.widthOfTextAtSize(
        teamTitlesValue,
        smallerFontSize
      );

      const teamTitlesXCenter = (page.getSize().width - teamTitlesWidth) / 2;

      page.drawText(teamTitlesValue, {
        x: teamTitlesXCenter,
        y: 340,
        size: smallerFontSize,
        font: regularFont,
        color: blackColor,
      });

      const pdfBytes = await pdfDoc.save();

      const blob = new Blob([pdfBytes], { type: "application/pdf" });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "profile.pdf";
      link.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  useEffect(() => {
    if (getCheckinsStatus && getCheckinsStatus.status === 200) {
      setCheckinsValue(getCheckinsStatus.data);
    }
  }, [getCheckinsStatus]);

  return (
    <div className="weekly-goals-module-style">
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <p className="weekly-goals-heading">Employees</p>
        </Col>
        <Col className="gutter-row" span={12}>
          <div className="dots-div">
            <MoreOutlined className="menu-icon-style" />
          </div>
        </Col>
      </Row>
      <div className="details-div">
        {UsersList.map((employee, index) => (
          <div className="goal-details-div" key={index}>
            <Row gutter={16}>
              <Col className="gutter-row" xs={3} sm={3} md={4} lg={5} xl={3}>
                <img
                  src={employee.image || userDummy}
                  alt="employee"
                  className="user-dummy-image-style"
                />
              </Col>
              <Col
                className="gutter-row"
                xs={12}
                sm={12}
                md={12}
                lg={11}
                xl={12}
                onClick={() => onClickEmployee(employee.id)}
                style={{ cursor: "pointer" }}
              >
                <p className="weekly-goals-duration">
                  {employee.first_name + " " + employee.last_name}
                </p>
                <p className="weekly-goal-assignee">{employee.designation}</p>
              </Col>
              <Col className="gutter-row" xs={9} sm={9} md={8} lg={8} xl={9}>
                <div className="employees-action-div">
                  <div className="eye-icon-div">
                    <p className="employee-view-text">view</p>
                    <div
                      className="view-icon-div"
                      onClick={() => onClickEmployee(employee.id)}
                    >
                      <EyeOutlined className="eye-icon-style" />
                    </div>
                  </div>
                  <div className="eye-icon-div">
                    <p className="view-text">Download</p>
                    <div
                      className="download-icon-div"
                      onClick={() => {
                        const userTeams = teamList.filter(
                          (team) => team.user_id === employee.id
                        );
                        const userTeamTitles = userTeams
                          .map((team) => team.title)
                          .join(", ");
                        generatePDF(
                          employee.first_name,
                          employee.last_name,
                          employee.designation,
                          employee.image || userDummy,
                          employee.contact,
                          userTeamTitles,
                          employee.id
                        );
                      }}
                    >
                      <DownloadOutlined className="download-icon-style" />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmployeesModule;
