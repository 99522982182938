import {
  GET_MOST_APPRECIATED_SUCCESS,
  GET_MOST_APPRECIATED_REQUEST,
  GET_MOST_APPRECIATED_ERROR,
} from "../../actions/HighFive/getMostAppreciated";

const getMostAppreciated = (state = null, action) => {
  switch (action.type) {
    case GET_MOST_APPRECIATED_REQUEST: {
      return true;
    }
    case GET_MOST_APPRECIATED_SUCCESS: {
      return action.response;
    }
    case GET_MOST_APPRECIATED_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getMostAppreciated;
