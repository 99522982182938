import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getMostAppreciated } from "../../redux/actions/HighFive/getMostAppreciated";

const MostAppreciatedHighFive = () => {
  const dispatch = useDispatch();
  const [MostAppreciated, setMostAppreciated] = useState();
  const getMostAppreciatedStatus = useSelector(
    (state) => state.getMostAppreciated
  );
  useEffect(() => {
    if (getMostAppreciatedStatus && getMostAppreciatedStatus.status === 200) {
      setMostAppreciated(getMostAppreciatedStatus.data);
    }
  }, [getMostAppreciatedStatus]);
  useEffect(() => {
    dispatch(getMostAppreciated());
  }, [dispatch]);

  return (
    <>
      <div className="mostAppreciatedSection">
        <p className="mostAppreciatedTitle">Most Appreciated</p>
        <div className="appreciatedScrollBar">
          {MostAppreciated &&
            MostAppreciated.map((value) => (
              <>
                {value.received_high_five !== 0 ? (
                  <Row className="mostAppreciatedRowStyle">
                    <Col span="1"></Col>
                    <Col span="5">
                      <img
                        className="mostAppreciatedUserImg"
                        src={value && value.image}
                        alt=""
                      />
                    </Col>
                    <Col span="12">
                      <p className="mostAppreciatedUserName">
                        {value.first_name} {value.last_name}
                      </p>
                    </Col>
                    <Col span="6">
                      <p className="mostAppreciatedSentHighFive">
                        {value.received_high_five}
                      </p>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </>
            ))}
        </div>
      </div>
    </>
  );
};

export default MostAppreciatedHighFive;
