import {
  GET_TEAM_OBJECTIVE_SUCCESS,
  GET_TEAM_OBJECTIVE_REQUEST,
  GET_TEAM_OBJECTIVE_ERROR,
} from "../../actions/Okr/getTeamObjective";

const getTeamObjective = (state = null, action) => {
  switch (action.type) {
    case GET_TEAM_OBJECTIVE_REQUEST: {
      return true;
    }
    case GET_TEAM_OBJECTIVE_SUCCESS: {
      return action.response;
    }
    case GET_TEAM_OBJECTIVE_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getTeamObjective;
