import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";

export const FILTER_SUCCESS = "FILTER_SUCCESS";
export const FILTER_ERROR = "FILTER_ERROR";
export const FILTER_REQUEST = "FILTER_REQUEST ";
export const filterTimeOff = (value) => (dispatch) => {
  let url =
    value === ""
      ? `${process.env.REACT_APP_BACKEND_API_BASE}/v1/time_offs`
      : `${process.env.REACT_APP_BACKEND_API_BASE}/v1/time_offs?status=${value}`;
  dispatch({ type: FILTER_REQUEST });
  return axios({
    method: "get",
    url: url,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  }).then((response) => {
    if (value === "requested") {
      localStorage.setItem("requested", response.data.count);
      localStorage.setItem("leave_type_status", "requested");
    }
    if (value === "approved") {
      localStorage.setItem("approved", response.data.count);
      localStorage.setItem("leave_type_status", "approved");
    }
    if (value === "rejected") {
      localStorage.setItem("rejected", response.data.count);
      localStorage.setItem("leave_type_status", "rejected");
    }
    return dispatch({ type: FILTER_SUCCESS, response: response });
  });
  // .catch((error) => {
  //   return dispatch({ type: FILTER_ERROR, response: error.response });
  // });
};
