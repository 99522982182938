import {
    GET_TEAM_SUCCESS,
    GET_TEAM_REQUEST,
    GET_TEAM_ERROR,
  } from "../../actions/Team/getTeam";
  
  const getTeam = (state = null, action) => {
    switch (action.type) {
      case GET_TEAM_REQUEST: {
        return true;
      }
      case GET_TEAM_SUCCESS: {
        return action.response;
      }
      case GET_TEAM_ERROR: {
        return action.response;
      }
      default:
        return state;
    }
  };
  
  export default getTeam;
  