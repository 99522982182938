import {
  UPDATE_PROFILE_VIEW_DETAILS_SUCCESS,
  UPDATE_PROFILE_VIEW_DETAILS_REQUEST,
  UPDATE_PROFILE_VIEW_DETAILS_ERROR,
} from "../../actions/ProfileView/updateProfileViewDetails";

const updateProfileView = (state = null, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_VIEW_DETAILS_REQUEST: {
      return true;
    }
    case UPDATE_PROFILE_VIEW_DETAILS_SUCCESS: {
      return action.response;
    }
    case UPDATE_PROFILE_VIEW_DETAILS_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default updateProfileView;
