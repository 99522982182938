import React, { useEffect, useState } from "react";
import { readLocalStorage } from "../utils/readLocalStorage";
import {
  Route,
  Redirect,
  useHistory,
  Link,
  useLocation,
  Switch,
} from "react-router-dom";
import { Layout, Menu, Button } from "antd";
import {
  AlignLeftOutlined,
  WechatFilled,
  DownCircleOutlined,
} from "@ant-design/icons";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import SignIn from "../screens/Signin";
import OrganizationRegistration from "../screens/OrganizationRegistration";
import { getProfileViewDetails } from "../redux/actions/ProfileView/getProfileViewDetails";
import DashboardContent from "../screens/FeedbackDashboard/Dashboard";
import dashboardImage from "../assets/dashboard.svg";
import employeeImage from "../assets/employee.svg";
import performanceImage from "../assets/performance.svg";
import bookImage from "../assets/book.svg";
import OrganizationDetail from "../screens/OrganizationDetail";
import AddWeeklyGoals from "../screens/AddWeeklyGoals";
import ViewWeeklyGoal from "../screens/ViewWeeklyGoal";
import logoutImage from "../assets/logout.svg";
import TimeoffRequest from "../screens/TimeoffRequest";
import WeeklyGoalListing from "../screens/WeeklyGoalListing";
import PageNotFound from "../screens/PageNotFound/PageNotFound";
import "antd/dist/antd.css";
import CompanyChart from "../screens/CompanyChart";
import UserMoodChart from "../screens/UserMoodChart";
import CreatePerformanceReview from "../screens/CreatePerformanceReview/CreatePerformanceReview";
import CreateTeam from "../screens/CreateTeam";
// import SearchSelect from "../screens/FeedbackDashboard/SearchSelect";
import TimeoffDashboard from "../screens/TimeoffDashboard";
import TeamListing from "../screens/TeamListing";
import OkrListings from "../screens/OkrListings";
import UpdateOkr from "../screens/UpdateOkr";
import CreateHighFive from "../screens/CreateHighFive";
import OkrsPage from "../screens/OKRS/Okrs";
import ViewCheckIn from "../screens/ViewCheckIn";
import ProfileView from "../screens/ProfileView/ProfileView";
import ChangePassword from "../screens/ChangePassword/ChangePassword";
import OrganizationHierarchy from "../screens/OrganizationHierarchy";
import CreateCheckIn from "../screens/CreateCheckIn/CreateCheckin";
import { getOrganization } from "../redux/actions/Organization/getOrganization";
import organizationIcon from "../assets/organization.png";
import ViewPerformanceReview from "../screens/ViewPerformanceReview";
import teamIcon from "../assets/team.png";
import userDummy from "../assets/user.png";
import TeamDetail from "../screens/TeamDetail";
import TeamUpdate from "../screens/TeamUpdate";
import TimeoffDetail from "../screens/TimeoffDetail";
import TimeoffRequestListing from "../screens/TimeoffRequestListing";
import EmployeeProfile from "../screens/EmployeeProfile/EmployeeProfile";
import PerformanceReviewNew from "../screens/PerformanceReviewNew";
import PerformanceReviewListing from "../screens/PerformanceReviewListing/PerformanceReviewListing";
import ViewHighFive from "../screens/ViewHighFive/ViewHighFive";
import HighFivesListing from "../screens/HighFivesListing/HighFivesListing";
import HighFiveDashboard from "../screens/HighFiveDashboard";
import Chat from "../screens/ChatGpt/chat";

const { Header, Sider, Content } = Layout;

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  function getCookie(otp) {
    let name = otp + "=";
    let spli = document.cookie.split(";");
    for (var j = 0; j < spli.length; j++) {
      let char = spli[j];
      while (char.charAt(0) === " ") {
        char = char.substring(1);
      }
      if (char.indexOf(name) === 0) {
        return char.substring(name.length, char.length);
      }
    }
    return "";
  }
  const dispatch = useDispatch();
  const getOrganizationStatus = useSelector((state) => state.getOrganization);
  const [organizationDetails, setOrganizationDetails] = useState();
  useEffect(() => {
    dispatch(getOrganization());
  }, [dispatch]);

  useEffect(() => {
    if (getOrganizationStatus && getOrganizationStatus.status === 200) {
      setOrganizationDetails(getOrganizationStatus.data[0]);
    }
  }, [getOrganizationStatus]);
  const [isChatVisible, setIsChatVisible] = useState(false);

  const toggleChatVisibility = () => {
    setIsChatVisible(!isChatVisible);
  };
  const [collapsed, setCollapsed] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const id = localStorage.getItem("user_id");
  const getProfileViewDetailsStatus = useSelector(
    (state) => state.getProfileViewDetails
  );
  useEffect(() => {
    dispatch(getProfileViewDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (
      getProfileViewDetailsStatus &&
      getProfileViewDetailsStatus.status === 200
    ) {
      setUserDetails(getProfileViewDetailsStatus.data[0]);
    }
  }, [getProfileViewDetailsStatus]);
  const history = useHistory();
  const handleLogoutClick = () => {
    localStorage.clear();
    history.push("/login");
  };
  const onClickUserProfile = () => {
    history.push("/profile");
  };
  const location = useLocation();
  const verifiedOtp = localStorage.getItem("verifiedOtp");

  return (
    <Route
      {...rest}
      render={(props) => {
        if (verifiedOtp && readLocalStorage("access-token")) {
          return (
            <>
              <Layout>
                <Sider
                  trigger={null}
                  collapsible
                  collapsed={collapsed}
                  className="sider"
                  width="270px"
                  style={{
                    padding: "5px",
                    height: "100vh",
                    position: "fixed",
                    left: 0,
                    top: 0,
                  }}
                >
                  <div
                    className="dashboard-user-data"
                    onClick={onClickUserProfile}
                  >
                    <img
                      id="roundedProfileImg"
                      src={(userDetails && userDetails.image) || userDummy}
                      className="dashboard-user-image"
                      alt="dummy"
                      style={{
                        width: collapsed ? "70px" : "70px",
                        marginBottom: collapsed ? "20px" : "5px",
                        height: collapsed ? "70px" : "70px",
                      }}
                    />
                    {!collapsed && (
                      <div className="user-description">
                        <p className="dashboard-user-name">
                          {userDetails && userDetails.first_name}
                          {""} {userDetails && userDetails.last_name}
                        </p>
                        <p className="dashboard-user-designation">
                          {userDetails && userDetails.designation}
                        </p>
                      </div>
                    )}
                  </div>

                  {!collapsed && <p className="features-heading">Features</p>}

                  <Menu
                    className="sider-menu"
                    mode="inline"
                    theme="dark"
                    defaultSelectedKeys={["1"]}
                  >
                    <Menu.Item
                      className="asidebar-menu-item"
                      key="2"
                      icon={
                        <img
                          src={dashboardImage}
                          alt="Dashboard"
                          className="image-icon-style"
                        />
                      }
                      style={{
                        borderRadius: "10px",
                        border:
                          location.pathname === "/dashboard"
                            ? "2px solid black"
                            : "none",
                      }}
                    >
                      <Link className="asidebarMenuItems" to="/dashboard">
                        Dashboard
                      </Link>
                    </Menu.Item>
                  </Menu>
                  {!collapsed && (
                    <p className="features-heading">Organization</p>
                  )}
                  <Menu
                    className="sider-menu"
                    mode="inline"
                    theme="dark"
                    defaultSelectedKeys={["3"]}
                  >
                    {organizationDetails &&
                    organizationDetails.organization_name ? (
                      <Menu.Item
                        className="asidebar-menu-item"
                        key="4"
                        icon={
                          <img
                            src={organizationIcon}
                            alt="Dashboard"
                            className="image-icon-style"
                          />
                        }
                        style={{
                          borderRadius: "10px",
                          border:
                            location.pathname === "/organization/details"
                              ? "2px solid black"
                              : "none",
                        }}
                      >
                        <Link
                          className="asidebarMenuItems"
                          to="/organization/details"
                        >
                          {organizationDetails &&
                          organizationDetails.organization_name
                            ? organizationDetails.organization_name
                            : "Organization"}
                        </Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    <Menu.Item
                      className="asidebar-menu-item"
                      key="5"
                      icon={
                        <img
                          src={teamIcon}
                          alt="Dashboard"
                          className="image-icon-style"
                        />
                      }
                      style={{
                        borderRadius: "10px",
                        border:
                          location.pathname === "/team/list"
                            ? "2px solid black"
                            : "none",
                      }}
                    >
                      <Link className="asidebarMenuItems" to="/team/list">
                        Teams
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      className="asidebar-menu-item"
                      key="10"
                      icon={
                        <img
                          src={organizationIcon}
                          alt="Dashboard"
                          className="image-icon-style"
                        />
                      }
                      style={{
                        borderRadius: "10px",
                        border:
                          location.pathname === "/organization-hierarchy"
                            ? "2px solid black"
                            : "none",
                      }}
                    >
                      <Link
                        className="asidebarMenuItems"
                        to="/organization-hierarchy"
                      >
                        Organization Hierarchy
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      className="asidebar-menu-item"
                      key="11"
                      icon={
                        <img
                          src={organizationIcon}
                          alt="Dashboard"
                          className="image-icon-style"
                        />
                      }
                      style={{
                        borderRadius: "10px",
                        border:
                          location.pathname === "/company_chart"
                            ? "2px solid black"
                            : "none",
                      }}
                    >
                      <Link className="asidebarMenuItems" to="/company_chart">
                        Organization Chart
                      </Link>
                    </Menu.Item>
                  </Menu>
                  <Menu
                    className="sider-menu"
                    mode="inline"
                    theme="dark"
                    defaultSelectedKeys={["6"]}
                  >
                    <Menu.SubMenu
                      className="asidebar-sub-menu-item"
                      key="7"
                      icon={
                        <img
                          src={employeeImage}
                          alt="Employee Management"
                          className="image-icon-style"
                        />
                      }
                      title="Employee Management"
                    >
                      <Menu.Item
                        className="asidebar-menu-item"
                        key="leave"
                        icon={
                          <img
                            src={bookImage}
                            alt="Leave Management"
                            className="image-icon-style"
                          />
                        }
                        style={{
                          borderRadius: "10px",
                          border:
                            location.pathname === "/listing"
                              ? "2px solid black"
                              : "none",
                        }}
                      >
                        <Link className="asidebarMenuItems" to="/listing">
                          Weekly Goals
                        </Link>
                      </Menu.Item>
                      <Menu.Item
                        className="asidebar-menu-item"
                        key="leave"
                        icon={
                          <img
                            src={bookImage}
                            alt="timeoff-requests"
                            className="image-icon-style"
                          />
                        }
                        style={{
                          borderRadius: "10px",
                          border:
                            location.pathname === "/timeoffrequestlisting"
                              ? "2px solid black"
                              : "none",
                        }}
                      >
                        <Link
                          className="asidebarMenuItems"
                          to="/timeoffrequestlisting"
                        >
                          Timeoff Request
                        </Link>
                      </Menu.Item>
                      <Menu.Item
                        className="asidebar-menu-item"
                        key="reviews"
                        icon={
                          <img
                            src={performanceImage}
                            alt="timeoff-requests"
                            className="image-icon-style"
                          />
                        }
                        style={{
                          borderRadius: "10px",
                          border:
                            location.pathname === "/performancereview_listing"
                              ? "2px solid black"
                              : "none",
                        }}
                      >
                        <Link
                          className="asidebarMenuItems"
                          to="/performancereview_listing"
                        >
                          Performance Reviews
                        </Link>
                      </Menu.Item>
                    </Menu.SubMenu>

                    <Menu.Item
                      className="asidebar-menu-item"
                      key="8"
                      icon={
                        <img
                          src={performanceImage}
                          alt="Performance Management"
                          className="image-icon-style"
                        />
                      }
                      style={{
                        borderRadius: "10px",
                        border:
                          location.pathname === "/okrs"
                            ? "2px solid black"
                            : "none",
                      }}
                    >
                      <Link className="asidebarMenuItems" to="/okrs">
                        OKRs
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      className="asidebar-menu-item"
                      key="8"
                      icon={
                        <img
                          src={performanceImage}
                          alt="Performance Management"
                          className="image-icon-style"
                        />
                      }
                      style={{
                        borderRadius: "10px",
                        border:
                          location.pathname === "/highfives_listing"
                            ? "2px solid black"
                            : "none",
                      }}
                    >
                      <Link
                        className="asidebarMenuItems"
                        to="/highfives_listing"
                      >
                        High Fives
                      </Link>
                    </Menu.Item>
                  </Menu>

                  <Menu
                    className="sider-menu-logout"
                    mode="inline"
                    theme="dark"
                  >
                    <Menu.Item
                      className="asidebar-menu-item"
                      key="9"
                      icon={
                        <img
                          src={logoutImage}
                          alt="Logout"
                          className="image-icon-style"
                        />
                      }
                      onClick={handleLogoutClick}
                    >
                      Logout
                    </Menu.Item>
                  </Menu>
                </Sider>
                <Layout className="site-layout">
                  <Header
                    className="site-layout-background"
                    style={{
                      padding: 0,
                      marginLeft: collapsed ? "80px" : "270px",
                    }}
                  >
                    {React.createElement(
                      collapsed ? AlignLeftOutlined : AlignLeftOutlined,
                      {
                        className: "trigger",
                        onClick: () => setCollapsed(!collapsed),
                      }
                    )}
                    {/* <SearchSelect /> */}
                  </Header>
                  <Content
                    className="site-layout-background"
                    style={{
                      margin: "24px 16px",
                      minHeight: 280,
                      overflow: "auto",
                      padding: "24px",
                      marginLeft: collapsed ? "80px" : "270px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "fixed",
                        bottom: "20px",
                        right: "20px",
                        zIndex: 1000,
                      }}
                    >
                      <Button
                        className="chat-btn"
                        shape="circle"
                        icon={
                          isChatVisible ? (
                            <DownCircleOutlined style={{ color: "white" }} />
                          ) : (
                            <WechatFilled style={{ color: "white" }} />
                          )
                        }
                        size="large"
                        onClick={toggleChatVisibility}
                      />
                    </div>

                    {isChatVisible && (
                      <div
                        style={{
                          position: "fixed",
                          bottom: "80px",
                          right: "20px",
                          zIndex: 1000,
                        }}
                      >
                        <Chat onClose={toggleChatVisibility} />
                      </div>
                    )}
                    <Switch>
                      <Route
                        exact
                        path="/listing"
                        component={WeeklyGoalListing}
                      />
                      <Route
                        exact
                        path="/dashboard"
                        component={DashboardContent}
                      />
                      <Route
                        exact
                        path="/performancereview/:id"
                        component={PerformanceReviewNew}
                      />
                      <Route
                        exact
                        path="/performancereview_listing"
                        component={PerformanceReviewListing}
                      />
                      <Route exact path="/" component={DashboardContent} />
                      <Route
                        exact
                        path="/change_password"
                        component={ChangePassword}
                      />
                      <Route exact path="/okrs" component={OkrsPage} />
                      <Route
                        exact
                        path="/viewHighFive"
                        component={ViewHighFive}
                      />
                      <Route
                        exact
                        path="/okr_listings"
                        component={OkrListings}
                      />
                      <Route
                        exact
                        path="/update_okr/:id"
                        component={UpdateOkr}
                      />
                      <Route
                        exact
                        path="/viewperformancereview/:id"
                        component={ViewPerformanceReview}
                      />
                      <Route exact path="/profile" component={ProfileView} />
                      <Route exact path="/newteam" component={CreateTeam} />
                      <Route exact path="/team/list" component={TeamListing} />
                      <Route
                        exact
                        path="/profile/:id"
                        component={EmployeeProfile}
                      />
                      <Route
                        exact
                        path="/timeoffrequestlisting"
                        component={TimeoffRequestListing}
                      />
                      <Route exact path="/chat" component={Chat} />

                      <Route
                        exact
                        path="/timeoffdetail/:id"
                        component={TimeoffDetail}
                      />
                      <Route
                        exact
                        path="/timeoffdashboard"
                        component={TimeoffDashboard}
                      />
                      <Route
                        exact
                        path="/timeoffrequest"
                        component={TimeoffRequest}
                      />
                      <Route
                        exact
                        path="/addweeklygoals"
                        component={AddWeeklyGoals}
                      />
                      <Route
                        exact
                        path="/:GoalId/viewgoal"
                        component={ViewWeeklyGoal}
                      />
                      <Route
                        exact
                        path="/createhighfive"
                        component={CreateHighFive}
                      />
                      <Route
                        exact
                        path="/create_performancereview"
                        component={CreatePerformanceReview}
                      />
                      <Route
                        exact
                        path="/organization/details"
                        component={OrganizationDetail}
                      />
                      <Route
                        exact
                        path="/company_chart"
                        component={CompanyChart}
                      />
                      <Route
                        exact
                        path="/team/:teamId/update"
                        component={TeamUpdate}
                      />
                      <Route
                        exact
                        path="/user_chart"
                        component={UserMoodChart}
                      />
                      <Route
                        exact
                        path="/organizationregistration"
                        component={OrganizationRegistration}
                      />
                      <Route
                        exact
                        path="/organization-hierarchy"
                        component={OrganizationHierarchy}
                      />
                      <Route
                        exact
                        path="/team/:teamId/detail"
                        component={TeamDetail}
                      />
                      <Route
                        exact
                        path="/create_checkin/:id"
                        component={CreateCheckIn}
                      />
                      <Route
                        exact
                        path="/highfives_listing"
                        component={HighFivesListing}
                      />
                      <Route
                        exact
                        path="/highfivedashboard"
                        component={HighFiveDashboard}
                      />
                      <Route
                        exact
                        path="/view_checkin/:id"
                        component={ViewCheckIn}
                      />
                      <Route path="*" component={PageNotFound} />
                    </Switch>
                  </Content>
                </Layout>
              </Layout>
            </>
          );
        } else if (!verifiedOtp && !readLocalStorage("access-token")) {
          return (
            <>
              <Route exact path="/login" component={SignIn} />
              <Redirect
                to={{
                  pathname: "/login",
                }}
              />
            </>
          );
        } else if (!verifiedOtp && readLocalStorage("access-token")) {
          return (
            <>
              <Route exact path="/login" component={SignIn} />
              <Redirect
                to={{
                  pathname: "/login",
                }}
              />
            </>
          );
        } else if (!readLocalStorage("access-token")) {
          return (
            <>
              <Route exact path="/login" component={SignIn} />
              <Redirect
                to={{
                  pathname: "/login",
                }}
              />
            </>
          );
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};
