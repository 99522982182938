import React, { useState } from "react";
import { Form, Input, Button, message, Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import registerLeft from "../../assets/register.png";
import "./style.css";
import {
  CognitoUserPool,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";

const Signup = (props) => {
  const { history } = props;

  const [form] = Form.useForm();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const poolData = {
    UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    ClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  };

  const UserPool = new CognitoUserPool(poolData);

  const onFinish = (values) => {
    const attributes = [
      new CognitoUserAttribute({ Name: "name", Value: firstName }),
      new CognitoUserAttribute({ Name: "given_name", Value: lastName }),
      new CognitoUserAttribute({ Name: "custom:first_name", Value: firstName }),
      new CognitoUserAttribute({ Name: "custom:last_name", Value: lastName }),
    ];

    UserPool.signUp(
      values.email,
      values.password,
      attributes,
      null,
      (err, data) => {
        if (err) {
          message.error({ content: err.message, duration: 3 });
          return;
        }
        if (data) {
          message.success({
            content: `Thank you. We have sent you an email. Please verify your email and activate your account.`,
            duration: 2,
          });
          history.push("/login");
        }
      }
    );
  };

  const validatePassword = (_, value) => {
    const passwordPattern =
      /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]{6,}$/;
    if (value && !passwordPattern.test(value)) {
      return Promise.reject(
        "Password should be at least 6 characters long and include both letters and numbers."
      );
    }
    return Promise.resolve();
  };

  const validateEmail = (rule, value, callback) => {
    if (value && !/\S+@\S+\.\S+/.test(value)) {
      callback("The input is not valid E-mail!");
    } else {
      callback();
    }
  };

  return (
    <>
      <Row>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <div className="register-left">
            <div className="register-left-content">
              <img src={registerLeft} alt="" />
              <h1 className="registration-heading-styling">
                Welcome to High-Knot
              </h1>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <div className="register-right">
            <div className="register-right-content">
              <h2 className="registration-subheading-styling">
                Sign up for your account
              </h2>
              <div className="signUpinputStyle">
                <Form
                  form={form}
                  name="normal_login"
                  className="login-form"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                    ]}
                    // validateTrigger={['onBlur']}
                  >
                    <Input
                      placeholder="First Name"
                      className="signupFirstname"
                      value={firstName}
                      size="large"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="given_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                    ]}
                    // validateTrigger={['onBlur']}
                  >
                    <Input
                      placeholder="Last Name"
                      className="signupLastname"
                      size="large"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                      {
                        validator: validateEmail,
                      },
                    ]}
                    validateTrigger={["onBlur"]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      className="signupEmail"
                      size="large"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        if (/\S+@\S+\.\S+/.test(e.target.value)) {
                          form.validateFields(["email"]);
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                      {
                        validator: validatePassword,
                      },
                    ]}
                    validateTrigger={["onBlur"]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      size="large"
                      className="signupPassword"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item>
                    <div className="signUpSubmitBtn">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="signup-form-button"
                      >
                        SIGN UP
                      </Button>
                    </div>
                    <div className="alreadyAccountDiv">
                      <span className="alreadyAccountHeading">
                        Already have an account?
                      </span>
                      <a href="/login" className="signInLink">
                        Login
                      </a>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(Signup);
