import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
export const CREATE_GOAL_SUCCESS = "CREATE_GOAL_SUCCESS";
export const CREATE_GOAL_ERROR = "CREATE_GOAL_ERROR";
export const CREATE_GOAL_REQUEST = "CREATE_GOAL_REQUEST ";

export const createGoal = (id, Goal) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");
  dispatch({ type: CREATE_GOAL_REQUEST });
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/weekly_goals/${id}/goals`,
    data: {
      detail: Goal,
      status: true,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      return dispatch({
        type: CREATE_GOAL_SUCCESS,
        response: response.status,
      });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(tokenAuthFailedAction());
      }
      return dispatch({
        type: CREATE_GOAL_ERROR,
        response: error.response,
      });
    });
};
