import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCheckins } from "../../redux/actions/Checkin/getCheckins";
import { Row, Col } from "antd";
import { getUsers } from "../../redux/actions/User/getUsers";
import userDummy from "../../assets/user.png";
import { useHistory } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import { convertUNIXString } from "../../utils/convertUnixTimeStamp";
import "./style.css";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      display: true,
      beginAtZero: true,
      scaleLabel: {
        display: true,
        labelString: "Value",
      },
      ticks: {
        min: 1,
        max: 5,
        stepSize: 1,
        callback: function (label) {
          switch (label) {
            case 1:
              return "Awful";
            case 2:
              return "Bad";
            case 3:
              return "Ok";
            case 4:
              return "Happy";
            case 5:
              return "Great";
            default:
              return "";
          }
        },
      },
      gridLines: {
        display: false,
      },
    },
  },
};

export default function LineChart() {
  const dispatch = useDispatch();
  const history = useHistory();
  const id = localStorage.getItem("employee_id");
  const parsedId = parseInt(id, 10);
  const [UsersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const getUsersList = useSelector((state) => state.getUsers);

  const [selectedEmployeeId, setSelectedEmployeeId] = useState(parsedId);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch, id]);

  useEffect(() => {
    if (getUsersList && getUsersList.status === 200) {
      setUsersList(getUsersList.data);
      const selectedUser = getUsersList.data.find(
        (user) => user.id === selectedEmployeeId
      );
      if (selectedUser) {
        setSelectedUser(selectedUser);
      }
    }
  }, [getUsersList, selectedEmployeeId]);

  const onClickEmployee = (id) => {
    dispatch(getCheckins(id));
    setSelectedEmployeeId(id);
    history.push(`/user_chart`);
  };
  const getCheckinsStatus = useSelector((state) => state.getCheckins);
  const [checkinsValue, setCheckinsValue] = useState([]);

  useEffect(() => {
    dispatch(getCheckins(id));
  }, [dispatch]);

  useEffect(() => {
    if (getCheckinsStatus && getCheckinsStatus.status === 200) {
      setCheckinsValue(getCheckinsStatus.data);
    }
  }, [getCheckinsStatus]);

  let averageMood = checkinsValue
    .filter((record) => record.sort_id === 1)
    .map((filteredRecord) => filteredRecord.answer);
  let endDate = checkinsValue
    .filter((record) => record.sort_id === 1)
    .map((filteredRecord) => filteredRecord.endDate);

  const labels = endDate.map((timestamp) => convertUNIXString(timestamp));

  const data = {
    labels,
    datasets: [
      {
        data: averageMood,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        pointRadius: 5,
      },
    ],
  };

  return (
    <>
      <p className="company-mood-chart-title">
        {selectedUser && selectedUser.first_name}{" "}
        {selectedUser && selectedUser.last_name}'s Mood Chart
      </p>
      <Row gutter={12}>
        <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={16}>
          <div className="company-chart">
            <Line options={options} data={data} />
          </div>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={8}>
          <div className="employees-mood-chart-main">
            {UsersList.filter(
              (employee) => employee.id !== selectedEmployeeId
            ).map((employee, index) => (
              <div
                className="employee-details-div"
                key={index}
                onClick={() => onClickEmployee(employee.id)}
              >
                <Row gutter={16}>
                  <Col
                    className="gutter-row"
                    xs={3}
                    sm={3}
                    md={4}
                    lg={5}
                    xl={5}
                  >
                    <img
                      src={employee.image || userDummy}
                      alt="User"
                      className="employee-image"
                    />
                  </Col>
                  <Col
                    className="gutter-row"
                    sxs={18}
                    sm={18}
                    md={17}
                    lg={15}
                    xl={18}
                  >
                    <p className="weekly-goals-duration">
                      {employee.first_name + " " + employee.last_name}
                    </p>
                    <p className="weekly-goal-assignee">
                      Role: {employee.designation}
                    </p>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
}
