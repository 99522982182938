import React from "react";
import { Row, Col } from "antd";
import MainModules from "./MainModules";
import WeeklyGoalsModule from "./WeeklyGoalsModule";
import EmployeesModule from "./EmployeesModule";
import PerformanceModule from "./PerformanceModule";
import TimeOffsModule from "./TimeOffsModule";
import OkrModule from "./OkrModule";
import CheckInModule from "./CheckInModule";

import "./style.css";

const DashboardContent = () => {
  return (
    <div className="">
      <MainModules />

      <div className="modules-div">
        <Row gutter={16}>
          <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="module-outer-div">
              <WeeklyGoalsModule />
            </div>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="module-outer-div">
              <EmployeesModule />
            </div>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="module-outer-div">
              <PerformanceModule />
            </div>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="module-outer-div">
              <TimeOffsModule />
            </div>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="module-outer-div">
              <OkrModule />
            </div>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="module-outer-div">
              <CheckInModule />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DashboardContent;
