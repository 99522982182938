import {
  VIEW_OBJECTIVE_SUCCESS,
  VIEW_OBJECTIVE_REQUEST,
  VIEW_OBJECTIVE_ERROR,
} from "../../actions/Okr/viewObjective";

const viewObjective = (state = null, action) => {
  switch (action.type) {
    case VIEW_OBJECTIVE_REQUEST: {
      return true;
    }
    case VIEW_OBJECTIVE_SUCCESS: {
      return action.response;
    }
    case VIEW_OBJECTIVE_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default viewObjective;
