import React, { useState, useEffect } from "react";
import { Input, Button, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { createHighFive } from "../../redux/actions/HighFive/createHighFive";
import { getUsers } from "../../redux/actions/User/getUsers";
import userDummy from "../../assets/user.png";
import { CheckSquareFilled } from "@ant-design/icons";
import { Form } from "antd";
import "./style.css";

const CreateHighFive = (props) => {
  const { history } = props;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const createHighFiveStatus = useSelector((state) => state.createHighFive);
  const user_id = localStorage.getItem("user_id");
  const [UsersList, setUsersList] = useState([]);
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const getUsersList = useSelector((state) => state.getUsers);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    if (getUsersList && getUsersList.status === 200) {
      setUsersList(getUsersList.data);
    }
  }, [getUsersList]);

  useEffect(() => {
    if (createHighFiveStatus && createHighFiveStatus.status === 201) {
      history.push("/highfives_listing");
    }
  }, [createHighFiveStatus, history]);

  const onClickEmployee = (id, first_name, last_name) => {
    setSelectedUserName(first_name + " " + last_name);
    setSelectedEmployeeId(id);
    setSelectedUserId(id);
  };

  const onFinish = (values) => {
    dispatch(createHighFive(selectedUserId, values.comment));
  };

  return (
    <>
      <h1 className="highFivesTitle">Give High Five 🙌🏻</h1>

      <Row gutter={16}>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16}>
          <div className="createHighFive">
            <div className="cretaeHighFiveFormDiv">
              <p className="highFiveFrom">From</p>

              <p className="highFiveFromValue">
                {localStorage.getItem("first_name")}{" "}
                {localStorage.getItem("last_name")}
              </p>

              <p className="highFiveTo">To</p>

              <Form onFinish={onFinish}>
                <Input placeholder="To" value={selectedUserName} readOnly />

                <p className="highFiveComment">Comment</p>

                <Form.Item
                  name="comment"
                  rules={[
                    {
                      required: true,
                      message: "Please input  your comment!",
                    },
                  ]}
                >
                  <TextArea rows={4} />
                </Form.Item>

                <Button className="hiveFivePostBtn" htmlType="submit">
                  Create High Five
                </Button>
              </Form>
            </div>
          </div>
        </Col>

        <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8}>
          <div className="employees-mood-chart-main">
            <h3 className="giveHighFiveUser">Give High Five 🙌🏻 to:</h3>
            {UsersList.filter(
              (employee) => employee.id.toString() !== user_id
            ).map((employee, index) => (
              <div
                key={index}
                className={
                  "employee-details-div" +
                  (employee.id === selectedEmployeeId ? " selected" : "")
                }
                onClick={() =>
                  onClickEmployee(
                    employee.id,
                    employee.first_name,
                    employee.last_name
                  )
                }
              >
                <Row gutter={16}>
                  <Col
                    className="gutter-row"
                    xs={3}
                    sm={3}
                    md={4}
                    lg={5}
                    xl={5}
                  >
                    <img
                      src={employee.image || userDummy}
                      alt="User"
                      className="employee-image"
                    />
                  </Col>
                  <Col
                    className="gutter-row"
                    xs={18}
                    sm={18}
                    md={17}
                    lg={15}
                    xl={14}
                  >
                    <p className="weekly-goals-duration">
                      {employee.first_name} {employee.last_name}
                    </p>
                    <p className="weekly-goal-assignee">
                      Role: {employee.designation}
                    </p>
                  </Col>
                  <Col
                    className="gutter-row"
                    xs={18}
                    sm={18}
                    md={17}
                    lg={15}
                    xl={5}
                  >
                    {selectedEmployeeId &&
                      employee.id === selectedEmployeeId && (
                        <CheckSquareFilled
                          style={{ fontSize: "16px", color: "green" }}
                        />
                      )}
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(CreateHighFive);
