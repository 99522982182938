import axios from "axios";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
import { readLocalStorage } from "../../../utils/readLocalStorage";
export const GET_TEAMS_SUCCESS = "GET_TEAMS _SUCCESS";
export const GET_TEAMS_ERROR = "GET_TEAMS _ERROR";
export const GET_TEAMS_REQUEST = "GET_TEAMS _REQUEST ";

export const getTeams = () => (dispatch) => {
  const user_id = localStorage.getItem("user_id");

  dispatch({ type: GET_TEAMS_REQUEST });
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/teams`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      return dispatch({ type: GET_TEAMS_SUCCESS, response: response });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(tokenAuthFailedAction());
      }
      return dispatch({ type: GET_TEAMS_ERROR, response: error });
    });
};
