import React, { useState, useEffect } from "react";
import { Row, Col, Typography } from "antd";
import { useHistory } from "react-router-dom";
import weeklygoals from "../../assets/weeklygoals.svg";
import KPI from "../../assets/KPI.png";
import HighFives from "../../assets/highfives.png";
import employees from "../../assets/employees.svg";
import leaves from "../../assets/leaves.png";
import { getEmployees } from "../../redux/actions/User/getEmployees";
import { getOrganization } from "../../redux/actions/Organization/getOrganization";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
const { Text } = Typography;

const MainModules = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [employeesCount, setEmployeesCount] = useState([""]);
  const [organizationDetails, setOrganizationDetails] = useState("");
  const getEmployeesList = useSelector((state) => state.getEmployees);
  const getOrganizationStatus = useSelector((state) => state.getOrganization);
  useEffect(() => {
    dispatch(getOrganization());
  }, [dispatch]);

  useEffect(() => {
    if (getOrganizationStatus && getOrganizationStatus.status === 200) {
      setOrganizationDetails(getOrganizationStatus.data[0]);
    }
  }, [getOrganizationStatus]);

  const user_id = localStorage.getItem("user_id");
  useEffect(() => {
    dispatch(getEmployees(user_id));
  }, [dispatch, user_id]);

  useEffect(() => {
    if (getEmployeesList && getEmployeesList.status === 200) {
      setEmployeesCount(getEmployeesList.data.length);
    }
  }, [getEmployeesList]);

  const onClickWeeklyGoals = () => {
    history.push("/listing");
  };
  const onClickHighFives = () => {
    history.push("/highfives_listing");
  };

  const onClickOkrs = () => {
    history.push("/okrs");
  };
  const onClickLeaves = () => {
    history.push("/timeoffrequestlisting");
  };

  return (
    <div className="main-modules">
      <Row gutter={12}>
        <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={5}>
          <div className="weekly-goals-module" onClick={onClickWeeklyGoals}>
            <img src={weeklygoals} alt="img" className="weekly-goals-image" />
            <Text className="goals-module-heading">Weekly Goals</Text>
          </div>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={4}>
          <div className="kpi-module" onClick={onClickOkrs}>
            <img src={KPI} alt="img" className="kpi-image" />
            <Text className="kpi-module-heading">KPI</Text>
          </div>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={5}>
          <div className="highfives-module" onClick={onClickHighFives}>
            <img src={HighFives} alt="img" className="highfives-image" />
            <Text className="highfives-module-heading">HighFive</Text>
          </div>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={5}>
          <div className="employees-module">
            <img src={employees} alt="img" className="employees-image" />
            <div className="employees-count">
              <Text className="employees-count-heading">{employeesCount}</Text>
              <Text className="employees-module-heading">Employees</Text>
            </div>
          </div>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={5}>
          <div className="leaves-module">
            <img src={leaves} alt="img" className="leaves-image" />
            <div className="leaves-count" onClick={onClickLeaves}>
              <Text className="leaves-count-heading">
                {organizationDetails && organizationDetails.time_off_request
                  ? organizationDetails.time_off_request
                  : "N/A"}
              </Text>
              <Text className="leaves-module-heading">Leaves</Text>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MainModules;
