import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const CREATE_TEAM_ERROR = "CREATE_TEAM_ERROR";
export const CREATE_TEAM_REQUEST = "CREATE_TEAM_REQUEST";

export const createTeam = (team) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");

  dispatch({ type: CREATE_TEAM_REQUEST });
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/teams`,
    data: {
      title: team.teamName,
      description: team.description,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      dispatch({
        type: CREATE_TEAM_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(tokenAuthFailedAction());
      }
      dispatch({
        type: CREATE_TEAM_ERROR,
        response: error.response,
      });
    });
};
