import {
    GET_WEEKLY_GOAL_SUCCESS,
    GET_WEEKLY_GOAL_REQUEST,
    GET_WEEKLY_GOAL_ERROR,
  } from "../../actions/Organization/viewWeeklyGoal";
  
  const getWeeklyGoalDetail = (state = null, action) => {
    switch (action.type) {
      case GET_WEEKLY_GOAL_REQUEST: {
        return true;
      }
      case GET_WEEKLY_GOAL_SUCCESS: {
        return action.response;
      }
      case GET_WEEKLY_GOAL_ERROR: {
        return action.response;
      }
      default:
        return state;
    }
  };
  
  export default getWeeklyGoalDetail;
  