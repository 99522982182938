import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { MoreOutlined, EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getPerformanceReview } from "../../redux/actions/PerformanceReview/getPerformanceReview";
import { convertUNIXString } from "../../utils/convertUnixTimeStamp";
import { getUsers } from "../../redux/actions/User/getUsers";
import userDummy from "../../assets/user.png";
import { useHistory } from "react-router-dom";
import "./style.css";

const PerformanceModule = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [review, setReview] = useState([""]);
  const getPerformanceReviewStatus = useSelector(
    (state) => state.getPerformanceReview
  );
  const [UsersList, setUsersList] = useState([]);
  const getUsersList = useSelector((state) => state.getUsers);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getPerformanceReview());
  }, [dispatch]);

  useEffect(() => {
    if (getUsersList && getUsersList.status === 200) {
      setUsersList(getUsersList.data);
    }
  }, [getUsersList]);

  useEffect(() => {
    if (
      getPerformanceReviewStatus &&
      getPerformanceReviewStatus.status === 200
    ) {
      setReview(getPerformanceReviewStatus.data);
    }
  }, [getPerformanceReviewStatus]);

  const getUserImageByUserId = (userId) => {
    const user = UsersList.find((user) => user.id === userId);
    return user ? user.image || userDummy : userDummy;
  };

  const getUserNameByUserId = (userId) => {
    const user = UsersList.find((user) => user.id === userId);
    return user ? `${user.first_name} ${user.last_name}` : "";
  };

  function getMonthAndYear(dateString) {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${month} ${year}`;
  }

  const onClickReview = (id) => {
    history.push(`./viewperformancereview/${id}`);
  };
  return (
    <div className="weekly-goals-module-style">
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <p className="weekly-goals-heading">Performance Management</p>
        </Col>
        <Col className="gutter-row" span={12}>
          <div className="dots-div">
            <MoreOutlined className="menu-icon-style" />
          </div>
        </Col>
      </Row>
      <div className="details-div">
        {review &&
          review.map((reviewDetail, uniId) => {
            const startDate = convertUNIXString(reviewDetail.start_date);
            const endDate = convertUNIXString(reviewDetail.end_date);
            const startDateMonthYear = getMonthAndYear(startDate);
            const endDateMonthYear = getMonthAndYear(endDate);
            return (
              <div
                className="goal-details-div"
                key={uniId}
                onClick={() => onClickReview(reviewDetail && reviewDetail.id)}
              >
                <Row gutter={16}>
                  <Col
                    className="gutter-row"
                    xs={3}
                    sm={3}
                    md={4}
                    lg={5}
                    xl={3}
                  >
                    <img
                      src={getUserImageByUserId(reviewDetail.employee_id)}
                      alt="Dashboard"
                      className="user-dummy-image-style"
                    />
                  </Col>
                  <Col
                    className="gutter-row"
                    sxs={18}
                    sm={18}
                    md={17}
                    lg={15}
                    xl={18}
                  >
                    <p className="weekly-goals-duration">
                      {startDateMonthYear} - {endDateMonthYear}
                    </p>
                    <p className="weekly-goal-assignee">
                      To: {reviewDetail.user_name}, By:
                      {getUserNameByUserId(reviewDetail.user_id)}
                    </p>
                  </Col>
                  <Col
                    className="gutter-row"
                    xs={3}
                    sm={3}
                    md={3}
                    lg={4}
                    xl={3}
                  >
                    <div className="weeklygoal-action-button">
                      <p className="view-text">view</p>
                      <div className="view-icon-div">
                        <EyeOutlined className="eye-icon-style" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PerformanceModule;
