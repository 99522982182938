import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";

export const LEAVE_TYPE_Count_SUCCESS = "LEAVE_TYPE_Count_SUCCESS";
export const LEAVE_TYPE_Count_ERROR = "LEAVE_TYPE_Count_ERROR";
export const LEAVE_TYPE_Count_REQUEST = "LEAVE_TYPE_Count_REQUEST ";

export const leaveTypeCount = (value) => (dispatch) => {
  dispatch({ type: LEAVE_TYPE_Count_REQUEST });
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/v1/time_offs?leave_type=${value}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  }).then((response) => {
    if (value === "vocational_leave") {
      localStorage.setItem("vocational_leave", response.data.count);
    }
    if (value === "emergency_leave") {
      localStorage.setItem("emergency_leave", response.data.count);
    }
    if (value === "sick_leave") {
      localStorage.setItem("sick_leave", response.data.count);
    }
    if (value === "annual_leave") {
      localStorage.setItem("annual_leave", response.data.count);
    }
    if (value === "other") {
      localStorage.setItem("other", response.data.count);
    }
    return dispatch({ type: LEAVE_TYPE_Count_SUCCESS, response: response });
  });
  // .catch((error) => {
  //   return dispatch({
  //     type: LEAVE_TYPE_Count_ERROR,
  //     response: error.response,
  //   });
  // });
};
