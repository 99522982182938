import {
  GET_WEEKLY_GOALS_SUCCESS,
  GET_WEEKLY_GOALS_REQUEST,
  GET_WEEKLY_GOALS_ERROR,
} from "../../actions/Organization/weeklyGoalsList";

const getWeeklyGoals = (state = null, action) => {
  switch (action.type) {
    case GET_WEEKLY_GOALS_REQUEST: {
      return true;
    }
    case GET_WEEKLY_GOALS_SUCCESS: {
      return action.response;
    }
    case GET_WEEKLY_GOALS_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getWeeklyGoals;
