import React, { useEffect } from "react";
import { Button, Row, Col } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";

import RequestsStatus from "../../components/TimeOffRequests/requestsStatus";
import RequestsType from "../../components/TimeOffRequests/requestsType";
import RequestsListing from "../../components/TimeOffRequests/requestsListing";
import OrganizationUsers from "../../components/TimeOffRequests/organizationUsers";
import "./style.css";

const TimeoffDashboard = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {}, [dispatch]);

  return (
    <div className="timeoffdashboard">
      <Row>
        <Col className="firstcol" span={17}>
          <Row>
            <Col span={10}>
              <RequestsStatus />
            </Col>
            <Col span={14}>
              <RequestsType />
            </Col>
          </Row>

          <RequestsListing />
        </Col>
        <Col className="secondcol" span={7}>
          <div className="timeoffrequestbtn">
            <a href="/timeoffrequest">
              <Button
                className="requestbtnstyle"
                type=""
                icon={<CalendarOutlined />}
              >
                TimeOff Request
              </Button>
            </a>
          </div>

          <OrganizationUsers />
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(TimeoffDashboard);
