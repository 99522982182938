import React, { useState, useEffect } from "react";
import { Row, Col, Menu, Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  EllipsisOutlined,
  CloseOutlined,
  CheckOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { convertUNIXString } from "../../utils/convertUnixTimeStamp";
import { getRequestedTimeOffs } from "../../redux/actions/TimeOff/getRequestedTimeOffs";
import { getRejectedTimeOffs } from "../../redux/actions/TimeOff/getRejectedTimeOffs";
import { getApprovedTimeOffs } from "../../redux/actions/TimeOff/getApprovedTimeOffs";
import { getTimeOff } from "../../redux/actions/TimeOff/getTimeOff";
import { useHistory } from "react-router-dom";

const RequestsListing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getTimeOffStatus = useSelector((state) => state.getTimeOff);
  const [record, setRecord] = useState("");
  const onFinish = (id) => {
    history.push(`/timeoffdetail/${id.id}`);
  };
  const onRequestedHandleChange = () => {
    dispatch(getRequestedTimeOffs()).then((result) => {
      setRecord(result.response.data);
    });
  };
  const onApprovedHandleChange = () => {
    dispatch(getApprovedTimeOffs()).then((result) => {
      setRecord(result.response.data);
    });
  };

  const onRejectedHandleChange = () => {
    dispatch(getRejectedTimeOffs()).then((result) => {
      setRecord(result.response.data);
    });
  };
  const onAllTimeOffsHandleChange = () => {
    dispatch(getTimeOff()).then((result) => {
      setRecord(result.response.data);
    });
  };
  useEffect(() => {
    if (getTimeOffStatus && getTimeOffStatus.status === 201) {
      setRecord(getTimeOffStatus.data);
    }
  }, [getTimeOffStatus]);

  useEffect(() => {
    dispatch(getTimeOff());
  }, [dispatch]);

  const menu = (
    <Menu>
      <Menu.Item key="0" className="pendingStyle">
        <a
          href="#/"
          onClick={() => {
            onRequestedHandleChange();
          }}
        >
          Pending
        </a>
      </Menu.Item>
      <Menu.Item key="1" className="approvedStyle">
        <a
          href="#/"
          onClick={() => {
            onApprovedHandleChange();
          }}
        >
          Approved
        </a>
      </Menu.Item>
      <Menu.Item key="2" className="rejectedStyle">
        <a
          href="#/"
          onClick={() => {
            onRejectedHandleChange();
          }}
        >
          Rejected
        </a>
      </Menu.Item>
      <Menu.Item key="3" className="onAllTimeOffStyle">
        <a
          href="#/"
          onClick={() => {
            onAllTimeOffsHandleChange();
          }}
        >
          All
        </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <div className="tomeoffactivities">
        <Row>
          <Col span={12}>
            <h1 className="activitytitle">Activities</h1>
          </Col>
          <Col span={12}>
            <div className="dropdown-style">
              <Dropdown overlay={menu} trigger={["click"]}>
                <a
                  href="#/"
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <EllipsisOutlined className="doticon" />
                </a>
              </Dropdown>
            </div>
          </Col>
        </Row>

        {[...record]
          .sort((a, b) => b.id - a.id)
          .map((value, uniId) => {
            return (
              <Row key={uniId}>
                <Col span={5}>
                  <div className="activitiesicon">
                    {value.status === "requested" && (
                      <EllipsisOutlined className="ellipsisicon" />
                    )}
                    {value.status === "rejected" && (
                      <CloseOutlined className="ellipsisicon" />
                    )}
                    {value.status === "approved" && (
                      <CheckOutlined className="ellipsisicon" />
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div
                    className="activitycontent"
                    onClick={(e) => onFinish(value)}
                    style={{ cursor: "pointer" }}
                  >
                    <p className="daterange">
                      {convertUNIXString(value.start_date)} -{" "}
                      {convertUNIXString(value.end_date)}
                    </p>
                    <div className="statusanddate">
                      <p className="activityleavestatus">{value.status}</p>
                      <p className="activitydatetime"></p>
                    </div>
                  </div>
                </Col>
                <Col span={7}>
                  <div className="activitycandidate">
                    <p className="candidatename">{value.user_name}</p>
                  </div>
                </Col>
              </Row>
            );
          })}
        <Col span={24}>
          <div className="moreicon">
            <DownOutlined className="ellipsisicon" />
          </div>
        </Col>
      </div>
    </>
  );
};

export default RequestsListing;
