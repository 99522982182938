import {
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_ERROR,
} from "../../actions/User/logout";

const userLogout = (state = null, action) => {
  switch (action.type) {
    case USER_LOGOUT_REQUEST: {
      return true;
    }
    case USER_LOGOUT_SUCCESS: {
      return action.response;
    }
    case USER_LOGOUT_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default userLogout;
