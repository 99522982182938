import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
export const VIEW_ORGANIZATION_SUCCESS = "VIEW_ORGANIZATION_SUCCESS";
export const VIEW_ORGANIZATION_ERROR = "VIEW_ORGANIZATION_ERROR";
export const VIEW_ORGANIZATION_REQUEST = "VIEW_ORGANIZATION_REQUEST ";

export const getOrganization = () => (dispatch) => {
  const user_id = localStorage.getItem("user_id");
  dispatch({ type: VIEW_ORGANIZATION_REQUEST });
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/organizations`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      return dispatch({
        type: VIEW_ORGANIZATION_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(tokenAuthFailedAction());
      }
      return dispatch({
        type: VIEW_ORGANIZATION_ERROR,
        response: error.response,
      });
    });
};
