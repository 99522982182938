import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";

export const CREATE_HIGH_FIVE_SUCCESS = "CREATE_HIGH_FIVE_SUCCESS";
export const CREATE_HIGH_FIVE_ERROR = "CREATE_HIGH_FIVE_ERROR";
export const CREATE_HIGH_FIVE_REQUEST = "CREATE_HIGH_FIVE_REQUEST";

export const createHighFive = (user, comment) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");

  dispatch({ type: CREATE_HIGH_FIVE_REQUEST });
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/high_fives`,
    data: {
      to_user: user,
      coment: comment,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      dispatch({
        type: CREATE_HIGH_FIVE_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: CREATE_HIGH_FIVE_ERROR,
        response: error.response,
      });
    });
};
