import {
  WEEKLY_GOAL_DELETE_SUCCESS,
  WEEKLY_GOAL_DELETE_REQUEST,
  WEEKLY_GOAL_DELETE_ERROR,
  WEEKLY_GOAL_DELETE_RESET
} from "../../actions/Organization/deleteWeeklyGoal";

const deleteWeeklyGoal = (state = null, action) => {
  switch (action.type) {
    case WEEKLY_GOAL_DELETE_REQUEST: {
      return true;
    }
    case WEEKLY_GOAL_DELETE_SUCCESS: {
      return action.response;
    }
    case WEEKLY_GOAL_DELETE_ERROR: {
      return action.response;
    }
    case WEEKLY_GOAL_DELETE_RESET: {
      return null;
    }
    default:
      return state;
  }
};

export default deleteWeeklyGoal;
