import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import userDummy from "../../assets/user.png";
import { getTopAppreciated } from "../../redux/actions/HighFive/getTopAppreciated";

const TopHighFives = () => {
  const dispatch = useDispatch();
  const [TopAppreciated, setTopAppreciated] = useState();
  const getTopAppreciatedStatus = useSelector(
    (state) => state.getTopAppreciated
  );
  useEffect(() => {
    if (getTopAppreciatedStatus && getTopAppreciatedStatus.status === 200) {
      setTopAppreciated(getTopAppreciatedStatus.data);
    }
  }, [getTopAppreciatedStatus]);
  useEffect(() => {
    dispatch(getTopAppreciated());
  }, [dispatch]);
  return (
    <>
      <div className="topHighFives">
        <p className="mostAppreciatedHeading">Top High Fivers </p>
        <div className="mostAppreciatedDetailsWithCount">
          {TopAppreciated &&
            TopAppreciated.map((value) => (
              <>
                {value.sent_high_five !== null ? (
                  <Row gutter={16}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="topAppreciatedUserImg">
                        <img
                          src={value && value.image ? value.image : userDummy}
                          alt=""
                          className="highfie-users"
                        />
                        <p className="topAppreciatedUsername">
                          {value.first_name} {value.last_name}
                        </p>
                      </div>
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="topAppreciatedCount">
                        {" "}
                        <p>{value && value.sent_high_five}</p>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </>
            ))}
        </div>
      </div>
    </>
  );
};
export default TopHighFives;
