import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";

export const GET_REJECTED_TIME_OFF_SUCCESS = "GET_REJECTED_TIME_OFF_SUCCESS";
export const GET_REJECTED_TIME_OFF_ERROR = "GET_REJECTED_TIME_OFF_ERROR";
export const GET_REJECTED_TIME_OFF_REQUEST = "GET_REJECTED_TIME_OFF_REQUEST ";

export const getRejectedTimeOffs = () => (dispatch) => {
  const user_id = localStorage.getItem("user_id");
  dispatch({ type: GET_REJECTED_TIME_OFF_REQUEST });
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/time_off_rejected`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_REJECTED_TIME_OFF_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_REJECTED_TIME_OFF_ERROR,
        response: error.response,
      });
    });
};
