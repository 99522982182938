import {
    TEAM_DELETE_SUCCESS,
    TEAM_DELETE_REQUEST,
    TEAM_DELETE_ERROR,
    TEAM_DELETE_RESET
  } from "../../actions/Team/deleteTeam";
  
  const deleteTeam = (state = null, action) => {
    switch (action.type) {
      case TEAM_DELETE_REQUEST: {
        return true;
      }
      case TEAM_DELETE_SUCCESS: {
        return action.response;
      }
      case TEAM_DELETE_ERROR: {
        return action.response;
      }
      case TEAM_DELETE_RESET: {
        return null;
      }
      default:
        return state;
    }
  };
  
  export default deleteTeam;
  