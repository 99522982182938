import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
export const UPDATE_KEY_RESULT_SUCCESS = "UPDATE_KEY_RESULT_SUCCESS";
export const UPDATE_KEY_RESULT_ERROR = "UPDATE_KEY_RESULT_ERROR";
export const UPDATE_KEY_RESULT_REQUEST = "UPDATE_KEY_RESULT_REQUEST";

export const updateKeyResult = (id, objective) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");

  dispatch({ type: UPDATE_KEY_RESULT_REQUEST });
  return axios({
    method: "put",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/objectives/${id}/key_results/${objective.id}`,
    data: {
      title: objective.keyresultsInput,
      target: objective.targetInput,
      target_achieved: objective.targetAchievedInput,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      dispatch({
        type: UPDATE_KEY_RESULT_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_KEY_RESULT_ERROR,
        response: error.response,
      });
    });
};
