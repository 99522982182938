import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
export const UPDATE_PROFILE_VIEW_DETAILS_SUCCESS =
  "UPDATE_PROFILE_VIEW_DETAILS_SUCCESS";
export const UPDATE_PROFILE_VIEW_DETAILS_ERROR =
  "UPDATE_PROFILE_VIEW_DETAILS_ERROR";
export const UPDATE_PROFILE_VIEW_DETAILS_REQUEST =
  "UPDATE_PROFILE_VIEW_DETAILS_REQUEST";

export const updateProfileView = (formData) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");
  dispatch({ type: UPDATE_PROFILE_VIEW_DETAILS_REQUEST });
  return axios({
    method: "put",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/user`,
    data: {
      image: formData.image,
      designation: formData.designation,
      first_name: formData.first_name,
      last_name: formData.last_name,
      contact: formData.mobile,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      dispatch({
        type: UPDATE_PROFILE_VIEW_DETAILS_SUCCESS,
        response: response.status,
      });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(tokenAuthFailedAction());
      }
      dispatch({
        type: UPDATE_PROFILE_VIEW_DETAILS_ERROR,
        response: error.response.status,
      });
    });
};
