import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
export const VIEW_PERFORMANCE_REVIEW_SUCCESS =
  "VIEW_PERFORMANCE_REVIEW_SUCCESS";
export const VIEW_PERFORMANCE_REVIEW_ERROR = "VIEW_PERFORMANCE_REVIEW_ERROR";
export const VIEW_PERFORMANCE_REVIEW_REQUEST =
  "VIEW_PERFORMANCE_REVIEW_REQUEST ";

export const viewPerformanceReview = (id) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");
  dispatch({ type: VIEW_PERFORMANCE_REVIEW_REQUEST });
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/performance_review/${id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      return dispatch({
        type: VIEW_PERFORMANCE_REVIEW_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      return dispatch({
        type: VIEW_PERFORMANCE_REVIEW_ERROR,
        response: error.response,
      });
    });
};
