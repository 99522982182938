import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col, Menu, Dropdown } from "antd";
import { withRouter } from "react-router-dom";
import { getOrganization } from "../../redux/actions/Organization/getOrganization";
import { getApprovedTimeOffs } from "../../redux/actions/TimeOff/getApprovedTimeOffs";
import { getRequestedTimeOffs } from "../../redux/actions/TimeOff/getRequestedTimeOffs";
import { getRejectedTimeOffs } from "../../redux/actions/TimeOff/getRejectedTimeOffs";
import { getTimeOff } from "../../redux/actions/TimeOff/getTimeOff";
import { convertUNIXString } from "../../utils/convertUnixTimeStamp";
import {
  PlusOutlined,
  EllipsisOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import "./style.css";
import Schema from "async-validator";
import { useHistory } from "react-router-dom";

const TimeoffRequestListing = () => {
  const dispatch = useDispatch();
  Schema.warning = function () {};
  const history = useHistory();
  const onClickCreateTimeoffBtn = () => {
    history.push(`/timeoffrequest`);
  };
  const getTimeOffStatus = useSelector((state) => state.getTimeOff);
  const [record, setRecord] = useState("");
  const onFinish = (id) => {
    history.push(`/timeoffdetail/${id.id}`);
  };
  const onRequestedHandleChange = () => {
    dispatch(getRequestedTimeOffs()).then((result) => {
      setRecord(result.response.data);
    });
  };
  const onApprovedHandleChange = () => {
    dispatch(getApprovedTimeOffs()).then((result) => {
      setRecord(result.response.data);
    });
  };

  const onRejectedHandleChange = () => {
    dispatch(getRejectedTimeOffs()).then((result) => {
      setRecord(result.response.data);
    });
  };
  const onAllTimeOffsHandleChange = () => {
    dispatch(getTimeOff()).then((result) => {
      setRecord(result.response.data);
    });
  };
  useEffect(() => {
    if (getTimeOffStatus && getTimeOffStatus.status === 201) {
      setRecord(getTimeOffStatus.data);
    }
  }, [getTimeOffStatus]);

  useEffect(() => {
    dispatch(getTimeOff());
  }, [dispatch]);

  const getOrganizationStatus = useSelector((state) => state.getOrganization);
  const getApprovedTimeOffsStatus = useSelector(
    (state) => state.getApprovedTimeOffs
  );
  const [organizationDetails, setOrganizationDetails] = useState();
  const [userApprovedTimeoffs, setUserApprovedTimeoffs] = useState([]);
  const [currentUserApprovedTimeoffs, setCurrentUserApprovedTimeoffs] =
    useState([]);
  const [totalWorkDays, setTotalWorkDays] = useState(0);
  const [totalCurrentMonthWorkDays, setTotalCurrentMonthWorkDays] = useState(0);

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");

    if (user_id) {
      const filteredTimeoffs = userApprovedTimeoffs.filter(
        (timeoff) => String(timeoff.user_id) === user_id
      );
      setCurrentUserApprovedTimeoffs(filteredTimeoffs);
    }
  }, [userApprovedTimeoffs]);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    const currentMonthTimeoffs = currentUserApprovedTimeoffs.filter(
      (timeoff) => {
        const timeoffStartDate = new Date(timeoff.start_date * 1000);
        return (
          timeoffStartDate.getMonth() + 1 === currentMonth &&
          timeoffStartDate.getFullYear() === currentYear
        );
      }
    );

    const totalCurrentMonthDays = currentMonthTimeoffs.reduce(
      (total, timeoff) => total + timeoff.work_days,
      0
    );
    setTotalCurrentMonthWorkDays(totalCurrentMonthDays);
  }, [currentUserApprovedTimeoffs]);

  useEffect(() => {
    const workDaysArray = currentUserApprovedTimeoffs.map(
      (timeoff) => timeoff.work_days
    );
    const totalDays = workDaysArray.reduce((total, days) => total + days, 0);
    setTotalWorkDays(totalDays);
  }, [currentUserApprovedTimeoffs]);

  useEffect(() => {
    dispatch(getOrganization());
    dispatch(getApprovedTimeOffs());
  }, [dispatch]);

  useEffect(() => {
    if (getOrganizationStatus && getOrganizationStatus.status === 200) {
      setOrganizationDetails(getOrganizationStatus.data[0]);
    }
  }, [getOrganizationStatus]);
  useEffect(() => {
    if (getApprovedTimeOffsStatus && getApprovedTimeOffsStatus.status === 201) {
      setUserApprovedTimeoffs(getApprovedTimeOffsStatus.data);
    }
  }, [getApprovedTimeOffsStatus]);

  const menu = (
    <Menu>
      <Menu.Item key="0" className="pendingStyle">
        <a
          href="#/"
          onClick={() => {
            onRequestedHandleChange();
          }}
        >
          Pending
        </a>
      </Menu.Item>
      <Menu.Item key="1" className="approvedStyle">
        <a
          href="#/"
          onClick={() => {
            onApprovedHandleChange();
          }}
        >
          Approved
        </a>
      </Menu.Item>
      <Menu.Item key="2" className="rejectedStyle">
        <a
          href="#/"
          onClick={() => {
            onRejectedHandleChange();
          }}
        >
          Rejected
        </a>
      </Menu.Item>
      <Menu.Item key="3" className="onAllTimeOffStyle">
        <a
          href="#/"
          onClick={() => {
            onAllTimeOffsHandleChange();
          }}
        >
          All
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <h1 className="timeoff-request-heading">Time Off Request Listing</h1>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <div className="timeoff-count-div">
            <p className="count-heading-style">Total Leaves</p>
            <p className="remainingTimeoffCount">
              {organizationDetails && organizationDetails.time_off_request}{" "}
            </p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <div className="timeoff-count-div">
            <p className="count-heading-style">Leave Available</p>
            <p className="remainingTimeoffCount">
              {currentUserApprovedTimeoffs.length > 0
                ? currentUserApprovedTimeoffs[0]?.total_time_off
                : organizationDetails && organizationDetails.time_off_request}
            </p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <div className="timeoff-count-div">
            <p className="count-heading-style">Leaves Taken</p>
            <p className="remainingTimeoffCount">{totalWorkDays} </p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <div className="timeoff-count-div">
            <p className="count-heading-style">Used This Month</p>
            <p className="remainingTimeoffCount">
              {totalCurrentMonthWorkDays}{" "}
            </p>
          </div>
        </Col>
      </Row>
      <div className="time0ff-listing-mian">
        <Row>
          <Col span={12}>
            <div className="create-timeoff-btn">
              <Button
                type="contained"
                onClick={onClickCreateTimeoffBtn}
                style={{
                  borderRadius: "5px",
                  marginTop: "20px",
                }}
              >
                <PlusOutlined /> Create time off
              </Button>
            </div>
          </Col>
          <Col span={12}>
            <div className="filters-dropdown-style">
              <Dropdown overlay={menu} trigger={["click"]}>
                <a
                  href="#/"
                  className="filters"
                  onClick={(e) => e.preventDefault()}
                >
                  <EllipsisOutlined className="doticon" />
                </a>
              </Dropdown>
            </div>
          </Col>
        </Row>

        <div className="listing-section">
          {record.length === 0 ? (
            <p className="empty-timeoffs-listing">Timeoffs listing is empty.</p>
          ) : (
            [...record]
              .sort((a, b) => b.id - a.id)
              .map((value, uniId) => (
                <Row gutter={16} key={uniId}>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <div className="activitiesicon">
                      {value.status === "requested" && (
                        <EllipsisOutlined className="requestedIcon" />
                      )}
                      {value.status === "rejected" && (
                        <CloseOutlined className="rejectedIcon" />
                      )}
                      {value.status === "approved" && (
                        <CheckOutlined className="approvedIcon" />
                      )}
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                    <div
                      className="activitycontent"
                      onClick={(e) => onFinish(value)}
                      style={{ cursor: "pointer" }}
                    >
                      <p className="daterange">
                        {convertUNIXString(value.start_date)} -{" "}
                        {convertUNIXString(value.end_date)}
                      </p>
                      <div className="statusanddate">
                        {value.status === "requested" && (
                          <p className="requestedLeavestatus">{value.status}</p>
                        )}
                        {value.status === "rejected" && (
                          <p className="rejectedLeavestatus">{value.status}</p>
                        )}
                        {value.status === "approved" && (
                          <p className="approvedLeavestatus">{value.status}</p>
                        )}
                        <p className="activitydatetime"></p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <div className="activitycandidate">
                      <p className="candidatename">{value.user_name}</p>
                    </div>
                  </Col>
                </Row>
              ))
          )}
        </div>
      </div>
    </>
  );
};
export default withRouter(TimeoffRequestListing);
