import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createTeam } from "../../redux/actions/Organization/createTeam";
import { addTeamMember } from "../../redux/actions/Organization/addTeamMember";
import Loader from "../../components/Loader";
import Schema from "async-validator";
import "./style.css";

const CreateTeam = (props) => {
  Schema.warning = function () {};
  const { history } = props;
  const dispatch = useDispatch();
  const teamStatus = useSelector((state) => state.createTeam);
  const addTeamMemberStatus = useSelector((state) => state.addTeamMember);
  const [errorState, setErrorState] = useState([{ name: "", description: "" }]);
  const [addList, setAddList] = useState([]);
  const [addTeamMemberStatusTracker, setAddTeamMemberStatusTracker] =
    useState(true);

  const onFinish = (values) => {
    if (values) {
      setAddList(values.teamMembers);
      setErrorState({ error: "" });
      dispatch(createTeam(values));
    }
  };

  useEffect(() => {
    if (
      teamStatus &&
      addTeamMemberStatus &&
      addTeamMemberStatus.errorStatus === 422
    ) {
      setErrorState(teamStatus.data);
    }
  }, [teamStatus, addTeamMemberStatus]);

  useEffect(() => {
    if (
      teamStatus &&
      teamStatus.status === 200 &&
      addTeamMemberStatusTracker === true
    ) {
      message.success("team create successfully");

      dispatch(addTeamMember(addList, teamStatus.data.insertId));

      setAddTeamMemberStatusTracker(false);
    } else if (
      addTeamMemberStatus &&
      addTeamMemberStatus.successStatus === 201
    ) {
      message.success(
        `${addTeamMemberStatus.successEmail} added team member successfully`
      );
      history.push("/team/list");
    } else if (addTeamMemberStatus && addTeamMemberStatus.errorStatus === 404) {
      message.error(
        `${addTeamMemberStatus.failedEmail} is not member of organization`
      );
      history.push("/team/list");
    } else if (addTeamMemberStatus && addTeamMemberStatus.errorStatus === 403) {
      message.error(
        `${addTeamMemberStatus.failedEmail} is already added in this team`
      );
      history.push("/team/list");
    }
  }, [
    teamStatus,
    addList,
    addTeamMemberStatus,
    history,
    addTeamMemberStatusTracker,
    dispatch,
  ]);
  const { TextArea } = Input;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 20,
        offset: 8,
      },
    },
  };

  return (
    <>
      <h1 className="team-heading-styling">Create Team</h1>
      <div className="team-main">
        <Form
          {...formItemLayout}
          className="form-align"
          hideRequiredMark
          onFinish={onFinish}
        >
          <Form.Item
            label="Team Name"
            name="teamName"
            rules={[
              {
                min: 3,
                message: "Team Name name should be atleast 3 character!",
              },
              {
                max: 25,
                message:
                  "Team Name name should not be greater then 25 character!",
              },
            ]}
          >
            <Input id="teamName" required />
          </Form.Item>
          {errorState.teamName ? (
            <div className="err-msg" style={{ textAlign: "center" }}>
              Team{errorState.teamName}
            </div>
          ) : (
            ""
          )}

          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Please input your team description!",
              },
              {
                min: 3,
                message: "Team description should be atleast 3 character!",
              },
            ]}
          >
            <TextArea rows={2} id="teamDescription" />
          </Form.Item>
          {errorState.description ? (
            <span className="err-msg">Description{errorState.description}</span>
          ) : (
            ""
          )}

          <Form.Item
            label="Add Team Member"
            name="teamMembers"
            rules={[
              {
                required: true,
                message: "Please add atleast one team member!",
              },
            ]}
          >
            <Input type="email" id="teamMembers" />
          </Form.Item>

          <div style={{ marginBottom: "30px", marginTop: "30px" }}></div>
          <Form.Item {...tailFormItemLayout}>
            <Button
              id="createTeamBtn"
              className="createTeamBtn"
              type="primary"
              htmlType="submit"
            >
              Create Team
            </Button>
          </Form.Item>
        </Form>
        {teamStatus === true ? <Loader /> : ""}
      </div>
    </>
  );
};
export default withRouter(CreateTeam);
