import {
  GET_TIME_OFF_COUNT_SUCCESS,
  GET_TIME_OFF_COUNT_REQUEST,
  GET_TIME_OFF_COUNT_ERROR,
} from "../../actions/TimeOff/getTimeOffCount";

const getTimeOff = (state = null, action) => {
  switch (action.type) {
    case GET_TIME_OFF_COUNT_REQUEST: {
      return true;
    }
    case GET_TIME_OFF_COUNT_SUCCESS: {
      return action.response;
    }
    case GET_TIME_OFF_COUNT_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getTimeOff;
