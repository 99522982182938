import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";

export const GET_KEY_RESULT_SUCCESS = "GET_KEY_RESULT_SUCCESS";
export const GET_KEY_RESULT_ERROR = "GET_KEY_RESULT_ERROR";
export const GET_KEY_RESULT_REQUEST = "GET_KEY_RESULT_REQUEST";

export const getKeyResult = (id) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");
  dispatch({ type: GET_KEY_RESULT_REQUEST });
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/objectives/${id}/key_results`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      dispatch({
        type: GET_KEY_RESULT_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_KEY_RESULT_ERROR,
        response: error.response,
      });
    });
};
