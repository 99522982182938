import {
  UPDATE_TIME_OFF_DETAIL_SUCCESS,
  UPDATE_TIME_OFF_DETAIL_REQUEST,
  UPDATE_TIME_OFF_DETAIL_ERROR,
} from "../../actions/TimeOff/updateTimeOffDetail";

const updateTimeOffDetail = (state = null, action) => {
  switch (action.type) {
    case UPDATE_TIME_OFF_DETAIL_REQUEST: {
      return true;
    }
    case UPDATE_TIME_OFF_DETAIL_SUCCESS: {
      return action.response;
    }
    case UPDATE_TIME_OFF_DETAIL_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default updateTimeOffDetail;
