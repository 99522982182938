import {
  GET_HIGH_FIVE_LIKE_SUCCESS,
  GET_HIGH_FIVE_LIKE_ERROR,
  GET_HIGH_FIVE_LIKE_REQUEST,
} from "../../actions/HighFive/getHighFiveLike";

const getHighFiveLike = (state = null, action) => {
  switch (action.type) {
    case GET_HIGH_FIVE_LIKE_REQUEST: {
      return true;
    }
    case GET_HIGH_FIVE_LIKE_SUCCESS: {
      return action.response;
    }
    case GET_HIGH_FIVE_LIKE_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getHighFiveLike;
