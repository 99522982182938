import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";

export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST ";

export const loginUser = (user, first_name, last_name) => (dispatch) => {
  dispatch({ type: USER_LOGIN_REQUEST });
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/me`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
      email: user,
    },
    data: {
      first_name: first_name,
      last_name: last_name,
    },
  })
    .then((response) => {
      localStorage.setItem("currentUserName", response.data.first_name);
      localStorage.setItem("currentUserRole", response.data.data[0].user_role);
      localStorage.setItem("designation", response.data.designation);
      localStorage.setItem("email", response.data.data[0].email);
      localStorage.setItem("user_id", response.data.data[0].id);
      localStorage.setItem(
        "organization_id",
        response.data.data[0].organization_id
      );
      localStorage.setItem("image", response.data.image.orignal);
      return dispatch({ type: USER_LOGIN_SUCCESS, response: response });
    })
    .catch((error) => {
      return dispatch({ type: USER_LOGIN_ERROR, response: error.response });
    });
};
