import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
export const GET_CHEKINS_SUCCESS = "GET_CHEKINS_SUCCESS";
export const GET_CHEKINS_ERROR = "GET_CHEKINS_ERROR";
export const GET_CHEKINS_REQUEST = "GET_CHEKINS_REQUEST ";

export const getCheckins = (user_id) => (dispatch) => {
  dispatch({ type: GET_CHEKINS_REQUEST });
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/check_ins`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  }).then((response) => {
    return dispatch({
      type: GET_CHEKINS_SUCCESS,
      response: response,
    });
  });
  // .catch((error) => {
  //   if (error.response.status === 401) {
  //     dispatch(tokenAuthFailedAction());
  //   }
  //   return dispatch({
  //     type: GET_CHEKINS_ERROR,
  //     response: error.response,
  //   });
  // });
};
