import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
export const CREATE_WEEKLY_GOAL_SUCCESS = "CREATE_WEEKLY_GOAL_SUCCESS";
export const CREATE_WEEKLY_GOAL_ERROR = "CREATE_WEEKLY_GOAL_ERROR";
export const CREATE_WEEKLY_GOAL_REQUEST = "CREATE_WEEKLY_GOAL_REQUEST";
export const CREATE_WEEKLY_GOAL_RESET = "CREATE_WEEKLY_GOAL_RESET";

export const createWeeklyGoal =
  (weeklyGoal, startDate, endDate) => (dispatch) => {
    const user_id = localStorage.getItem("user_id");
    // const dateStr = weeklyGoal.startDate;
    // const startdate = new Date(dateStr);
    // const startDate = Math.floor(startdate.getTime() / 1000);

    // const endDateStr = weeklyGoal.endDate;
    // const enddate = new Date(endDateStr);
    // const endDate = Math.floor(enddate.getTime() / 1000);

    dispatch({ type: CREATE_WEEKLY_GOAL_REQUEST });
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/weekly_goals`,
      data: {
        startDate: startDate,
        endDate: endDate,
        description: weeklyGoal.description,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "access-token": readLocalStorage("access-token"),
      },
    })
      .then((response) => {
        dispatch({
          type: CREATE_WEEKLY_GOAL_SUCCESS,
          response: response,
        });
        dispatch({ type: CREATE_WEEKLY_GOAL_RESET });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(tokenAuthFailedAction());
        }
        dispatch({
          type: CREATE_WEEKLY_GOAL_ERROR,
          response: error.response,
        });
        dispatch({ type: CREATE_WEEKLY_GOAL_RESET });
      });
  };
