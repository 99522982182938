import {
  GET_ORGANIZATION_HIERARCHY_SUCCESS,
  GET_ORGANIZATION_HIERARCHY_REQUEST,
  GET_ORGANIZATION_HIERARCHY_ERROR,
} from "../../actions/Organization/getOrganizationHierarchy";

const getOrganizationHierarchy = (state = null, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_HIERARCHY_REQUEST: {
      return true;
    }
    case GET_ORGANIZATION_HIERARCHY_SUCCESS: {
      return action.response;
    }
    case GET_ORGANIZATION_HIERARCHY_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getOrganizationHierarchy;
