import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";

import moment from "moment";
export const CREATE_PERFORMANCE_REVIEW_SUCCESS =
  "CREATE_PERFORMANCE_REVIEW_SUCCESS";
export const CREATE_PERFORMANCE_REVIEW_ERROR =
  "CREATE_PERFORMANCE_REVIEW_ERROR";
export const CREATE_PERFORMANCE_REVIEW_REQUEST =
  "CREATE_PERFORMANCE_REVIEW_REQUEST";

export const createPerformanceReview =
  (user, startDate, endDate) => (dispatch) => {
    const user_id = localStorage.getItem("user_id");

    // let start_date = moment(performanceReview.dateRange[0]).unix();
    // let end_date = moment(performanceReview.dateRange[1]).unix();

    dispatch({ type: CREATE_PERFORMANCE_REVIEW_REQUEST });
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/performance_review`,
      data: {
        employee_id: user,
        start_date: startDate,
        end_date: endDate,
      },

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "access-token": readLocalStorage("access-token"),
      },
    })
      .then((response) => {
        dispatch({
          type: CREATE_PERFORMANCE_REVIEW_SUCCESS,
          response: response,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(tokenAuthFailedAction());
        }
        dispatch({
          type: CREATE_PERFORMANCE_REVIEW_ERROR,
          response: error.response,
        });
      });
  };
