import {
    DELETE_TEAM_MEMBER_SUCCESS,
    DELETE_TEAM_MEMBER_REQUEST,
    DELETE_TEAM_MEMBER_ERROR,
  } from "../../actions/Team/deleteTeamMember";
  
  const deleteTeamMembers = (state = null, action) => {
    switch (action.type) {
      case DELETE_TEAM_MEMBER_REQUEST: {
        return true;
      }
      case DELETE_TEAM_MEMBER_SUCCESS: {
        return action.response;
      }
      case DELETE_TEAM_MEMBER_ERROR: {
        return action.response;
      }
      default:
        return state;
    }
  };
  
  export default deleteTeamMembers;
  