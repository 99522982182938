import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";

export const GET_HIGH_FIVE_SUCCESS = "GET_HIGH_FIVE_SUCCESS";
export const GET_HIGH_FIVE_ERROR = "GET_HIGH_FIVE_ERROR";
export const GET_HIGH_FIVE_REQUEST = "GET_HIGH_FIVE_REQUEST";

export const getHighFive =
  (user, status, start_date, end_date) => (dispatch) => {
    const user_id = localStorage.getItem("user_id");
    let url;

    if (user === "") {
      url = `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/high_fives`;
    }

    if (user !== "" && start_date === undefined && status === undefined) {
      url = `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/high_fives?user_id=${user}`;
    }

    if (
      (user !== "" && status === "sent") ||
      (status === "received" && start_date === undefined)
    ) {
      url = `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/high_fives?user_id=${user}&status=${status}`;
    }

    if (
      user !== undefined &&
      status !== undefined &&
      start_date !== "" &&
      start_date !== undefined &&
      end_date !== "" &&
      end_date !== undefined
    ) {
      url = `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/high_fives?user_id=${user}&status=${status}&start_date=${start_date}&end_date=${end_date}`;
    }

    dispatch({ type: GET_HIGH_FIVE_REQUEST });
    return axios({
      method: "get",
      url: url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "access-token": readLocalStorage("access-token"),
      },
    })
      .then((response) => {
        dispatch({
          type: GET_HIGH_FIVE_SUCCESS,
          response: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_HIGH_FIVE_ERROR,
          response: error.response,
        });
      });
  };
