import React, { useEffect, useState, useRef } from "react";
import { Button, Typography, Input, Form, message, Row, Col } from "antd";
import { withRouter, useHistory } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import registerLeft from "../../assets/register.png";
import { useDispatch, useSelector } from "react-redux";
import { createOtp } from "../../redux/actions/Otp/createOtp";
import { verifyOtp } from "../../redux/actions/Otp/verifyOtp";
import "./style.css";
import CryptoJS from "crypto-js";

function MyTimer({ expiryTimestamp }) {
  const [isExpired, setIsExpired] = useState(false);

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => setIsExpired(true),
  });

  useEffect(() => {
    restart(expiryTimestamp);
    setIsExpired(false);
  }, [expiryTimestamp, restart]);

  return (
    <div style={{ textAlign: "center" }}>
      {isExpired ? (
        <Typography className="expire-otp" style={{ color: "red" }}>
          OTP Expired
        </Typography>
      ) : (
        <Typography className="expire-otp">
          OTP will expire in{" "}
          <span className="time-style">
            {minutes}:{seconds}
          </span>{" "}
          minutes
        </Typography>
      )}
    </div>
  );
}

function ResendTimer({ expiryTimestamp, onExpire, onResend }) {
  const { seconds, isRunning, restart } = useTimer({
    expiryTimestamp,
    onExpire,
  });

  useEffect(() => {
    restart(expiryTimestamp);
  }, [expiryTimestamp, restart]);

  return (
    <>
      {isRunning ? (
        <div className="resend-code-div">
          <p>Resend code in</p>
          <Typography
            style={{
              fontSize: "14px",
              marginLeft: "5px",
              color: "#1890ff",
              fontWeight: "bold",
            }}
          >
            0:{seconds}
          </Typography>
        </div>
      ) : (
        <Button style={{ marginTop: "-4px" }} type="link" onClick={onResend}>
          Resend
        </Button>
      )}
    </>
  );
}

const Otp = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [otpInputOne, setOtpInputOne] = useState("");
  const [otpInputTwo, setOtpInputTwo] = useState("");
  const [otpInputThree, setOtpInputThree] = useState("");
  const [otpInputFour, setOtpInputFour] = useState("");

  const [otpExpiryTime, setOtpExpiryTime] = useState(
    new Date().getTime() + 5 * 60 * 1000
  );
  const [resendTimestamp, setResendTimestamp] = useState(
    new Date().getTime() + 20 * 1000
  );
  const user_email = localStorage.getItem("email");

  const dispatch = useDispatch();

  const inputOneRef = useRef();
  const inputTwoRef = useRef();
  const inputThreeRef = useRef();
  const inputFourRef = useRef();
  const createOtpStatus = useSelector((state) => state.createOtp);

  useEffect(() => {
    if (createOtpStatus && createOtpStatus.status === 200) {
      const secretKey = "qwerty";
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(createOtpStatus.data.otp),
        secretKey
      ).toString();
      localStorage.setItem("userOtp", encryptedData);
    }
  }, [createOtpStatus]);

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData("text");
    if (paste.length === 4 && /^\d+$/.test(paste)) {
      setOtpInputOne(paste[0]);
      setOtpInputTwo(paste[1]);
      setOtpInputThree(paste[2]);
      setOtpInputFour(paste[3]);

      form.setFieldsValue({
        otpOne: paste[0],
        otpTwo: paste[1],
        otpThree: paste[2],
        otpFour: paste[3],
      });

      e.preventDefault();
    }
  };

  const onChangeOtpInput = (e, nextInputRef, prevInputRef) => {
    const { value, name } = e.target;

    switch (name) {
      case "otpOne":
        setOtpInputOne(value);
        break;
      case "otpTwo":
        setOtpInputTwo(value);
        break;
      case "otpThree":
        setOtpInputThree(value);
        break;
      case "otpFour":
        setOtpInputFour(value);
        break;
      default:
        break;
    }

    if (value && nextInputRef && nextInputRef.current) {
      nextInputRef.current.focus();
    }
  };

  const handleKeyDown = (e, prevInputRef) => {
    if (e.key === "Backspace" && !e.target.value) {
      if (prevInputRef && prevInputRef.current) {
        prevInputRef.current.focus();
      }
    }
  };

  const onFinish = () => {
    const retrievedData = localStorage.getItem("userOtp");
    const secretKey = "qwerty";
    const bytes = CryptoJS.AES.decrypt(retrievedData, secretKey);

    const userOtp = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    console.log("userOtp", userOtp);

    const otp = otpInputOne + otpInputTwo + otpInputThree + otpInputFour;
    if (otp === userOtp) {
      dispatch(verifyOtp(user_email, userOtp));
      localStorage.setItem("verifiedOtp", userOtp);
      message.success({
        content: "You are successfully logged in",
        duration: 3,
      });
      const orgId = localStorage.getItem("organization_id");
      if (orgId !== "null") {
        history.push("/dashboard");
      } else {
        history.push("/organizationregistration");
      }
    } else {
      alert("You entered wrong OTP");
    }
  };

  const handleResend = () => {
    const newOtpExpiryTime = new Date().getTime() + 5 * 60 * 1000;
    const newResendTime = new Date().getTime() + 20 * 1000;
    setOtpExpiryTime(newOtpExpiryTime);
    setResendTimestamp(newResendTime);

    var date = new Date();
    date.setTime(newOtpExpiryTime);
    var expiry = "; expires=" + date.toUTCString();
    document.cookie = "otp=" + user_email + expiry + "; path=/";

    dispatch(createOtp(user_email));
  };

  return (
    <>
      <Row>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <div className="register-left">
            <div className="register-left-content">
              <img src={registerLeft} alt="register" />
              <h1 className="registration-heading-styling">
                Welcome to High-Knot
              </h1>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <div className="otp-right">
            <div className="otp">
              <Typography className="otpTitle">Enter OTP</Typography>
              <Typography className="otpDescription">
                Enter 4 digit verification code sent to your email.
              </Typography>
              <Form onFinish={onFinish} name="otpForm" form={form}>
                <div className="otpInputs">
                  <Form.Item
                    name="otpOne"
                    className="otpInput"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input
                      placeholder=""
                      type="text"
                      maxLength="1"
                      style={{ textAlign: "center", fontSize: "24px" }}
                      onChange={(e) => onChangeOtpInput(e, inputTwoRef, null)}
                      onKeyDown={(e) => handleKeyDown(e, null)}
                      onPaste={handlePaste}
                      name="otpOne"
                      value={otpInputOne}
                      ref={inputOneRef}
                    />
                  </Form.Item>
                  <Form.Item
                    name="otpTwo"
                    className="otpInput"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input
                      placeholder=""
                      type="text"
                      maxLength="1"
                      style={{ textAlign: "center", fontSize: "24px" }}
                      onChange={(e) =>
                        onChangeOtpInput(e, inputThreeRef, inputOneRef)
                      }
                      onKeyDown={(e) => handleKeyDown(e, inputOneRef)}
                      onPaste={handlePaste}
                      ref={inputTwoRef}
                      name="otpTwo"
                      value={otpInputTwo}
                    />
                  </Form.Item>
                  <Form.Item
                    name="otpThree"
                    className="otpInput"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input
                      placeholder=""
                      type="text"
                      maxLength="1"
                      style={{ textAlign: "center", fontSize: "24px" }}
                      onChange={(e) =>
                        onChangeOtpInput(e, inputFourRef, inputTwoRef)
                      }
                      onKeyDown={(e) => handleKeyDown(e, inputTwoRef)}
                      onPaste={handlePaste}
                      ref={inputThreeRef}
                      name="otpThree"
                      value={otpInputThree}
                    />
                  </Form.Item>
                  <Form.Item
                    name="otpFour"
                    className="otpInput"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input
                      placeholder=""
                      type="text"
                      maxLength="1"
                      style={{ textAlign: "center", fontSize: "24px" }}
                      onChange={(e) => onChangeOtpInput(e, null, inputThreeRef)}
                      onKeyDown={(e) => handleKeyDown(e, inputThreeRef)}
                      onPaste={handlePaste}
                      ref={inputFourRef}
                      name="otpFour"
                      value={otpInputFour}
                    />
                  </Form.Item>
                </div>
                <div className="resend-code">
                  <div className="timer">
                    <ResendTimer
                      expiryTimestamp={new Date(resendTimestamp)}
                      onExpire={() => {}}
                      onResend={handleResend}
                    />
                  </div>
                  <div className="timer">
                    <MyTimer
                      expiryTimestamp={otpExpiryTime}
                      onExpire={() => alert("OTP expired")}
                    />
                  </div>
                  <Button
                    htmlType="submit"
                    className="submitOtpButton"
                    type="primary"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(Otp);
