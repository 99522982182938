import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { convertUNIXString } from "../../utils/convertUnixTimeStamp";
import { getPerformanceReview } from "../../redux/actions/PerformanceReview/getPerformanceReview";

import { useHistory } from "react-router-dom";

import "./style.css";

const PerformanceReviewListing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [reviews, setReviews] = useState([]);
  const getPerformanceReviewStatus = useSelector(
    (state) => state.getPerformanceReview
  );

  useEffect(() => {
    if (
      getPerformanceReviewStatus &&
      getPerformanceReviewStatus.status === 200
    ) {
      const sortedReviews = getPerformanceReviewStatus.data.sort(
        (a, b) => b.id - a.id
      );
      setReviews(sortedReviews);
    }
  }, [getPerformanceReviewStatus]);

  useEffect(() => {
    dispatch(getPerformanceReview());
  }, [dispatch]);

  function getMonthAndYear(dateString) {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${month} ${year}`;
  }
  const onClickReview = (id) => {
    history.push(`./viewperformancereview/${id}`);
  };

  return (
    <>
      <h1 className="performance-review-heading">Performance Review</h1>
      <Button
        className="create-performance-review-button"
        onClick={() => history.push(`/create_performancereview`)}
      >
        Create Performance Evaluations
      </Button>
      <div className="performance-review-listing">
        <Row gutter={16}>
          {reviews.map((value, index) => {
            const startDate = convertUNIXString(value.start_date);
            const endDate = convertUNIXString(value.end_date);
            const startDateMonthYear = getMonthAndYear(startDate);
            const endDateMonthYear = getMonthAndYear(endDate);
            return (
              <Col xs={24} sm={24} md={12} lg={6} xl={6} key={index}>
                <div
                  className="performance-review-div"
                  onClick={() => onClickReview(value && value.id)}
                >
                  <p className="performance-review-date">
                    {startDateMonthYear} - {endDateMonthYear}
                  </p>
                  <div className="performance-evaluation-user">
                    <Row gutter={16}>
                      <Col xs={12} sm={12} md={12} lg={16} xl={16}>
                        <p className="evaluation-user"> {value.user_name}</p>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                        <div className="evaluation">
                          <div className="evaluatedEmoji">
                            {value.avg_rating === 1 && <p>😢</p>}
                            {value.avg_rating === 2 && <p>☹️</p>}
                            {value.avg_rating === 3 && <p>😐</p>}
                            {value.avg_rating === 4 && <p>😎</p>}
                            {value.avg_rating === 5 && <p>🤩</p>}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
};

export default PerformanceReviewListing;
