import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getHighFive } from "../../redux/actions/HighFive/getHighFive";
import DatePickerCalender from "react-datepicker";
import userDummy from "../../assets/user.png";
import "./style.css";

const ViewHighFive = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const [highFiveDetails, setHighFiveDetails] = useState([]);
  const getHighFiveStatus = useSelector((state) => state.getHighFive);

  useEffect(() => {
    dispatch(getHighFive("", "", ""));
  }, [dispatch]);

  useEffect(() => {
    if (getHighFiveStatus && getHighFiveStatus.status === 200) {
      setHighFiveDetails(getHighFiveStatus.data[8]);
    }
  }, [getHighFiveStatus]);

  const formattedStartDate =
    highFiveDetails && highFiveDetails.date
      ? new Date(highFiveDetails.date)
          .toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, "-")
      : null;

  const startDate =
    formattedStartDate && formattedStartDate !== "Invalid Date"
      ? new Date(formattedStartDate)
      : null;

  return (
    <>
      <h1 className="highFivesTitle">View High Five 🙌🏻</h1>

      <Row gutter={16}>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={18} xl={18}>
          <div className="viewHighFive">
            <div className="view-highfive-content">
              <img
                src={
                  highFiveDetails.from_user_image
                    ? highFiveDetails.from_user_image
                    : userDummy
                }
                alt=""
                className="highfive-users"
              />
              <p className="highfive-icon">🙌🏻</p>
              <img
                src={
                  highFiveDetails.to_user_image
                    ? highFiveDetails.to_user_image
                    : userDummy
                }
                alt=""
                className="highfive-users"
              />
            </div>
            <div className="comment-div">
              <p>Comment: </p>
              <p className="highfive-comment">{highFiveDetails.coment}</p>
            </div>
          </div>
        </Col>

        <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
          <div className="view-high-five-calender">
            <div className="date-picker-stle">
              <DatePickerCalender
                monthsShown={1}
                width={100}
                selectsRange
                inline
                disabledKeyboardNavigation
                highlightDates={startDate ? [startDate] : []}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ViewHighFive;
