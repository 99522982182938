import React, { useRef, useState, useEffect } from "react";
import JSONDigger from "json-digger";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import OrganizationChart from "../../components/OrganizationHierarchyChart/ChartContainer";
import { getUsers } from "../../redux/actions/User/getUsers";
import { Select, message } from "antd";
import { updateOrganizationHierarchy } from "../../redux/actions/Organization/updateOrganizationHierarchy";
import { getOrganizationHierarchy } from "../../redux/actions/Organization/getOrganizationHierarchy";
import "./style.css";

const OrganizationHierarchy = () => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [UsersList, setUsersList] = useState([]);
  const getUsersList = useSelector((state) => state.getUsers);
  const getOrganizationHierarchyStatus = useSelector(
    (state) => state.getOrganizationHierarchy
  );

  function onChange(value) {
    let getUserData = UsersList.find((user) => user.id === value);
    if (getUserData) {
      const updatedNodes = [
        {
          id: uuidv4(),
          name: getUserData.first_name,
          title: getUserData.designation,
          img: getUserData.image,
        },
      ];
      setNewNodes(updatedNodes);
    }
  }

  useEffect(() => {
    if (
      getOrganizationHierarchyStatus &&
      getOrganizationHierarchyStatus.status === 200 &&
      getOrganizationHierarchyStatus.data &&
      getOrganizationHierarchyStatus.data.length > 0
    ) {
      setDS(JSON.parse(getOrganizationHierarchyStatus.data[0].hierarchy));
    }
  }, [getOrganizationHierarchyStatus]);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getOrganizationHierarchy());
  }, [dispatch]);

  useEffect(() => {
    if (getUsersList && getUsersList.status === 200) {
      setUsersList(getUsersList.data);
    }
  }, [getUsersList]);

  const orgchart = useRef();
  const datasource = {
    id: "n1",
    name: "Organization",
    title: localStorage.getItem("organization_id"),
    img: localStorage.getItem("image"),
    relationship: "001",
    children: [
      {
        name: localStorage.getItem("currentUserName"),
        title: "Owner",
        img: localStorage.getItem("image"),
        id: "34c75eb7-7cb5-4ac4-a997-420b5353bf87",
        relationship: "100",
      },
    ],
  };
  const [ds, setDS] = useState(datasource);
  const dsDigger = new JSONDigger(ds, "id", "children");
  const [selectedNodes, setSelectedNodes] = useState(new Set());
  const [newNodes, setNewNodes] = useState([
    {
      id: uuidv4(),
      name: "",
      title: "",
      img: "",
    },
  ]);

  const readSelectedNode = (nodeData) => {
    setSelectedNodes(new Set([nodeData]));
  };

  const clearSelectedNode = () => {
    setSelectedNodes(new Set());
  };

  const getNewNodes = () => {
    const nodes = [];
    for (const node of newNodes) {
      nodes.push({ ...node, id: uuidv4() });
    }
    return nodes;
  };

  const addChildNodes = async () => {
    if (selectedNodes.size > 0) {
      const nodesToAdd = getNewNodes();
      await dsDigger.addChildren([...selectedNodes][0].id, nodesToAdd);
      setDS({ ...dsDigger.ds });
      dispatch(updateOrganizationHierarchy(dsDigger.ds));
    } else {
      message.error("Please select a node before adding child nodes.");
    }
  };

  const addSiblingNodes = async () => {
    if (selectedNodes.size > 0) {
      const nodesToAdd = getNewNodes();
      await dsDigger.addSiblings([...selectedNodes][0].id, nodesToAdd);
      setDS({ ...dsDigger.ds });
      dispatch(updateOrganizationHierarchy(dsDigger.ds));
    } else {
      message.error("Please select a node before adding sibling nodes.");
    }
  };

  const remove = async () => {
    if (selectedNodes.size > 0) {
      await dsDigger.removeNodes([...selectedNodes].map((node) => node.id));
      setDS({ ...dsDigger.ds });
      setSelectedNodes(new Set());
      dispatch(updateOrganizationHierarchy(dsDigger.ds));
    } else {
      message.error("Please select a node before removing.");
    }
  };

  return (
    <div className="edit-chart-wrapper">
      <section className="toolbar">
        <div className="new-nodes">
          {localStorage.getItem("currentUserRole") === "owner" && (
            <Select
              showSearch
              onChange={onChange}
              placeholder="Select a user"
              className="search"
            >
              {UsersList &&
                UsersList.map((value) => (
                  <Option value={value.id} key={value.id}>
                    {value.last_name}
                  </Option>
                ))}
            </Select>
          )}
        </div>
        <div className="action-buttons">
          {localStorage.getItem("currentUserRole") === "owner" && (
            <button onClick={addChildNodes}>Add Child User</button>
          )}
          {localStorage.getItem("currentUserRole") === "owner" && (
            <button onClick={addSiblingNodes}>Add Sibling User</button>
          )}
          {localStorage.getItem("currentUserRole") === "owner" && (
            <button onClick={remove}>Remove User</button>
          )}
        </div>
      </section>
      <OrganizationChart
        ref={orgchart}
        datasource={ds}
        onClickNode={readSelectedNode}
        onClickChart={clearSelectedNode}
      />
    </div>
  );
};

export default OrganizationHierarchy;
