import React, { useState, useEffect } from "react";
import { Row, Col, message } from "antd";
import { withRouter } from "react-router-dom";
import { getTimeoffDetail } from "../../redux/actions/TimeOff/getTimeoffDetail";
import { getOrganization } from "../../redux/actions/Organization/getOrganization";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { updateTimeOffDetail } from "../../redux/actions/TimeOff/updateTimeOffDetail";
import { convertUNIXString } from "../../utils/convertUnixTimeStamp";
import { getApprovedTimeOffs } from "../../redux/actions/TimeOff/getApprovedTimeOffs";
import "./style.css";

const { Option } = Select;

const TimeoffDetail = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const getTimeOffDetailStatus = useSelector((state) => state.getTimeoffDetail);
  const updateTimeOffDetailStatus = useSelector(
    (state) => state.updateTimeOffDetail
  );
  const [value, setValue] = useState([]);

  useEffect(() => {
    if (updateTimeOffDetailStatus && updateTimeOffDetailStatus.status === 200) {
      message.success({
        content: "update successfully",
        duration: 1,
      });
    }
  }, [updateTimeOffDetailStatus]);

  const onFinish = (e) => {
    if (value.work_days > value.total_time_off) {
      message.error(
        "Sorry! you can't approve or reject time of request because you don't have more work days."
      );
    } else {
      dispatch(updateTimeOffDetail(e));
      history.push(`/dashboard`);
    }
  };
  useEffect(() => {
    dispatch(getTimeoffDetail());
    dispatch(getOrganization());
    dispatch(getApprovedTimeOffs());
  }, [dispatch]);

  useEffect(() => {
    if (getTimeOffDetailStatus && getTimeOffDetailStatus.status === 200) {
      setValue(getTimeOffDetailStatus.data[0] || {});
    }
  }, [getTimeOffDetailStatus]);

  useEffect(() => {
    if (getTimeOffDetailStatus && getTimeOffDetailStatus.status === 500) {
      alert("permissiondenied");
      history.push(`/dashboard`);
    }
  }, [getTimeOffDetailStatus, history]);
  const getOrganizationStatus = useSelector((state) => state.getOrganization);
  const getApprovedTimeOffsStatus = useSelector(
    (state) => state.getApprovedTimeOffs
  );
  const [organizationDetails, setOrganizationDetails] = useState();
  useEffect(() => {
    if (getOrganizationStatus && getOrganizationStatus.status === 200) {
      setOrganizationDetails(getOrganizationStatus.data[0]);
    }
  }, [getOrganizationStatus]);
  useEffect(() => {
    if (getApprovedTimeOffsStatus && getApprovedTimeOffsStatus.status === 201) {
      setUserApprovedTimeoffs(getApprovedTimeOffsStatus.data);
    }
  }, [getApprovedTimeOffsStatus]);
  const [userApprovedTimeoffs, setUserApprovedTimeoffs] = useState([]);
  const [currentUserApprovedTimeoffs, setCurrentUserApprovedTimeoffs] =
    useState([]);
  const [totalWorkDays, setTotalWorkDays] = useState(0);
  const [totalCurrentMonthWorkDays, setTotalCurrentMonthWorkDays] = useState(0);

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");

    if (user_id) {
      const filteredTimeoffs = userApprovedTimeoffs.filter(
        (timeoff) => String(timeoff.user_id) === user_id
      );
      setCurrentUserApprovedTimeoffs(filteredTimeoffs);
    }
  }, [userApprovedTimeoffs]);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    const currentMonthTimeoffs = currentUserApprovedTimeoffs.filter(
      (timeoff) => {
        const timeoffStartDate = new Date(timeoff.start_date * 1000);
        return (
          timeoffStartDate.getMonth() + 1 === currentMonth &&
          timeoffStartDate.getFullYear() === currentYear
        );
      }
    );

    const totalCurrentMonthDays = currentMonthTimeoffs.reduce(
      (total, timeoff) => total + timeoff.work_days,
      0
    );
    setTotalCurrentMonthWorkDays(totalCurrentMonthDays);
  }, [currentUserApprovedTimeoffs]);

  useEffect(() => {
    const workDaysArray = currentUserApprovedTimeoffs.map(
      (timeoff) => timeoff.work_days
    );
    const totalDays = workDaysArray.reduce((total, days) => total + days, 0);
    setTotalWorkDays(totalDays);
  }, [currentUserApprovedTimeoffs]);
  const user_id = localStorage.getItem("user_id");

  return (
    <>
      <h1 className="timeoff-request-heading">View Time Off Request</h1>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <div className="timeoff-count-div">
            <p className="count-heading-style">Total Leaves</p>
            <p className="remainingTimeoffCount">
              {organizationDetails && organizationDetails.time_off_request}{" "}
            </p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <div className="timeoff-count-div">
            <p className="count-heading-style">Leave Available</p>
            <p className="remainingTimeoffCount">
              {currentUserApprovedTimeoffs.length > 0
                ? currentUserApprovedTimeoffs[0]?.total_time_off
                : organizationDetails && organizationDetails.time_off_request}
            </p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <div className="timeoff-count-div">
            <p className="count-heading-style">Leaves Taken</p>
            <p className="remainingTimeoffCount">{totalWorkDays} </p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <div className="timeoff-count-div">
            <p className="count-heading-style">Used This Month</p>
            <p className="remainingTimeoffCount">
              {totalCurrentMonthWorkDays}{" "}
            </p>
          </div>
        </Col>
      </Row>
      <div className="view-timeoff-request">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <div className="view-timeoff-request-values">
              <div className="timeoff-value-div">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <p className="timeoff-label">Start Date:</p>
                  </Col>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <p className="timeoff-value">
                      {convertUNIXString(value.start_date)}
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="timeoff-value-div">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <p className="timeoff-label">End Date:</p>
                  </Col>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <p className="timeoff-value">
                      {convertUNIXString(value.end_date)}
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="timeoff-value-div">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <p className="timeoff-label">Leave Duration:</p>
                  </Col>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <p className="timeoff-value">
                      {value.leave_duration === "first_half"
                        ? "First Half"
                        : ""}
                      {value.leave_duration === "after_lunch"
                        ? "After Lunch"
                        : ""}
                      {value.leave_duration === "full_day" ? "Full  Day" : ""}
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="timeoff-value-div">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <p className="timeoff-label">Leave Type:</p>
                  </Col>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <p className="timeoff-value">
                      {value.leave_type === "sick_leave" ? "Sick  Leave" : ""}
                      {value.leave_type === "emergency_leave"
                        ? "Emergency Leave"
                        : ""}
                      {value.leave_type === "vocational_leave"
                        ? "Vocational Leave"
                        : ""}
                      {value.leave_type === "annual_leave"
                        ? "Annual Leave"
                        : ""}
                      {value.leave_type === "other_leave" ? value.other : ""}
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="timeoff-value-div">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <p className="timeoff-label">Work Days:</p>
                  </Col>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <p className="timeoff-value">
                      {value ? value.work_days : ""}
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="timeoff-value-div">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <p className="timeoff-label">Note:</p>
                  </Col>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <p className="timeoff-value">{value ? value.note : ""}</p>
                  </Col>
                </Row>
              </div>{" "}
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div className="timeoff-status-value">
              <p className="timeoff-label">Status:</p>
              <div className="select-status">
                {(value &&
                  value.status === "requested" &&
                  localStorage.getItem("currentUserRole") === "owner") ||
                (localStorage.getItem("currentUserRole") === "manager" &&
                  parseInt(user_id, 10) !== value.user_id) ? (
                  <>
                    <Select
                      onChange={onFinish}
                      style={{ width: 120 }}
                      placeholder="status"
                    >
                      <Option value="approved">Approve</Option>
                      <Option value="rejected">Reject</Option>
                    </Select>
                  </>
                ) : (
                  <div>
                    <p>{value && value.status}</p>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default withRouter(TimeoffDetail);
