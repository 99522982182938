import React, { useEffect, useState } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Empty,
  message,
  DatePicker,
  Dropdown,
  Menu,
} from "antd";
import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getWeeklyGoals } from "../../redux/actions/Organization/weeklyGoalsList";
import { getUsers } from "../../redux/actions/User/getUsers";
import { convertUNIXString } from "../../utils/convertUnixTimeStamp";
import "./style.css";

const { RangePicker } = DatePicker;

const GoalLists = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const goals = useSelector((state) => state.weeklyGoalList);
  const deleteWeeklyGoalStatus = useSelector((state) => state.deleteWeeklyGoal);
  const [UsersList, setUsersList] = useState([]);
  const getUsersList = useSelector((state) => state.getUsers);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [filteredGoals, setFilteredGoals] = useState(null);

  useEffect(() => {
    if (deleteWeeklyGoalStatus && deleteWeeklyGoalStatus.status === 204) {
      dispatch(getWeeklyGoals());
    }
  }, [deleteWeeklyGoalStatus, dispatch]);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    if (getUsersList && getUsersList.status === 200) {
      setUsersList(getUsersList.data);
    }
  }, [getUsersList]);

  useEffect(() => {
    if (deleteWeeklyGoalStatus && deleteWeeklyGoalStatus === 403) {
      message.error("You don't have permission to delete this goal");
    }
  }, [deleteWeeklyGoalStatus]);

  const mapUserIdToUserData = (userId) => {
    const user = UsersList.find((user) => user.id === userId);

    if (user) {
      return {
        name: `${user.first_name} ${user.last_name}`,
      };
    } else {
      return { name: "Unknown User" };
    }
  };

  useEffect(() => {
    dispatch(getWeeklyGoals());
  }, [dispatch]);

  useEffect(() => {
    if (goals && goals.data) {
      let filtered = goals.data;

      if (selectedDateRange) {
        filtered = filtered.filter((goal) => {
          const goalStartDate = new Date(goal.startDate);
          const goalEndDate = new Date(goal.endDate);
          return (
            goalStartDate >= selectedDateRange[0] &&
            goalEndDate <= selectedDateRange[1]
          );
        });
      } else if (selectedMonth !== null) {
        filtered = filtered.filter((goal) => {
          const goalMonth = new Date(goal.startDate).getMonth();
          return goalMonth === selectedMonth;
        });
      } else if (selectedYear !== null) {
        filtered = filtered.filter((goal) => {
          const goalYear = new Date(goal.startDate).getFullYear();
          return goalYear === selectedYear;
        });
      }

      setFilteredGoals(filtered);
    } else {
      setFilteredGoals(goals && goals.data);
    }
  }, [goals, selectedDateRange, selectedMonth, selectedYear]);

  const handleDateRangeChange = (dates) => {
    setSelectedDateRange(dates);
    setSelectedMonth(null);
    setSelectedYear(null);
  };

  const handleMonthSelect = ({ key }) => {
    setSelectedMonth(Number(key));
    setSelectedDateRange(null);
    setSelectedYear(null);
  };

  const handleYearSelect = ({ key }) => {
    setSelectedYear(Number(key));
    setSelectedDateRange(null);
    setSelectedMonth(null);
  };

  const showAllWeeklyGoals = () => {
    setSelectedDateRange(null);
    setSelectedMonth(null);
    setSelectedYear(null);
  };
  const onClickAddWeeklyGoals = () => {
    history.push("/addweeklygoals");
  };

  const dateMenu = (
    <Menu>
      <Menu.Item key="weeks">
        <div onClick={(e) => e.stopPropagation()}>
          <RangePicker onChange={handleDateRangeChange} />
        </div>
      </Menu.Item>
    </Menu>
  );

  const monthMenu = (
    <Menu onClick={handleMonthSelect}>
      {[...Array(12).keys()].map((month) => (
        <Menu.Item key={month}>
          {new Date(0, month).toLocaleString("default", { month: "long" })}
        </Menu.Item>
      ))}
    </Menu>
  );

  const yearMenu = (
    <Menu onClick={handleYearSelect}>
      {[...Array(10).keys()].map((_, index) => {
        const year = new Date().getFullYear() - index;
        return <Menu.Item key={year}>{year}</Menu.Item>;
      })}
    </Menu>
  );

  return (
    <React.Fragment>
      <p className="weekly-goals-title">Weekly Goals</p>
      <div className="filters-div">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={16} lg={16}>
            <Button onClick={showAllWeeklyGoals}>All Weekly Goals</Button>

            <Dropdown
              overlay={dateMenu}
              placement="bottomLeft"
              trigger={["click"]}
            >
              <Button style={{ marginBottom: 16, marginLeft: 16 }}>
                Custom Search <DownOutlined />
              </Button>
            </Dropdown>

            <Dropdown
              overlay={monthMenu}
              placement="bottomLeft"
              trigger={["click"]}
            >
              <Button style={{ marginBottom: 16, marginLeft: 16 }}>
                Search by Month <DownOutlined />
              </Button>
            </Dropdown>

            <Dropdown
              overlay={yearMenu}
              placement="bottomLeft"
              trigger={["click"]}
            >
              <Button style={{ marginBottom: 16, marginLeft: 16 }}>
                Search by Year <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8}>
            <div className="add-weekl-goal-btn">
              <Button
                type="primary"
                className="addWeeklyGoalBtn"
                onClick={onClickAddWeeklyGoals}
              >
                {" "}
                <PlusOutlined /> Add Weekly Goal
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      <Row gutter={16}>
        {filteredGoals && filteredGoals.length > 0 ? (
          filteredGoals.map((data, index) => (
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              key={index}
              style={{ marginBottom: "20px" }}
            >
              <Link to={`/${data.id}/viewgoal`}>
                <div className="weekly-goals-card">
                  <div className="weeklygoal-content">
                    <p className="weekly-goal-duration">
                      {convertUNIXString(data.startDate) +
                        " - " +
                        convertUNIXString(data.endDate)}
                    </p>
                    <p className="weeklygoal-description">
                      {" "}
                      {data.description}{" "}
                    </p>
                    <p className="craetedBy">
                      Created By: {mapUserIdToUserData(data.userId).name}
                    </p>
                  </div>
                </div>
              </Link>
            </Col>
          ))
        ) : (
          <div style={{ marginTop: "100px" }}>
            <Empty
              description={
                <span>No Weekly Goals found for the selected date range</span>
              }
            />
          </div>
        )}
      </Row>
    </React.Fragment>
  );
};

export default withRouter(GoalLists);
