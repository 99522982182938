import {
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_ERROR,
} from "../../actions/Organization/createOrganization";

const createOrganization = (state = null, action) => {
  switch (action.type) {
    case CREATE_ORGANIZATION_REQUEST: {
      return true;
    }
    case CREATE_ORGANIZATION_SUCCESS: {
      return action.response;
    }
    case CREATE_ORGANIZATION_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default createOrganization;
