import {
  CREATE_HIGH_FIVE_LIKE_SUCCESS,
  CREATE_HIGH_FIVE_LIKE_CHEKIN_ERROR,
  CREATE_HIGH_FIVE_LIKE_CHEKIN_REQUEST,
} from "../../actions/HighFive/createHighFiveLike";

const createHighFiveLike = (state = null, action) => {
  switch (action.type) {
    case CREATE_HIGH_FIVE_LIKE_CHEKIN_REQUEST: {
      return true;
    }
    case CREATE_HIGH_FIVE_LIKE_SUCCESS: {
      return action.response;
    }
    case CREATE_HIGH_FIVE_LIKE_CHEKIN_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default createHighFiveLike;
