import {
  GET_PROFILE_VIEW_DETAILS_SUCCESS,
  GET_PROFILE_VIEW_DETAILS,
  GET_PROFILE_VIEW_DETAILS_ERROR,
} from "../../actions/ProfileView/getProfileViewDetails";

const getProfileViewDetails = (state = null, action) => {
  switch (action.type) {
    case GET_PROFILE_VIEW_DETAILS: {
      return true;
    }
    case GET_PROFILE_VIEW_DETAILS_SUCCESS: {
      return action.response;
    }
    case GET_PROFILE_VIEW_DETAILS_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getProfileViewDetails;
