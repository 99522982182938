import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Select,
  DatePicker,
  Input,
  Modal,
  Form,
  Popconfirm,
} from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrgUsers } from "../../redux/actions/User/getOrgUsers";
import { getHighFiveComment } from "../../redux/actions/HighFive/getHighFiveComment";
import { getHighFive } from "../../redux/actions/HighFive/getHighFive";
import { createHighFiveLike } from "../../redux/actions/HighFive/createHighFiveLike";
import { getHighFiveLikes } from "../../redux/actions/HighFive/getHighFiveLikes";
import { getHighFiveLike } from "../../redux/actions/HighFive/getHighFiveLike";
import { createHighFiveComment } from "../../redux/actions/HighFive/createHighFiveComment";
import { deleteHighFiveComment } from "../../redux/actions/HighFive/deleteHighFiveComment";
import userDummy from "../../assets/user.png";
import Loader from "../../components/Loader";
import {
  MessageOutlined,
  HeartFilled,
  HeartOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import MostAppreciatedHighFive from "./MostAppreciated";
import TopHighFives from "./TopHighFives";
import "./style.css";

const HighFivesListing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const [UsersList, setUsersList] = useState();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedHighFiveId, setSelectedHighFiveId] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [commentid, setCommentid] = useState(false);
  const [likedPerson, setLikedPerson] = useState();
  const [highFiveLikes, setHighFiveLikes] = useState();
  const [highFiveComment, setHighFiveComment] = useState();
  const [highFiveData, setHighFiveData] = useState("");
  const [isLikeModalVisible, setIsLikeModalVisible] = useState(false);
  const getHighFiveLikeStatus = useSelector((state) => state.getHighFiveLike);
  const getHighFiveLikesStatus = useSelector((state) => state.getHighFiveLikes);
  const getHighFiveStatus = useSelector((state) => state.getHighFive);
  const createHighFiveLikeStatus = useSelector(
    (state) => state.createHighFiveLike
  );
  const getHighFiveCommentStatus = useSelector(
    (state) => state.getHighFiveComment
  );
  const deleteHighFiveCommentStatus = useSelector(
    (state) => state.deleteHighFiveComment
  );
  const [localCommentsCount, setLocalCommentsCount] = useState({});
  const createHighFiveCommentStatus = useSelector(
    (state) => state.createHighFiveComment
  );
  useEffect(() => {
    const initialCommentsCount = {};
    filteredData.forEach((filteredData) => {
      initialCommentsCount[filteredData.id] = filteredData.coments_count;
    });
    setLocalCommentsCount(initialCommentsCount);
  }, [filteredData]);
  useEffect(() => {
    if (
      createHighFiveCommentStatus &&
      createHighFiveCommentStatus.status === 201
    ) {
      setLocalCommentsCount((prevCommentsCount) => ({
        ...prevCommentsCount,
        [commentid]: (prevCommentsCount[commentid] || 0) + 1,
      }));
    }
  }, [createHighFiveCommentStatus, dispatch]);
  useEffect(() => {
    if (createHighFiveLikeStatus && createHighFiveLikeStatus.status === 201) {
      dispatch(getHighFiveLikes());
    }
  }, [createHighFiveLikeStatus, dispatch]);
  useEffect(() => {
    if (createHighFiveLikeStatus && createHighFiveLikeStatus.status === 204) {
      dispatch(getHighFiveLikes());
    }
  }, [createHighFiveLikeStatus, dispatch]);
  useEffect(() => {
    if (getHighFiveLikesStatus && getHighFiveLikesStatus.status === 200) {
      setHighFiveLikes(getHighFiveLikesStatus.data);
    }
  }, [getHighFiveLikesStatus]);

  useEffect(() => {
    if (getHighFiveLikeStatus && getHighFiveLikeStatus.status === 200) {
      setLikedPerson(getHighFiveLikeStatus.data);
    }
  }, [getHighFiveLikeStatus]);

  useEffect(() => {
    if (getHighFiveCommentStatus && getHighFiveCommentStatus.status === 200) {
      setHighFiveComment(getHighFiveCommentStatus.data);
    }
  }, [getHighFiveCommentStatus]);

  useEffect(() => {
    if (getHighFiveStatus && getHighFiveStatus.status === 200) {
      setHighFiveData(getHighFiveStatus.data);
    }
  }, [getHighFiveStatus]);
  const [localLikesCount, setLocalLikesCount] = useState({});
  useEffect(() => {
    const initialLikesCount = {};
    filteredData.forEach((highFive) => {
      initialLikesCount[highFive.id] = highFive.likes_count;
    });
    setLocalLikesCount(initialLikesCount);
  }, [filteredData]);

  const isLiked = (id) =>
    highFiveLikes?.some(({ high_five_id }) => id === high_five_id);

  const onLike = (id) => {
    const liked = isLiked(id);

    dispatch(createHighFiveLike(id));

    setLocalLikesCount((prevLikesCount) => ({
      ...prevLikesCount,
      [id]: liked ? prevLikesCount[id] - 1 : prevLikesCount[id] + 1,
    }));
  };

  const showLikeModal = (value) => {
    setIsLikeModalVisible(true);
    dispatch(getHighFiveLike(value));
  };

  const handleLikeOk = () => {
    setIsLikeModalVisible(false);
  };

  const handleLikeCancel = () => {
    setIsLikeModalVisible(false);
  };
  const showModal = (id) => {
    localStorage.setItem("selected_high_five_id", id);
    setIsModalVisible(true);
    dispatch(getHighFiveComment(id));
    setCommentid(id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onDeleteHighFiveComment = (selected_high_five_id, id) => {
    dispatch(deleteHighFiveComment(selected_high_five_id, id));
  };
  useEffect(() => {
    const selected_high_five_id = localStorage.getItem("selected_high_five_id");
    if (
      deleteHighFiveCommentStatus &&
      deleteHighFiveCommentStatus.status === 200
    ) {
      dispatch(getHighFiveComment(selected_high_five_id));
      dispatch(getHighFive("", "", ""));
    }
  }, [deleteHighFiveCommentStatus]);
  const onFinish = (values) => {
    const formData = new FormData(),
      formDataObj = Object.fromEntries(formData.entries());
    formDataObj.comment = values.comment;
    for (let i = 0; i < highFiveData.length; i++) {
      if (highFiveData[i].id === commentid) {
        dispatch(
          createHighFiveComment(highFiveData[i].id, formDataObj.comment)
        );
      }
    }
    form.resetFields();
    setIsModalVisible(false);
  };
  const handleChange = (value) => {
    localStorage.setItem("selected_user", value);
    const selectedStatus = localStorage.getItem("selected_status");
    if (
      startDate &&
      endDate &&
      value &&
      value !== "all" &&
      selectedStatus === "sent"
    ) {
      const filteredDataValues = data.filter((item) => {
        const highFiveDate = item.date.substring(0, 10);
        return (
          highFiveDate >= startDate &&
          highFiveDate <= endDate &&
          item.from_user_name === value
        );
      });
      setFilteredData(filteredDataValues);
    } else if (
      startDate &&
      endDate &&
      value &&
      value !== "all" &&
      selectedStatus === "received"
    ) {
      const filteredDataValues = data.filter((item) => {
        const highFiveDate = item.date.substring(0, 10);
        return (
          highFiveDate >= startDate &&
          highFiveDate <= endDate &&
          item.to_user_name === value
        );
      });
      setFilteredData(filteredDataValues);
    } else if (
      startDate &&
      endDate &&
      value &&
      value !== "all" &&
      selectedStatus === "sentReceived"
    ) {
      const filteredDataValues = data.filter((item) => {
        const highFiveDate = item.date.substring(0, 10);
        return (
          (item.from_user_name === value || item.to_user_name === value) &&
          highFiveDate >= startDate &&
          highFiveDate <= endDate
        );
      });
      setFilteredData(filteredDataValues);
    } else if (startDate && endDate && value && value !== "all") {
      const filteredDataValues = data.filter((item) => {
        const highFiveDate = item.date.substring(0, 10);
        return (
          (item.from_user_name === value || item.to_user_name === value) &&
          highFiveDate >= startDate &&
          highFiveDate <= endDate
        );
      });
      setFilteredData(filteredDataValues);
    } else if (value === "all" && startDate && endDate) {
      const filteredDataValues = data.filter((value) => {
        const highFiveDate = value.date.substring(0, 10);
        return highFiveDate >= startDate && highFiveDate <= endDate;
      });

      setFilteredData(filteredDataValues);
      localStorage.removeItem("selected_user");
    } else if (value === "all") {
      setFilteredData(data);
    } else if (selectedStatus && selectedStatus === "sent") {
      const filteredDataValues = data.filter((item) => {
        return value === item.from_user_name;
      });

      setFilteredData(filteredDataValues);
    } else if (selectedStatus && selectedStatus === "received") {
      const filteredDataValues = data.filter((item) => {
        return value === item.to_user_name;
      });

      setFilteredData(filteredDataValues);
    } else if (selectedStatus && selectedStatus === "sentReceived") {
      const filteredDataValues = data.filter((item) => {
        return item.from_user_name === value || item.to_user_name === value;
      });
      setFilteredData(filteredDataValues);
    } else {
      const filteredDataValues = data.filter((item) => {
        return item.from_user_name === value || item.to_user_name === value;
      });
      setFilteredData(filteredDataValues);
    }
  };

  function onChangeStatus(status) {
    localStorage.setItem("selected_status", status);
    if (status === "sent" && startDate && endDate) {
      const selectedUser = localStorage.getItem("selected_user");
      const filteredDataValues = data.filter((item) => {
        const highFiveDate = item.date.substring(0, 10);
        return (
          selectedUser === item.from_user_name &&
          highFiveDate >= startDate &&
          highFiveDate <= endDate
        );
      });

      setFilteredData(filteredDataValues);
    } else if (status === "received" && startDate && endDate) {
      const selectedUser = localStorage.getItem("selected_user");
      const filteredDataValues = data.filter((item) => {
        const highFiveDate = item.date.substring(0, 10);
        return (
          selectedUser === item.to_user_name &&
          highFiveDate >= startDate &&
          highFiveDate <= endDate
        );
      });

      setFilteredData(filteredDataValues);
    } else if (status === "sentReceived" && startDate && endDate) {
      const selectedUser = localStorage.getItem("selected_user");

      const filteredDataValues = data.filter((item) => {
        const highFiveDate = item.date.substring(0, 10);

        return (
          (item.from_user_name === selectedUser ||
            item.to_user_name === selectedUser) &&
          highFiveDate >= startDate &&
          highFiveDate <= endDate
        );
      });

      setFilteredData(filteredDataValues);
    } else if (status === "sent") {
      const selectedUser = localStorage.getItem("selected_user");
      const filteredDataValues = data.filter((item) => {
        return selectedUser === item.from_user_name;
      });

      setFilteredData(filteredDataValues);
    } else if (status === "received") {
      const selectedUser = localStorage.getItem("selected_user");
      const filteredDataValues = data.filter((item) => {
        return selectedUser === item.to_user_name;
      });

      setFilteredData(filteredDataValues);
    } else if (status === "sentReceived") {
      const selectedUser = localStorage.getItem("selected_user");
      const filteredDataValues = data.filter((item) => {
        return (
          item.from_user_name === selectedUser ||
          item.to_user_name === selectedUser
        );
      });
      setFilteredData(filteredDataValues);
    }
  }

  function onChangeMonth(range) {
    const selectedStatus = localStorage.getItem("selected_status");
    if (!range || !range[0] || !range[1]) {
      if (localStorage.getItem("selected_user")) {
        const selectedUser = localStorage.getItem("selected_user");

        const filteredDataValues = data.filter((item) => {
          return (
            item.from_user_name === selectedUser ||
            item.to_user_name === selectedUser
          );
        });
        setFilteredData(filteredDataValues);
        setStartDate("");
        setEndDate("");
      } else {
        setFilteredData(data);
        setStartDate("");
        setEndDate("");
      }

      return;
    }
    if (localStorage.getItem("selected_user") && !selectedStatus) {
      const start_date = range[0].format().substring(0, 10);
      const end_date = range[1].format().substring(0, 10);
      setStartDate(start_date);
      setEndDate(end_date);

      const filteredDataValues = data.filter((item) => {
        const selectedUser = localStorage.getItem("selected_user");
        const highFiveDate = item.date.substring(0, 10);
        return (
          (highFiveDate >= start_date &&
            highFiveDate <= end_date &&
            item.from_user_name === selectedUser) ||
          item.to_user_name === selectedUser
        );
      });
      setFilteredData(filteredDataValues);
    } else if (localStorage.getItem("selected_user") && selectedStatus) {
      if (selectedStatus === "sent") {
        const start_date = range[0].format().substring(0, 10);
        const end_date = range[1].format().substring(0, 10);
        setStartDate(start_date);
        setEndDate(end_date);

        const filteredDataValues = data.filter((item) => {
          const selectedUser = localStorage.getItem("selected_user");
          const highFiveDate = item.date.substring(0, 10);
          return (
            highFiveDate >= start_date &&
            highFiveDate <= end_date &&
            item.from_user_name === selectedUser
          );
        });
        setFilteredData(filteredDataValues);
      } else if (selectedStatus === "received") {
        const start_date = range[0].format().substring(0, 10);
        const end_date = range[1].format().substring(0, 10);
        setStartDate(start_date);
        setEndDate(end_date);

        const filteredDataValues = data.filter((item) => {
          const selectedUser = localStorage.getItem("selected_user");
          const highFiveDate = item.date.substring(0, 10);
          return (
            highFiveDate >= start_date &&
            highFiveDate <= end_date &&
            item.to_user_name === selectedUser
          );
        });
        setFilteredData(filteredDataValues);
      } else if (selectedStatus === "sentReceived") {
        const start_date = range[0].format().substring(0, 10);
        const end_date = range[1].format().substring(0, 10);
        setStartDate(start_date);
        setEndDate(end_date);

        const filteredDataValues = data.filter((item) => {
          const selectedUser = localStorage.getItem("selected_user");
          const highFiveDate = item.date.substring(0, 10);
          return (
            (highFiveDate >= start_date &&
              highFiveDate <= end_date &&
              item.from_user_name === selectedUser) ||
            item.to_user_name === selectedUser
          );
        });
        setFilteredData(filteredDataValues);
      }
    } else {
      const start_date = range[0].format().substring(0, 10);
      const end_date = range[1].format().substring(0, 10);
      setStartDate(start_date);
      setEndDate(end_date);
      const filteredDataValues = data.filter((value) => {
        const highFiveDate = value.date.substring(0, 10);
        return highFiveDate >= start_date && highFiveDate <= end_date;
      });
      setFilteredData(filteredDataValues);
    }
  }

  const selectUser = localStorage.getItem("selected_user");
  useEffect(() => {
    localStorage.removeItem("selected_user");
    localStorage.removeItem("selected_status");
  }, []);

  const getOrgUsersStatus = useSelector((state) => state.getOrgUsers);

  useEffect(() => {
    dispatch(getOrgUsers());
    dispatch(getHighFive("", "", ""));
  }, [dispatch]);

  useEffect(() => {
    if (getHighFiveStatus && getHighFiveStatus.status === 200) {
      setData(getHighFiveStatus.data);
      setFilteredData(getHighFiveStatus.data);
    }
  }, [getHighFiveStatus]);
  useEffect(() => {
    dispatch(getHighFiveLikes());
  }, [dispatch]);
  useEffect(() => {
    if (getOrgUsersStatus && getOrgUsersStatus.status === 200) {
      setUsersList(getOrgUsersStatus.data);
    }
  }, [getOrgUsersStatus]);

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    if (user_id && UsersList) {
      const userIdNum = parseInt(user_id, 10);
      const user = UsersList.find((user) => user.id === userIdNum);
      setUserDetails(user);
    }
  }, [UsersList]);

  const onClickCreateHighFive = () => {
    history.push("/createhighfive");
  };

  const groupHighFivesByDate = () => {
    const groupedData = {};
    filteredData.forEach((value) => {
      const date = value.date.substring(0, 10);
      if (!groupedData[date]) {
        groupedData[date] = [];
      }
      groupedData[date].push(value);
    });
    return groupedData;
  };
  const selected_high_five_id = localStorage.getItem("selected_high_five_id");
  const current_user_id = localStorage.getItem("user_id");
  return (
    <>
      <Modal
        visible={isLikeModalVisible}
        onOk={handleLikeOk}
        onCancel={handleLikeCancel}
      >
        <div className="likeCountListing">
          {likedPerson &&
            likedPerson.map((value) => (
              <Row className="likedPersonInfo">
                <img
                  className="userImage"
                  src={`${process.env.REACT_APP_BACKEND_API_BASE}${
                    value && value.image.orignal
                  }`}
                  alt=""
                />

                <p className="likedUsername">{value.likedBy}</p>
              </Row>
            ))}
        </div>
      </Modal>
      <Modal
        visible={isModalVisible}
        footer={null}
        className="commentModal"
        maskClosable={true}
        onCancel={handleCancel}
      >
        <div className="closeComment">
          <CloseOutlined onClick={handleCancel} />
        </div>
        <div className="commentContent">
          <Row>
            {getHighFiveCommentStatus === true ? <Loader /> : ""}
            {highFiveComment &&
              highFiveComment
                .slice()
                .reverse()
                .map((value) => (
                  <div className="CommentPersonInfo" key={value.id}>
                    <img
                      className="userImage"
                      src={
                        value && value.user_image ? value.user_image : userDummy
                      }
                      alt=""
                    />
                    <div className="commentAndDate">
                      <p className="commentValue">{value.coment}</p>
                      <p className="comment-date">
                        {value && value.date.substring(0, 10)}
                      </p>
                    </div>
                    <div>
                      <Popconfirm
                        title="Are you sure delete this comment?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() =>
                          onDeleteHighFiveComment(
                            selected_high_five_id,
                            value && value.id
                          )
                        }
                      >
                        {value && value.user_id === current_user_id ? (
                          <DeleteOutlined
                            style={{ color: "white", paddingLeft: "20px" }}
                          />
                        ) : (
                          ""
                        )}
                      </Popconfirm>
                    </div>
                  </div>
                ))}
          </Row>
        </div>

        <Form form={form} onFinish={onFinish}>
          {createHighFiveCommentStatus === true ? <Loader /> : ""}
          <div className="commnetInputBtnDiv">
            <Row gutter={16}>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={18}
                xl={18}
              >
                <Form.Item
                  name="comment"
                  rules={[
                    {
                      required: true,
                      message: "Please input your comment first!",
                    },
                  ]}
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <Form.Item>
                  <Button className="commentBtnStyle" htmlType="submit">
                    Comment
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
      <div className="highFivesListing">
        <h1 className="highFivesTitle">High five 🙌🏻 listing</h1>
        <div className="highFivesListingContent">
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="selectUsers">
                <Select
                  defaultValue="all"
                  onChange={handleChange}
                  className="highFiveInputStyle"
                >
                  <Option value="all" key="all">
                    All Users
                  </Option>
                  {UsersList &&
                    UsersList.map((value) => (
                      <Option
                        value={value.first_name + " " + value.last_name}
                        key={value.id}
                      >
                        {value.first_name + " " + value.last_name}
                      </Option>
                    ))}
                </Select>
              </div>
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8}>
              {selectUser ? (
                <Select
                  className="highFiveInputStyle"
                  defaultValue="sentReceived"
                  onChange={onChangeStatus}
                >
                  <Option value="sentReceived">Sent And Received</Option>
                  <Option value="sent">Sent</Option>
                  <Option value="received">Receive</Option>
                </Select>
              ) : (
                <Select
                  className="highFiveInputStyle"
                  defaultValue="sentReceived"
                  onChange={onChangeStatus}
                  disabled="true"
                >
                  <Option value="sentReceived">Sent And Received</Option>
                  <Option value="sent">Sent</Option>
                  <Option value="received">Receive</Option>
                </Select>
              )}
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8}>
              <DatePicker.RangePicker
                onChange={onChangeMonth}
                className="highFiveInputStyle"
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16}>
              <div className="listingDetails">
                <Button
                  className="createHighFiveBtn"
                  onClick={onClickCreateHighFive}
                >
                  Create Highfive 🙌🏻
                </Button>
                <p className="highFiveComment">
                  Who did an amazing work that made your day? High fives will be
                  posted on this public dashboard
                </p>
              </div>
              <div className="highFivesContent">
                {getHighFiveStatus === true ? <Loader /> : ""}
                {filteredData &&
                  Object.entries(groupHighFivesByDate()).map(
                    ([date, highFives]) => (
                      <div key={date}>
                        <p className="highFiveDate">{date}</p>
                        <div className="smallDividerOne"></div>
                        <div className="viewHighfiveContent">
                          {highFives.map((value, index) => (
                            <React.Fragment key={value.id}>
                              <Row gutter={16}>
                                <Col
                                  className="gutter-row"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={8}
                                  xl={8}
                                >
                                  <div className="sendHighFive">
                                    <img
                                      src={
                                        value && value.from_user_image
                                          ? value.from_user_image
                                          : userDummy
                                      }
                                      alt=""
                                      className="highfive-users"
                                    />
                                    <p className="HighFiveUsername">
                                      {value.from_user_name}
                                    </p>
                                  </div>
                                </Col>
                                <Col
                                  className="gutter-row"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={8}
                                  xl={8}
                                >
                                  <p className="listingHighfiveIcon">🙌🏻</p>
                                </Col>
                                <Col
                                  className="gutter-row"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={8}
                                  xl={8}
                                >
                                  <div className="receiveHighFive">
                                    <img
                                      src={
                                        value && value.to_user_image
                                          ? value.to_user_image
                                          : userDummy
                                      }
                                      alt=""
                                      className="highfive-users"
                                    />
                                    <p className="HighFiveUsername">
                                      {value.to_user_name}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <p className="highFiveComment">{value.coment}</p>

                              <Row>
                                <Col span={2}>
                                  {isLiked(value.id) ? (
                                    <HeartFilled
                                      onClick={() => onLike(value.id)}
                                      className="likeIcon"
                                    />
                                  ) : (
                                    <HeartOutlined
                                      onClick={() => onLike(value.id)}
                                      className="likeIcon"
                                    />
                                  )}
                                </Col>
                                <Col span={3}>
                                  <p
                                    className="likesCount"
                                    onClick={() => showLikeModal(value.id)}
                                  >
                                    {localLikesCount[value.id]}
                                  </p>
                                </Col>
                                <Col span={2}>
                                  <MessageOutlined
                                    className="commentIcon"
                                    onClick={() => showModal(value.id)}
                                  />
                                </Col>
                                <Col span={2}>
                                  <p className="commentsCount">
                                    {localCommentsCount[value.id]}
                                  </p>
                                </Col>
                                <Col span="13">
                                  <p className="givenHighFiveContent"></p>
                                </Col>
                              </Row>
                              {index !== highFives.length - 1 && (
                                <div className="divider">
                                  <div className="small-divider"></div>
                                </div>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    )
                  )}
              </div>
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8}>
              {userDetails && (
                <div className="highFiveUserDetails">
                  <Row gutter={16}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={10}
                      xl={10}
                    >
                      <div className="userDetails">
                        <img
                          src={
                            userDetails.image ? userDetails.image : userDummy
                          }
                          alt=""
                          className="highFiveCreatorImage"
                        />
                        <p className="highFiveCreator">
                          {userDetails.first_name} {userDetails.last_name}
                        </p>
                        <p className="highFiveCreatorRole">
                          {userDetails.designation}
                        </p>
                      </div>
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={2}
                      xl={2}
                    >
                      <div className="verticalDivider"></div>
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="highFiveCountAndHeading">
                        <Row gutter={16}>
                          <Col
                            className="gutter-row"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <p className="givenHeading">Sent</p>
                            <p className="receivedHeading">Received</p>
                          </Col>
                          <Col
                            className="gutter-row"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <p className="highFiveCount">
                              {userDetails.sent_high_five}
                            </p>
                            <p className="highFiveCount">
                              {userDetails
                                ? userDetails.received_high_five || 0
                                : 0}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
              <MostAppreciatedHighFive />
              <TopHighFives />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default HighFivesListing;
