import React, { useState, useEffect } from "react";
import { Select, DatePicker, Button, Form } from "antd";
import { getOrgUsers } from "../../redux/actions/User/getOrgUsers";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createPerformanceReview } from "../../redux/actions/PerformanceReview/createPerformanceReview";
import "./style.css";

const CreatePerformanceReview = () => {
  const { Option } = Select;
  const history = useHistory();
  const dispatch = useDispatch();
  const [UsersList, setUsersList] = useState();
  const [starttDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [user, setUser] = useState();
  const user_role = localStorage.getItem("currentUserRole");
  const user_id = localStorage.getItem("user_id");

  const createPerformanceReviewStatus = useSelector(
    (state) => state.createPerformanceReview
  );

  const getOrgUsersStatus = useSelector((state) => state.getOrgUsers);

  useEffect(() => {
    dispatch(getOrgUsers());
  }, [dispatch]);

  useEffect(() => {
    if (getOrgUsersStatus && getOrgUsersStatus.status === 200) {
      const filteredUsers = getOrgUsersStatus.data.filter(
        (user) => parseInt(user.id) !== parseInt(user_id)
      );
      setUsersList(filteredUsers);
    }
  }, [getOrgUsersStatus, user_id]);

  useEffect(() => {
    if (
      createPerformanceReviewStatus &&
      createPerformanceReviewStatus.status === 201
    ) {
      history.push(
        `/performancereview/${
          createPerformanceReviewStatus.data &&
          createPerformanceReviewStatus.data.insertId
        }`
      );
    }
  }, [createPerformanceReviewStatus]);

  function onChange(value) {
    setUser(value);
  }

  function onChangeMonth(range) {
    setStartDate(range[0].unix());
    setEndDate(range[1].unix());
  }

  const onFinish = (values) => {
    if (values) {
      dispatch(createPerformanceReview(user, starttDate, endDate));
    }
  };

  return (
    <>
      {user_role === "manager" || "owner" ? (
        <>
          <h1 className="create-performance-review-heading">
            Create Performance Review
          </h1>
          <div className="create-performance-review">
            <div className="create-performance-form">
              <Form onFinish={onFinish}>
                <Form.Item
                  label="Employee"
                  name="user"
                  rules={[
                    {
                      required: true,
                      message: "Please select user!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    onChange={onChange}
                    placeholder="Select a user"
                    className="highFiveSearchInput"
                  >
                    {" "}
                    {UsersList &&
                      UsersList.map((value) => (
                        <Option value={value.id} key={value.id}>
                          {value.first_name + value.last_name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Duration"
                  name="duration"
                  rules={[
                    {
                      required: true,
                      message: "Please select duration!",
                    },
                  ]}
                >
                  <DatePicker.RangePicker
                    picker="month"
                    className="select-duration"
                    onChange={onChangeMonth}
                  />
                </Form.Item>
                <div className="performance-review-btn">
                  <Button
                    className="create-performance-review-btn"
                    htmlType="submit"
                  >
                    Next
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </>
      ) : (
        <div class="performance-review-style">
          <p>You are not allowed to create performance review!</p>
        </div>
      )}
    </>
  );
};
export default CreatePerformanceReview;
