import React, { useState, useEffect } from "react";
import Avatar from "react-avatar-edit";
import { withRouter } from "react-router-dom";
import { Input, Form, Button } from "antd";
import "./style.css";
import dummyimage from "./dummy-image.png";
import { useDispatch, useSelector } from "react-redux";
import { getProfileViewDetails } from "../../redux/actions/ProfileView/getProfileViewDetails";
import { updateProfileView } from "../../redux/actions/ProfileView/updateProfileViewDetails";
import Loader from "../../components/Loader";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";

const ProfileView = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const id = localStorage.getItem("user_id");

  const getProfileViewDetailsStatus = useSelector(
    (state) => state.getProfileViewDetails
  );
  const updateProfileViewStatus = useSelector(
    (state) => state.updateProfileView
  );
  const [preview, setPreview] = useState();
  const [startLoadingState, setStartLoadingState] = useState(false);

  useEffect(() => {
    dispatch(getProfileViewDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (updateProfileViewStatus === 200) {
      history.push(`/dashboard`);
    }
  }, [updateProfileViewStatus, history]);

  useEffect(() => {
    if (updateProfileViewStatus === 400) {
      setStartLoadingState(false);
      alert("please update image OR designation");
    }
  }, [updateProfileViewStatus]);

  useEffect(() => {
    if (
      getProfileViewDetailsStatus &&
      getProfileViewDetailsStatus.status === 200
    ) {
      setStartLoadingState(false);
    }
  }, [getProfileViewDetailsStatus]);

  const getprofiledetailValue =
    getProfileViewDetailsStatus && getProfileViewDetailsStatus.data;
  const first_name =
    getprofiledetailValue && getprofiledetailValue[0].first_name;
  const last_name = getprofiledetailValue && getprofiledetailValue[0].last_name;
  const designation =
    getprofiledetailValue && getprofiledetailValue[0].designation;
  const contactNumber =
    getprofiledetailValue && getprofiledetailValue[0].contact;

  const onFinish = (values) => {
    const formData = new FormData(),
      formDataObj = Object.fromEntries(formData.entries());
    formDataObj.image = preview;
    formDataObj.designation = values.designation;
    formDataObj.first_name = values.first_name;
    formDataObj.last_name = values.last_name;
    formDataObj.mobile = values.mobile;
    setStartLoadingState(true);
    dispatch(updateProfileView(formDataObj));
    localStorage.setItem("image", preview);
    localStorage.setItem("designation", values.designation);
    const email = localStorage.getItem("email");

    const poolData = {
      UserPoolId: `${process.env.REACT_APP_AWS_USER_POOL_ID}`,
      ClientId: `${process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID}`,
    };

    const UserPool = new CognitoUserPool(poolData);

    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    const authDetails = new AuthenticationDetails({
      Username: email,
    });
    user.authenticateUser(authDetails);

    const current_user = UserPool.getCurrentUser();

    const fisrt_name = new CognitoUserAttribute({
      Name: "name",
      Value: values.first_name,
    });
    const last_name = new CognitoUserAttribute({
      Name: "given_name",
      Value: values.last_name,
    });

    current_user.updateAttributes(
      [fisrt_name, last_name],
      function (err, result) {
        console.log("result", { err, result });
      }
    );
  };

  const onClose = () => {
    setPreview("");
  };

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 1024 * 1024 * 10) {
      alert("File is too big!");
      elem.target.value = "";
    }
  };

  const onCrop = (preview) => {
    setPreview(preview);
  };

  return (
    <>
      <p className="viewProfileTitle">View Profile</p>
      <div className="profileScreenCard">
        <div className="form-section">
          {getprofiledetailValue && getprofiledetailValue ? (
            <Form
              onFinish={onFinish}
              name="profileForm"
              initialValues={{
                first_name: first_name,
                last_name: last_name,
                designation: designation,
                mobile: contactNumber,
              }}
            >
              <div className="image-upload">
                <Form.Item name="profileImage">
                  <div className="uploadImage" id="uploadImageDiv">
                    <div className="image-container">
                      <Avatar
                        className="avatar-style"
                        id="avatarStyle"
                        width={245}
                        height={200}
                        onCrop={onCrop}
                        onClose={onClose}
                        src={preview}
                        onBeforeFileLoad={onBeforeFileLoad}
                        label="Update Image"
                      />
                    </div>
                  </div>
                  {getprofiledetailValue && getprofiledetailValue[0].image ? (
                    <div className="imagePreview" id="previewImageDiv">
                      <img
                        alt=""
                        src={`${
                          getprofiledetailValue &&
                          getprofiledetailValue[0].image
                        }`}
                      />
                    </div>
                  ) : (
                    <div
                      className="imagePreviewDummy"
                      id="previewImageDummyDiv"
                    >
                      <img
                        id="roundedProfileImg"
                        src={dummyimage}
                        className="roundedProfileImgDummy"
                        alt="Preview"
                      />
                    </div>
                  )}
                </Form.Item>
              </div>
              <div style={{ paddingTop: "50px", width: "60%", margin: "auto" }}>
                <Form.Item name="first_name" label="first name">
                  <Input />
                </Form.Item>

                <Form.Item name="last_name" label="last name">
                  <Input />
                </Form.Item>
                <Form.Item name="mobile" label="Contact #">
                  <Input />
                </Form.Item>
                <Form.Item name="designation" label="designation">
                  <Input />
                </Form.Item>

                <Form.Item>
                  <div className="profileUpdateBtnClass">
                    <Button
                      className="profileUpdateBtn"
                      type="primary"
                      htmlType="submit"
                    >
                      Update
                    </Button>
                  </div>
                </Form.Item>
              </div>
            </Form>
          ) : (
            ""
          )}
        </div>
        {startLoadingState && <Loader />}
      </div>
    </>
  );
};
export default withRouter(ProfileView);
