import {
  GET_EMAIL_SUCCESS,
  GET_EMAIL_REQUEST,
  GET_EMAIL_ERROR,
} from "../../actions/User/getEmail";

const getEmail = (state = null, action) => {
  switch (action.type) {
    case GET_EMAIL_REQUEST: {
      return true;
    }
    case GET_EMAIL_SUCCESS: {
      return action.response;
    }
    case GET_EMAIL_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getEmail;
