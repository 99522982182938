import {
    ACCEPT_INVITE_SUCCESS,
    ACCEPT_INVITE_REQUEST,
    ACCEPT_INVITE_ERROR,
  } from "../../actions/InviteUser/acceptinvite";
  
  const acceptInvite = (state = null, action) => {
    switch (action.type) {
      case ACCEPT_INVITE_REQUEST: {
        return true;
      }
      case ACCEPT_INVITE_SUCCESS: {
        return action.response;
      }
      case ACCEPT_INVITE_ERROR: {
        return action.response;
      }
      default:
        return state;
    }
  };
  
  export default acceptInvite;
  