import {
  CREATE_HIGH_FIVE_SUCCESS,
  CREATE_HIGH_FIVE_REQUEST,
  CREATE_HIGH_FIVE_ERROR,
} from "../../actions/HighFive/createHighFive";

const createHighFive = (state = null, action) => {
  switch (action.type) {
    case CREATE_HIGH_FIVE_REQUEST: {
      return true;
    }
    case CREATE_HIGH_FIVE_SUCCESS: {
      return action.response;
    }
    case CREATE_HIGH_FIVE_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default createHighFive;
