import {
  GET_SQL_SUCCESS,
  GET_SQL_REQUEST,
  GET_SQL_ERROR,
} from "../../actions/SqlData/getSqlData";

const getSql = (state = null, action) => {
  switch (action.type) {
    case GET_SQL_REQUEST: {
      return true;
    }
    case GET_SQL_SUCCESS: {
      return action.response;
    }
    case GET_SQL_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getSql;
