import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getTimeOffCount } from "../../redux/actions/TimeOff/getTimeOffCount";
import { getOrganization } from "../../redux/actions/Organization/getOrganization";
import { EllipsisOutlined } from "@ant-design/icons";

const RequestsType = () => {
  const dispatch = useDispatch();
  const [annualTimeOffs, setAnnualTimeOffs] = useState("");
  const [sickTimeOffs, setSickTimeOffs] = useState("");
  const [emergencyTimeOffs, setEmergencyTimeOffs] = useState("");
  const [vocationalTimeOffs, setvocationalTimeOffs] = useState("");
  const [otherTimeOffs, setOtherTimeOffs] = useState("");
  const [totalTimeOffs, setTotalTimeOffs] = useState("");
  const getTimeOffCountStatus = useSelector((state) => state.getTimeOffCount);
  const getOrganizationStatus = useSelector((state) => state.getOrganization);

  useEffect(() => {
    dispatch(getTimeOffCount());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getOrganization());
  }, [dispatch]);
  useEffect(() => {
    if (getOrganizationStatus && getOrganizationStatus.status === 200) {
      setTotalTimeOffs(
        getOrganizationStatus && getOrganizationStatus.data[0].time_off_request
      );
    }
  }, [getOrganizationStatus]);

  useEffect(() => {
    if (getTimeOffCountStatus && getTimeOffCountStatus.status === 200) {
      setAnnualTimeOffs(
        getTimeOffCountStatus &&
          getTimeOffCountStatus.data.annual_leave[0]["SUM(work_days)"]
      );

      setSickTimeOffs(
        getTimeOffCountStatus &&
          getTimeOffCountStatus.data.sick_leave[0]["SUM(work_days)"]
      );
      setEmergencyTimeOffs(
        getTimeOffCountStatus &&
          getTimeOffCountStatus.data.emergency_leave[0]["SUM(work_days)"]
      );
      setvocationalTimeOffs(
        getTimeOffCountStatus &&
          getTimeOffCountStatus.data.vocational_leave[0]["SUM(work_days)"]
      );
      setOtherTimeOffs(
        getTimeOffCountStatus &&
          getTimeOffCountStatus.data.other[0]["SUM(work_days)"]
      );
    }
  }, [getTimeOffCountStatus]);

  return (
    <>
      <div className="leavetype">
        <Row>
          <Col span={24} className="leavetypedoticon">
            <EllipsisOutlined className="doticon" />
          </Col>
        </Row>
        <Row>
          <Col span={3}>
            <p className="annualheading">Total</p>
          </Col>
          <Col span={4}>
            <p className="annualheading">Annual</p>
          </Col>
          <Col span={3}>
            <p className="annualheading">Sick</p>
          </Col>
          <Col span={5}>
            <p className="vocationalheading">Vocational</p>
          </Col>
          <Col span={5}>
            <p className="emergencyheading">Emergency</p>
          </Col>
          <Col span={4}>
            <p className="otherheading">Other</p>
          </Col>
        </Row>
        <Row>
          <Col span={3}>
            <p className="totalcount">{totalTimeOffs}</p>
          </Col>
          <Col span={4}>
            <h1 className="approvedcount">
              {annualTimeOffs === null ? 0 : annualTimeOffs}
            </h1>
          </Col>
          <Col span={3}>
            <h1 className="sickleavecount">
              {sickTimeOffs === null ? 0 : sickTimeOffs}
            </h1>
          </Col>
          <Col span={5}>
            <h1 className="rejectedcount">
              {vocationalTimeOffs === null ? 0 : vocationalTimeOffs}
            </h1>
          </Col>
          <Col span={5}>
            <h1 className="pendingcount">
              {emergencyTimeOffs === null ? 0 : emergencyTimeOffs}
            </h1>
          </Col>
          <Col span={4}>
            <h1 className="pendingcount">
              {otherTimeOffs === null ? 0 : otherTimeOffs}
            </h1>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RequestsType;
