import {
  CREATE_CHECKIN_SUCCESS,
  CREATE_CHECKIN_REQUEST,
  CREATE_CHECKIN_ERROR,
} from "../../actions/Checkins/createCheckin";

const createCheckin = (state = null, action) => {
  switch (action.type) {
    case CREATE_CHECKIN_REQUEST: {
      return true;
    }
    case CREATE_CHECKIN_SUCCESS: {
      return action.response;
    }
    case CREATE_CHECKIN_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default createCheckin;
