import axios from "axios";
import { readLocalStorage } from "../../../utils/readLocalStorage";
import { tokenAuthFailedAction } from "../Auth/handleUnauthRoutes";
export const GET_SQL_SUCCESS = "GET_SQL_SUCCESS";
export const GET_SQL_ERROR = "GET_SQL_ERROR";
export const GET_SQL_REQUEST = "GET_SQL_REQUEST ";

export const getSql = (user_id, userInput) => (dispatch) => {
  dispatch({ type: GET_SQL_REQUEST });
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_API_BASE}/api/${user_id}/db_data`,
    data: {
      values: userInput,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": readLocalStorage("access-token"),
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_SQL_SUCCESS,
        response: response,
      });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(tokenAuthFailedAction());
      }
      return dispatch({
        type: GET_SQL_ERROR,
        response: error.response,
      });
    });
};
