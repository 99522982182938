import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getTopAppreciated } from "../../redux/actions/HighFive/getTopAppreciated";

const TopHighFives = () => {
  const dispatch = useDispatch();
  const [TopAppreciated, setTopAppreciated] = useState();
  const getTopAppreciatedStatus = useSelector(
    (state) => state.getTopAppreciated
  );
  useEffect(() => {
    if (getTopAppreciatedStatus && getTopAppreciatedStatus.status === 200) {
      setTopAppreciated(getTopAppreciatedStatus.data);
    }
  }, [getTopAppreciatedStatus]);
  useEffect(() => {
    dispatch(getTopAppreciated());
  }, [dispatch]);
  return (
    <>
      <div className="topHighFiversSection">
        <p className="topHighFiversTitle">Top High fivers</p>
        <div className="topHighFiversScrollBar">
          {TopAppreciated &&
            TopAppreciated.map((value) => (
              <>
                {value.sent_high_five !== 0 ? (
                  <Row className="topHighFiversRowStyle">
                    <Col span="1"></Col>
                    <Col span="5">
                      <img
                        className="topHighFiversUserImg"
                        src={value && value.image}
                        alt=""
                      />
                    </Col>
                    <Col span="12">
                      <p className="topHighFiversUserName">
                        {value.first_name} {value.last_name}
                      </p>
                    </Col>
                    <Col span="6">
                      <p className="topHighFiversSentHighFive">
                        {value.sent_high_five}
                      </p>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </>
            ))}
        </div>
      </div>
    </>
  );
};
export default TopHighFives;
