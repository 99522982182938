import {
  GET_TIME_OFF_DETAIL_SUCCESS,
  GET_TIME_OFF_DETAIL,
  GET_TIME_OFF_DETAIL_ERROR,
} from "../../actions/TimeOff/getTimeoffDetail";

const getTimeoffDetail = (state = null, action) => {
  switch (action.type) {
    case GET_TIME_OFF_DETAIL: {
      return true;
    }
    case GET_TIME_OFF_DETAIL_SUCCESS: {
      return action.response;
    }
    case GET_TIME_OFF_DETAIL_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default getTimeoffDetail;
