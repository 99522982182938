import { Button, Typography, Input, Form } from "antd";
import "./style.css";

const ChangePassword = () => {
  const onFinish = (values) => {};
  return (
    <>
      <Typography className="changePasswordTitle">
        Change your Password
      </Typography>
      <div className="changePassword">
        <div className="changePasswordInput">
          <Form hideRequiredMark onFinish={onFinish}>
            <Form.Item
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your current password!",
                },
              ]}
            >
              <Input.Password placeholder="Current Password" size="large" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder="New Password" size="large" />
            </Form.Item>

            <Form.Item
              name="confirm"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      "The two passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm  Password" size="large" />
            </Form.Item>
            <Button
              htmlType="submit"
              className="submitPasswordButton"
              type="primary"
            >
              Change my password
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
