import {
  LEAVE_TYPE_Count_SUCCESS,
  LEAVE_TYPE_Count_REQUEST,
  LEAVE_TYPE_Count_ERROR,
} from "../../actions/TimeOff/leave_type_count";

const leaveTypeCount = (state = null, action) => {
  switch (action.type) {
    case LEAVE_TYPE_Count_REQUEST: {
      return true;
    }
    case LEAVE_TYPE_Count_SUCCESS: {
      return action.response;
    }
    case LEAVE_TYPE_Count_ERROR: {
      return action.response;
    }
    default:
      return state;
  }
};

export default leaveTypeCount;
